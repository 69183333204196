import React, { useEffect, useState } from 'react';
import { iClockGray, iCrossOutlined, iGrayCancel } from '../../app/utility/imageImports';
import FreeDropDown from '../dropdown/FreeDropDown';
import TimePickerNew from './TimePickerNew';
import { Tooltip } from 'antd'

const CommonTimePicker = ({
    selectAction = () => { },
    onChange = () => { },
    disabled = false,
    endTime,
    init_time,
    init_date,
    label = "Select Time",
    showExtendedTimeUi = true,
    required,
    heightClass = "h-s48",
    show_asterisk = true,
    showClear = false,
    handleClear = () => { },
    clearIconTooltipText = "",
    marginClass = "",
    isInvalid = false,
    onBlurEvent = () => { },
    isCurrentTimeValidation = false,
    isCustomMinTimeValidation = false,
    customMinTimeValue = null,
}) => {
    const [value, setValue] = useState('');
    const [showingTimePickerUi, setShowingTimePickerUi] = useState(false);

    useEffect(() => {
        setValue(init_time ?? '');
    }, [init_time]);

    return (
        <div onClick={(e) => { if (disabled) e.stopPropagation() }} className={`relative w-full ${heightClass} outline-none ${marginClass} ${disabled ? "opacity-50" : ""}`}>
            <input type="hidden" name="hidden input" id='hidden-input' />
            <div className={`outline-visible outline-hidden absolute outline-none top-0 left-0 bg-white ${heightClass} w-full z-10`}>
                <FreeDropDown
                    onUiUpdate={(data) => setShowingTimePickerUi(data)}
                    width={500}
                    body={
                        !disabled ?
                            <TimePickerNew
                                customMinTimeValue={customMinTimeValue}
                                isCurrentTimeValidation={isCurrentTimeValidation}
                                isCustomMinTimeValidation={isCustomMinTimeValidation}
                                endTime={endTime}
                                init_time={value ? value : init_time}
                                init_date={init_date}
                                showExtendedTimeUi={showExtendedTimeUi}
                                selectAction={(e, f) => {
                                    setValue(e);
                                    selectAction(e, f);
                                    onChange(e, f);
                                }}
                            /> : <></>
                    }
                    button={
                        <div
                            className={`border-b-[1px]  pb-[1px] ${disabled ? "cursor-not-allowed" : "hover:border-b-[#949494] hover:border-b-2 hover:pb-0 cursor-pointer"}  outline-none mt-[10px] h-[26px] w-full flex justify-between items-center  relative
                                ${isInvalid ? 'border-b-[#F0554B]' : showingTimePickerUi && !disabled ? 'border-b-[#BCC2BF] border-b-2' : 'border-b-[#949494]'}
                            `}>

                            <div
                                className={`
                                absolute left-0 transition-all ease-in-out duration-300 text-cSideBarNormalText capitalize
                                ${value !== '' ? '-top-4 text-xs font-normal ' : 'top-0 text-base'}`}>
                                {(required && show_asterisk) ? <div className="">{label}<span className={`text-cSideBarNormalText ml-s2`}>*</span> </div> : label}</div>


                            <div className='text-sm text-black'>{value ?? ""}</div>
                            <div className='flex space-x-2'>
                                {
                                    showClear && init_time && <Tooltip title={clearIconTooltipText} color={"#242826"} >
                                        <img onClick={
                                            async (e) => {
                                                e.stopPropagation();
                                                setValue("");
                                                await handleClear()
                                                document.getElementById('hidden-input').focus();
                                            }}
                                            className={`w-5 h-5 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`} src={iCrossOutlined} alt="" />
                                    </Tooltip>
                                }
                                <img className={`w-5 h-5 ml-[1px] ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`} src={iClockGray} alt="" />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default CommonTimePicker