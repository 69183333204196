import React from "react";
import CommonModal from "../../../../components/modal/CommonModal";
import useRequestStore, {
  defineNotPlannedDeliveryDate,
} from "../../../../app/stores/others/requestStore";
import { useTranslation } from "react-i18next";
import CommonTable from "../../../../components/table/CommonTable";
import {
  request_details_types,
  user_role as role,
} from "../../../../app/utility/const";
import useGeneralStore from "../../../../app/stores/others/generalStore";
import Clamp from "react-multiline-clamp";
import {
  formatSchedule,
  formatTime,
} from "../../../../app/utility/utilityFunctions";
import { useParams } from "react-router-dom";

const RequestTableViewModal = () => {
  const { t } = useTranslation();

  const { user_role } = useGeneralStore();

  const params = useParams();

  const { type } = params;

  const {
    showRequestTableViewModal,
    not_planned_stops,
    setShowRequestTableViewModal,
    request_details,
  } = useRequestStore();

  const headers =
    user_role === role.company
      ? [
          { name: t("Address") },
          { name: t("Floor number") },
          { name: t("Date & time") },
          { name: t("Duration") },
          { name: t("Product") },
          { name: t("Comment") },
        ]
      : [
          { name: t("Address") },
          { name: t("Floor number") },
          { name: t("Product") },
          { name: t("Comment") },
        ];

  const contents = request_details?.stops?.map((item, index) => {
    return user_role === role.company
      ? {
          contents: [
            { value: item?.address },
            { value: item?.floor_number },
            {
              value:
                (type === request_details_types.not_planned &&
                  request_details?.awarded?.status === "init") ||
                type === request_details_types.in_bidding ||
                type === request_details_types.awarded ||
                type === request_details_types.invitation ||
                window.location.pathname ===
                  `/global-request/details/${request_details?.id}` ? (
                  <>
                    {defineNotPlannedDeliveryDate(
                      request_details?.pickup_date,
                      not_planned_stops[index]?.start_time,
                      request_details?.pickup_start_time,
                    )}
                    {item?.start_time || item?.end_time
                      ? `, ${formatTime(item?.start_time)} ${
                          item?.end_time ? " - " : ""
                        } ${formatTime(item?.end_time)}`
                      : ""}
                  </>
                ) : (
                  formatSchedule(item?.date, item?.start_time, item?.end_time)
                ),
            },
            { value: item?.duration ? `${item?.duration} min` : " " },
            {
              value: item?.products?.length ? <Clamp lines={2}>{item?.products?.map((item) => item.text).join(", ")}</Clamp> : "",
              className: "break-all whitespace-pre-line"
            },
            {
              value: item?.comment ? <Clamp lines={2}>{item?.comment}</Clamp> :  "",
              className: "break-all whitespace-pre-line"
            },
          ],
          clickAction: () => {},
        }
      : {
          contents: [
            { value: item?.address },
            { value: item?.floor_number },
            { value: item?.products?.length ? <Clamp lines={2}>{item?.products?.map((item) => item.text).join(", ")}</Clamp> : ""},
            {
              value: item?.comment ? (
                <Clamp lines={2}>{item?.comment}</Clamp>
              ) : (
                ""
              ),
            },
          ],
          clickAction: () => {},
        };
  });

  return (
    <CommonModal
      showModal={showRequestTableViewModal}
      setShowModal={setShowRequestTableViewModal}
      modalTitle={t("Table View")}
      widthClass="w-[1145px]"
      modalSpace={true}
      mainContent={
        <>
          <CommonTable
            tableTitle=""
            showSearchBox={false}
            showTopRightFilter={false}
            emptyDataMessage={t("No data available")}
            showTakeOption={false}
            showPagination={false}
            showPageCountText={false}
            tableHeaders={headers}
            tableContents={contents}
            hasClickFunction={false}
            widths={
              user_role === role.company
                ? "auto 10% 20% 10% 15% 20%"
                : "auto 20% 20% 20%"
            }
            outerPadding="p-s0"
            withClearSearch={false}
            sort={false}
            seeAllText={null}
            //
          />
        </>
      }
    />
  );
};

export default RequestTableViewModal;
