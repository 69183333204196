import React, { useRef, useState } from "react";
import { iNoImageUploader, iRedCancel } from "../../app/utility/imageImports";
import { Toastr } from '../../app/utility/utilityFunctions';
import { VALID_IMAGE_TYPES, base_url_src } from "../../app/utility/const";
import { useTranslation } from "react-i18next";

export default function SquareImageUploader({
    onChange = () => { },
    finalBase64 = () => { },
    editMode = true,
    iImage = null,
    clearImage,
    rounded = "",
}) {

    const { t } = useTranslation();

    const [tempImage, setTempImage] = useState(iImage ? iImage.startsWith("data:image/") ? iImage : base_url_src + iImage : null);

    const inputFile = useRef(null);

    const onButtonClick = () => inputFile.current.click();

    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];

        if (file && VALID_IMAGE_TYPES.includes(file.type)) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const result = reader.result;
                setTempImage(result);
                finalBase64(result);
                onChange(result);
            };
            reader.readAsDataURL(file);

        } else {
            Toastr({ message: t("Invalid file type. Only jpeg, png, and jpg files are allowed."), type: "warning" })
        }
    };


    return (
        <div className="relative">
            <div
                className={`relative overflow-hidden select-none rounded-br10`}
            >
                
                <div className={`rounded-br6 object-cover cursor-pointer relative flex flex-col items-center justify-center min-w-[180px] max-w-[180px] min-h-[180px] max-h-[180px] ${tempImage ? "border-[1px] border-cGray200" : "border-[1px] border-cGreen600 bg-cGreen50"} w-fit ${rounded}`}
                    onClick={editMode ? onButtonClick : undefined}>
                    <input
                        onChange={onChangeFile}
                        type="file"
                        id="file"
                        ref={inputFile}
                        style={{ display: "none" }}
                        accept="image/jpeg,image/png,image/jpg"
                    />
                    <img
                        className={`${tempImage && "min-w-[180px] max-w-[180px] min-h-[180px] max-h-[180px] object-fill"}  rounded-br8 ${!tempImage ? "" : "rounded-br4 p-1"} ${tempImage && rounded}`}
                        src={tempImage ? tempImage : ""}
                        alt="profile-pic"
                        onError={(e) => {
                            setTempImage(null);
                            e.target.onerror = null;
                            e.target.src = iNoImageUploader;
                        }}
                    />
                    {tempImage &&
                        <img
                            onClick={(event) => {
                                event.stopPropagation();
                                onChange("");
                                setTempImage(null);
                                inputFile.current.value = null;
                                clearImage();
                            }}
                            className="absolute top-3 right-3"
                            src={iRedCancel}
                            alt=""
                        />
                    }
                    {!tempImage && <div className="mt-2 font-black text-fs16 text-cBrand">{t("Upload Image")}</div>}
                </div>
            </div>
            <div className="mt-1 font-normal text-fs12 text-cGray500">{t("Jpg & Png file with 5mb max file size")}</div>
        </div>
    );
}
