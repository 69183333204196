import React, { useRef } from "react";
import CommonModal from "../../../../../../../components/modal/CommonModal";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../../../components/button/CommonButton";
import CommonSelect from "../../../../../../../components/select/CommonSelect";
import useShiftStore, {
    FormatCarsArrayForSelectionList,
    FormatDriversArrayForSelectionList,
    getAllShiftCarsAndDriversList,
} from "../../../../../../../app/stores/company/shiftStore";
import { k_shift_status } from "../../../../../../../app/utility/const";
import { useEffect } from "react";
import { getRequestShiftPlannerList } from "../../../../../../../app/stores/others/requestStore";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";

const AvailableShiftFilter = ({ showModal = false, setShowModal }) => {
    const {
        resetFilterFormCopy,
        filter_form,
        changeFilterForm,
        filterShiftCarList,
        resetFilterForm,
        filterShiftDriverList,
        setFilterShiftCarList,
        setFilterShiftDriverList,
        updateFilterFormCopy,
    } = useShiftStore();

    const { cr_form } = useCreateRequestStore();

    const divRef = useRef(null);

    const { t } = useTranslation();

    const shift_status = [
        { value: k_shift_status.init, title: t("Not Started") },
        { value: k_shift_status.ongoing, title: t("Ongoing") },
    ];

    useEffect(() => {
        if (divRef.current) divRef.current.focus();
    }, []);

    useEffect(() => {
        if (showModal) {
            fetchData();
        }
    }, [showModal]);

    const getAvailableShift = async () => {
        return await getRequestShiftPlannerList(
            cr_form?.pickup?.start_time,
            cr_form?.pickup?.end_time,
            cr_form?.pickup?.date,
            true
        );
    }

    const fetchData = async () => {
        if (filterShiftCarList?.length > 0 && filterShiftDriverList?.length > 0)
            return;

        const success = await getAllShiftCarsAndDriversList(null, null, null, null, true);
        setFilterShiftCarList(FormatCarsArrayForSelectionList(success?.cars));
        setFilterShiftDriverList(
            FormatDriversArrayForSelectionList(success?.drivers)
        );
    };

    return (
        <div>
            <CommonModal
                showModal={showModal}
                setShowModal={setShowModal}
                modalTitle={
                    <div className="flex items-baseline">
                        <div>{t("Filter")}</div>
                        <div
                            className="pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed"
                            onClick={async () => {
                                await resetFilterFormCopy();
                                await resetFilterForm();
                                const success = await getAvailableShift();
                                if (success) setShowModal(false);
                            }}
                        >
                            {t("Clear")}
                        </div>
                    </div>
                }
                mainContent={
                    <>
                        <form
                            className="mt-6"
                            onSubmit={async (e) => {
                                e.preventDefault();
                                const success = await getAvailableShift();

                                if (success) {
                                    updateFilterFormCopy(filter_form);
                                    setShowModal(false);
                                }
                            }}
                        >
                            <div tabIndex="0" ref={divRef} className="pt-0"></div>

                            <div className="mt-[9px] grid grid-cols-2 items-center gap-7">
                                <CommonSelect
                                    placeholder={t("Select Car/License Plate")}
                                    label={t("Select Car/License Plate")}
                                    dataArray={filterShiftCarList}
                                    has_subtitle={false}
                                    onChange={(e, value) => {
                                        changeFilterForm("plate_number", e.target.value);
                                    }}
                                    value={filter_form?.plate_number}
                                />

                                <CommonSelect
                                    label={t("Select Driver")}
                                    placeholder={t("Select Driver")}
                                    dataArray={filterShiftDriverList}
                                    has_subtitle={false}
                                    onChange={(e, value) => {
                                        changeFilterForm("driver_id", e.target.value);
                                    }}
                                    value={filter_form?.driver_id}
                                />
                            </div>

                            <div className="mt-[21px] grid grid-cols-2 items-center gap-7">
                                <CommonSelect
                                    label={t("Shift Status")}
                                    placeholder={t("Shift Status")}
                                    dataArray={shift_status}
                                    has_subtitle={false}
                                    onChange={(e, value) => {
                                        changeFilterForm("status", e.target.value);
                                    }}
                                    value={filter_form?.status}
                                />
                            </div>

                            <div className="flex flex-row items-center justify-end w-full mt-s20">
                                <CommonButton btnLabel={t("Apply Filter")} type="submit" />
                            </div>
                        </form>
                    </>
                }
            />
        </div>
    );
};

export default AvailableShiftFilter;
