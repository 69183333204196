import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useCreateRequestStore from '../../../../../../../../../app/stores/others/createRequestStore'
import useRequestStore from '../../../../../../../../../app/stores/others/requestStore'
import { chat_role, create_request_steps } from '../../../../../../../../../app/utility/const'
import { iChatGreen, iFavCompanyGray } from '../../../../../../../../../app/utility/imageImports'
import RequestAccordion from '../../../../../../../../../components/Accordion/RequestAccordion'
import Image from '../../../../../../../../../components/image/Image'
import CommonCompanyDetailsModal from '../../../../../../../../../components/modal/CommonCompanyDetailsModal'
import CommonViewComponent from '../../../../../../../../../components/viewer/CommonViewComponent'
import { getAllUserInfo } from '../../../../../../../../../app/stores/others/generalStore'
import { commonSelectedChat } from '../../../../../../../../../app/stores/others/chatStore'

export default function BiddingDetails() {

  const { request_details } = useRequestStore();

  const { awarded, awarded_company } = request_details;
  const { image, name, id } = awarded_company ?? {};
  const { budget, details } = awarded ?? {};

  const { t } = useTranslation();


  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();

  const [showModal, setShowModal] = useState(false);


  const navigateTo = useNavigate();

  const CompanyImage = ({ src }) => (
    <Image cursorPointerClass="cursor-pointer" dummyImage={iFavCompanyGray} className="object-fill rounded-full h-[44px] w-[44px] cursor-pointers border border-[#D9D9D9]" src={src} />
  );

  const CompanyName = ({ name }) => (
    <div title={name} className='relative ml-1 text-fs16 font-fw500'>{name ?? 'NA'}</div>
  );

  const handleChat = async (company_id) => {
    const res = await getAllUserInfo(company_id);
    if (res?.success) {
      await commonSelectedChat(
        res?.data,
        chat_role.customer
      );
      setShowModal(false);
      navigateTo("/chat");
    }
  };


  return (
    <RequestAccordion
      showClearButton={false}
      // titleRightComponent={"Selected Companies (" + request_details?.invitation_data?.length + ")"}
      // titleRightComponentOnClick={() => { setSelectedCompaniesModal(true) }}
      title={t("Bidding Details")}
      subTitleOne={budget ? "DKK " + budget + " | " + request_details?.products_count + " products" : request_details?.products_count + " products"}
      isForm={true}
      isExpanded={expandedAccordion === create_request_steps.bidding_details}
      onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.bidding_details ? null : create_request_steps.bidding_details) }}
    >

      <div className={`${expandedAccordion === create_request_steps.bidReceived ? "pt-5" : "pt-8"}`}>
        {/* <div className='flex justify-between w-full'>
          <div className='mb-3'>
            <RequestDetailsTextTitle title={t(`Bidding Details`)} />
          </div>
        </div> */}

        <div className=' w-full mb-1'>

          <div className="border border-cGray300 rounded p-3 space-y-3">
            <CommonViewComponent
              labelText={t('Bidding price')}
              value={budget ? "DKK " + budget : "No bid received"}
            />
            <CommonViewComponent
              labelText={t('Comment')}
              value={details ?? "No comment"}
            />
          </div>

          {id && <div
            onClick={() => setShowModal(true)}
            className='flex flex-row justify-between items-center w-full cursor-pointer text-fs14 font-fw400 mt-4 border border-cGray300 rounded p-3'>
            <div className='flex flex-row justify-start w-full space-x-3'>
              <CompanyImage src={image} />
              <div className='flex flex-col items-start justify-center'>
                <CompanyName name={name} />
                {/* <CompanyRating id={id} rate={rate} setSelectedCompanyId={setSelectedCompanyId} setShowReviewModal={setShowReviewModal} /> */}
                <div className="text-xs text-cGray500 pl-1" >
                  {"Company | " + name}
                  {awarded_company?.phone ? " | " + awarded_company?.phone : ""}
                </div>
              </div>
            </div>

            <div
              className="mr-3 cursor-pointer"
              onClick={async (e) => {
                e.stopPropagation();
                handleChat(id);
              }}
            >
              <img src={iChatGreen} className=" w-[20px] h-[20px]" alt="" />

            </div>
          </div>}


        </div>


        <CommonCompanyDetailsModal showModal={showModal} setShowModal={setShowModal} company_id={id} bidding_details={false} />
      </div>

    </RequestAccordion>
  );
}
