import React, { useEffect } from "react";
import CommonViewComponent from "../../../../../../components/viewer/CommonViewComponent";
import { useTranslation } from "react-i18next";
import useRequestStore from "../../../../../../app/stores/others/requestStore";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  formatDate,
  formatTime,
} from "../../../../../../app/utility/utilityFunctions";
import CommonCheckbox from "../../../../../../components/input/CommonCheckbox";
import CommonCompanyDetailsModal from "../../../../../../components/modal/CommonCompanyDetailsModal";
import useCreateRequestStore from "../../../../../../app/stores/others/createRequestStore";
import RequestAccordion from "../../../../../../components/Accordion/RequestAccordion";
import { create_request_steps } from "../../../../../../app/utility/const";
import CommonEmptyView from "../../../../../common/createRequest/components/content/selectShift/components/CommonEmptyView";

export default function Agreement() {
  const { t } = useTranslation();

  const { request_details, selectBiddingId, inBiddingCheck, setBiddingCheck } =
    useRequestStore();
  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();

  const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);

  const selectCompany = request_details?.biddings?.find(
    (item) => item?.id === selectBiddingId,
  );

  useEffect(()=> {
    setBiddingCheck(false)
  }, [selectCompany])

  return (
    <RequestAccordion
      // isInvalid={isApplyGenerate && !isCrFormValidationWithAddress()?.pickup}
      showClearButton={false}
      // clearHandler={() => {
      //   clearPickupFrom(); console.log("clear Pickup HITS !!");
      // }}
      title={t("Agreement")}
      //   subTitleOne={cr_form?.is_global ? "Submitted for Global" : cr_form?.company_user_ids?.length > 0 ? "" : "No company selected yet"}
      //   subTitleTwo={cr_form?.company_user_ids?.length > 0 ? cr_form?.company_user_ids?.length + " directly invited" : ""}
      isForm={true}
      isExpanded={expandedAccordion === create_request_steps.agreement}
      onExpand={() => {
        setExpandedAccordion(
          expandedAccordion === create_request_steps.agreement
            ? null
            : create_request_steps.agreement,
        );
      }}
    >
      <div
        className={`${
          expandedAccordion === create_request_steps.agreement ? "pt-5" : "pt-8"
        }`}
      >
        {selectBiddingId ? (
          <>
            <div className="mb-5 space-y-2">
              <CommonViewComponent
                labelText={t("Company")}
                value={selectCompany?.company_info?.name}
              />
              <CommonViewComponent
                labelText={t("Budget")}
                value={
                  selectCompany?.budget
                    ? "DKK " + selectCompany?.budget?.toLocaleString("da-DK")
                    : "No bid received"
                }
              />
              <CommonViewComponent
                labelText={t("Bidding Expire on")}
                value={`${
                  request_details?.pickup_date
                    ? formatDate(request_details?.pickup_date)
                    : ""
                }${request_details?.pickup_start_time ? "," : ""}  ${
                  request_details?.pickup_start_time
                    ? (formatTime(request_details?.pickup_start_time) ?? "--") +
                      " - " +
                      (formatTime(request_details?.pickup_end_time) ?? "--")
                    : ""
                }`}
              />
              <CommonViewComponent
                labelText={t("Bidding Description")}
                value={selectCompany?.details}
              />
            </div>

            <div className="flex space-x-2">
              <CommonCheckbox
                checked={inBiddingCheck}
                onChange={() => {
                  setBiddingCheck(!inBiddingCheck);
                }}
              />

              <div className="flex items-center justify-center space-x-1">
                <div className="checkBoxLabel">{t("I agree with all")} </div>
                <a
                  className="font-normal text-cBrand text-fs14 underline"
                  href="https://limadi.dk/TermsAndCondition.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Limadi Terms & Conditions")}
                </a>
                <div className="font-normal checkBoxLabel text-fs14">
                  {t("also with")}{" "}
                </div>
                <div
                  className="font-normal cursor-pointer text-cBrand text-fs14 underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowCompanyDetailsModal(true);
                  }}
                >
                  {t("Company Terms & Conditions.")}
                </div>
              </div>
            </div>

            <CommonCompanyDetailsModal
              company_id={selectCompany?.company_info?.id}
              showModal={showCompanyDetailsModal}
              setShowModal={setShowCompanyDetailsModal}
            />
          </>
        ) : (
          <CommonEmptyView message={t("No bid has been selected yet.")} />
        )}
      </div>
    </RequestAccordion>
  );
}
