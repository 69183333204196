import axios from "axios";
import { create } from "zustand";
import { kuAddFavoriteAddress, kuDeleteFavoriteAddress, kuFavoriteAddressDetails, kuFavoriteAddressList, kuUpdateFavoriteAddress } from "../../urls/commonUrl";
import { Toastr } from "../../utility/utilityFunctions";
import useGeneralStore from "./generalStore";
import useUtilityStore from "./utilityStore";
import { t } from "i18next";
import { common_take } from "../../utility/const";
const { setLoading } = useGeneralStore.getState();

const { setLoadingSearch } = useUtilityStore.getState();

const useFavoriteAddressStore = create((set, get) => ({

  favoriteAddressList: [],
  setFavoriteAddressList: (value) => set({ favoriteAddressList: value }),

  tempFavoriteAddress: [],
  setTempFavoriteAddress: (value) => set({ tempFavoriteAddress: value }),

  favoriteAddressDetails: {},
  setFavoriteAddressDetails: (value) => set({ favoriteAddressDetails: value }),

  selectedFavoriteAddressIndex: 0,
  setSelectedFavoriteAddressIndex: (value) => set({ selectedFavoriteAddressIndex: value }),

  addAddressLabel: "",
  setAddAddressLabel: (value) => set({ addAddressLabel: value }),

  favoriteAddressSearchValue: "",
  setFavoriteAddressSearchValue: (value) => set({ favoriteAddressSearchValue: value }),

  editFavoriteAddress_form: { fav_id: "", title: "", address: "", floor_number: "", note: "", lat: "", lng: "" },
  setEditFavoriteAddress_form: (value) => set({ editFavoriteAddress_form: value }),

  addFavoriteAddress_form: { title: "", address: "", floor_number: "", note: "", lat: "", lng: "" },
  setAddFavoriteAddress_form: (value) => set({ addFavoriteAddress_form: value }),

  resetFavoriteAddressForm: () => set({
    addFavoriteAddress_form: {
      id: null, title: "", address: "", floor_number: "", note: "", lat: "", lng: ""
    }
  }),

  favoriteAddressTake: common_take,
  setFavoriteAddressTake: (value) => set({ favoriteAddressTake: value }),

  favAddress_order_by: 'id',
  setFavAddress_order_by: (value) => set({ favAddress_order_by: value }),


  favAddressPageUrl: "",
  setFavAddressPageUrl: (value) => set({ favAddressPageUrl: value }),


  //All Modal
  showAddFavoriteAddressModal: false,
  setShowAddFavoriteAddressModal: (value) => set({ showAddFavoriteAddressModal: value }),

  showEditFavoriteAddressModal: false,
  setShowEditFavoriteAddressModal: (value) => set({ showEditFavoriteAddressModal: value }),

  showFavoriteAddressDeleteModal: false,
  setShowFavoriteAddressDeleteModal: (value) => set({ showFavoriteAddressDeleteModal: value }),

  showFavoriteAddressDetailsModal: false,
  setShowFavoriteAddressDetailsModal: (value) => set({ showFavoriteAddressDetailsModal: value }),

  // table view
  order: null,
  setOrder: (order) => {
    set({ order: order });
    return;
  },

  is_asc: 1,
  setIsAsc: (data) => {
    set({ is_asc: data });
    return;
  },

  take: common_take,
  setTake: (data) => {
    set({ take: data });
    return;
  },

  search_key: '',
  setSearchKey: (key) => {
    set({ search_key: key })
    return;
  },

  api_url: kuFavoriteAddressList,
  setApiUrl: (url) => {
    set({ api_url: url })
    return;
  },

  table_data: null,
  setTableData: (data) => set({ table_data: data }),

}));
export default useFavoriteAddressStore;

// get Favorite Address
export const getFavoriteAddress = async () => {

  const { setTableData, is_asc, take, order, search_key, api_url } = useFavoriteAddressStore.getState();

  let body = {
    take: take,
    search: search_key,
    order_by: order,
    is_asc: is_asc,
  };

  console.log('getFavoriteAddress body', body);

  try {
    if (search_key) setLoadingSearch(true); else setLoading(true);
    const res = await axios.get(api_url ?? kuFavoriteAddressList, { params: body });
    console.log('getFavoriteAddress:::', res.data);

    if (res?.data?.success) {
      setTableData(res?.data?.data);
      if (search_key) setLoadingSearch(false); else setLoading(false);
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      if (body.search_key) setLoadingSearch(false); else setLoading(false);
      return false;
    }
  } catch (error) {
    console.log('getFavoriteAddress:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    if (search_key) setLoadingSearch(false); else setLoading(false);
    return false;
  }
};


export const handleFavAddressOrder = async (order_by, action) => {
  const { setIsAsc, is_asc, setOrder, setApiUrl, order } = useFavoriteAddressStore.getState();
  await setOrder(order_by);
  if (order !== order_by) await setIsAsc(1);
  else await setIsAsc(is_asc ? 0 : 1);
  await setApiUrl(kuFavoriteAddressList);
  const success = await action();
  if (!success) setIsAsc(is_asc ? 0 : 1);
  if (!success && order !== order_by) setIsAsc(1);
}

// Add Favorite Address
export const addFavoriteAddress = async () => {

  const { addFavoriteAddress_form } = useFavoriteAddressStore.getState();

  try {
    setLoading(true)
    const res = await axios.post(kuAddFavoriteAddress, addFavoriteAddress_form,);
    console.log('addFavoriteAddress:::', res.data);
    if (res?.data?.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      await getFavoriteAddress();
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false;
    }
  } catch (error) {
    console.log('addFavoriteAddress:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};

// Edit Favorite Address
export const editFavoriteAddress = async (body) => {

  try {
    setLoading(true)
    const res = await axios.post(kuUpdateFavoriteAddress, body);
    console.log('editFavoriteAddress:::', res?.data);
    if (res?.data?.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      await getFavoriteAddress();
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false;
    }
  } catch (error) {
    console.log('editFavoriteAddress:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};

// Delete Favorite Address
export const deleteFavoriteAddress = async (favAddress_id) => {
  try {
    setLoading(true)
    const res = await axios.post(kuDeleteFavoriteAddress, { fav_id: favAddress_id });
    console.log('deleteFavoriteAddress:::', res.data);
    if (res?.data?.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      getFavoriteAddress();
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false;
    }
  } catch (error) {
    console.log('deleteFavoriteAddress:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};


// get Favorite Address Details
export const getFavoriteAddressDetails = async (favAddress_id) => {
  const { setFavoriteAddressDetails } = useFavoriteAddressStore.getState();
  try {
    setLoading(true)
    const res = await axios.get(kuFavoriteAddressDetails, { params: { id: favAddress_id } });
    console.log('getFavoriteAddressDetails:::', res.data);
    if (res?.data?.success) {
      await setFavoriteAddressDetails(res?.data?.data)
      return res?.data?.data;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      return false;
    }
  } catch (error) {
    console.log('getFavoriteAddressDetails:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    return false;
  } finally {
    setLoading(false);
  }
};