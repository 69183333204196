import { Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore, {
    getAddress,
} from "../../../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../../../app/stores/others/generalStore";
import { user_role as role } from "../../../../../../../../../app/utility/const";
import { iPast } from "../../../../../../../../../app/utility/imageImports";
import {
    convertToDDMMYYYY,
    countEmptySpaces,
    isTimeFormatValid,
    Toastr,
} from "../../../../../../../../../app/utility/utilityFunctions";
import CommonButton from "../../../../../../../../../components/button/CommonButton";
import CommonModal from "../../../../../../../../../components/modal/CommonModal";
import {
    comment_width,
    date_width,
    floor_width,
    product_width,
    time_width,
} from "../../tableViewFildWidth";

export const Header = () => {
    const {
        cr_form,
        table_cr_form,
        updateTableStopsForm,
        setShowAdditionStopAlertModal,
        setAddProduct,
        setTableCrFullForm,
        setPasteDataLength
    } = useCreateRequestStore();

    const [propertyData, setProperty] = useState(null);

    const pasteDataLengthRef = useRef(0);
    const additionalStopsRef = useRef(0);
    const totalTableRowsRef = useRef(0);

    const { setLoading, user_role } = useGeneralStore();
    const { t } = useTranslation();

    const getReadClipboardText = async () => {
        try {
            // Attempt to read clipboard content
            const text = await navigator.clipboard.readText();
            console.log('Read clipboard: ', text);
            return text;
        } catch (error) {
            console.error('Failed to read clipboard: ', error);
            //   alert('Failed to read clipboard. Please ensure you have copied text and try again.');
        }
    };

    const pasteInfo = async (property) => {
        console.log("started pasting with property: ", property);
        const textData = await getReadClipboardText();

        // check permission first
        const permission = await navigator.permissions.query({
            name: "clipboard-read",
        });

        if (!textData && permission.state === "denied") {
            console.log("permission denied");
            alert(t("You need to allow clipboard access to paste data"));
            return;
        }

        // prevent auto suggestion on paste
        setLoading(true);
        const text = textData ?? await navigator.clipboard.readText();

        console.log("RECEIVED pasted text: ", text);


        // convert text to array of lines
        let data = text.split("\n");
        setPasteDataLength(data?.length);
        pasteDataLengthRef.current = data?.length;
        // console.log('paste data: ', data);

        // prevent use from multiple colum paste
        if (data[0].includes("\t")) {
            Toastr({
                message: t("You cannot paste multiple column"),
                type: "warning",
            });
            setLoading(false);
            return;
        }
        if (countEmptySpaces(data[0].replace(/\[|\]| /g, ""))) {
            return Toastr({
                message: t("Please remove extra space"),
                type: "warning",
            });
        }

        useCreateRequestStore.getState().setPasteData(data);
        setProperty(property);

        if (data.length > totalTableRowsRef.current) {
            //note: check for max 200 and if extra row creation required...
            if (parseInt(data?.length) < 1) {
                Toastr({
                    message: t("Please enter a number between 1 and 200."),
                    type: "warning",
                });
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                return;
            }

            if (parseInt(data?.length) > 200) {
                Toastr({ message: t("Maximum 200 items allowed."), type: "warning" });
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                return;
            } else {
                additionalStopsRef.current = parseInt(data?.length) - parseInt(totalTableRowsRef.current);

                // setTimeout(() => {
                //     console.log("OK additional stops: ", additionalStopsRef.current);
                // }, 2000);
                setLoading(false);
                setShowAdditionStopAlertModal(true);

                return;
            }
        } else {
            //note: main pasting data processing starts here...

            const isDateFormatValid = (date) => {
                // This regex matches "DD/MM/YYYY", "DD.MM.YYYY", and "DD-MM-YYYY" formats
                const regex =
                    /^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\d\d$/;
                return regex.test(date);
            };

            const convertDateFormat = (date) => {
                const [day, month, year] = date.split("/");
                return `${year}-${month}-${day}`;
            };

            if (property === "date") {
                const valid = await isDateFormatValid(data[0]);
                if (valid) {
                    data?.map((item, index) => {
                        let convertedDateString = convertToDDMMYYYY(item);
                        updateTableStopsForm(index, property, convertDateFormat(convertedDateString));
                    }
                    );
                    setLoading(false);
                } else {
                    setLoading(false);
                    Toastr({
                        message: t(
                            'Invalid date format. Please use the format DD/MM/YYYY, DD.MM.YYYY, or DD-MM-YYYY"'
                        ),
                        type: "warning",
                    });
                    return;
                }
            }

            if (property === "start_time") {
                const allTimesValid = await Promise.all(
                    data.map((time) => isTimeFormatValid(time.replace(/\[|\]| /g, "")))
                );
                data?.map((item, index) => {
                    if (allTimesValid[index]) {
                        let [hours, minutes] = item.split(":").map(Number);

                        let curr_min = parseInt(minutes);
                        let curr_hour = parseInt(hours);
                        curr_min = Math.ceil(curr_min / 15) * 15;

                        if (curr_min === 60) {
                            curr_min = 0;
                            curr_hour += 1;
                        }

                        if (curr_hour === 24) {
                            curr_hour = 0;
                        }

                        if (curr_hour.toString().length < 2) curr_hour = '0' + curr_hour;
                        if (curr_min.toString().length < 2) curr_min = '0' + curr_min;

                        hours = curr_hour == "00" ? 23 : curr_hour;
                        minutes = curr_min;


                        const end_time = new Date();
                        // Assuming table_cr_form.stops[0].date contains a string representing the date
                        const originalDate = new Date(table_cr_form[0]?.date);

                        // Add one day to the original date
                        originalDate.setDate(originalDate.getDate() + 1);

                        // if (hours > 22) {
                        //   updateTableStopsForm(index, "date", originalDate);
                        // }
                        end_time.setHours(hours + 2, minutes);

                        const end_time_string = `${end_time
                            .getHours()
                            .toString()
                            .padStart(2, "0")}:${end_time
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`;

                        updateTableStopsForm(index, property, `${hours}:${minutes}`);
                        updateTableStopsForm(index, "end_time", end_time_string);
                    }
                });
                setLoading(false);
            }

            if (property === "product") {
                if (countEmptySpaces(data[0])) {
                    Toastr({ message: t("Please remove extra space"), type: "warning" });
                    setLoading(false);
                    return;
                } else {
                    data?.map((item, productIndex) => {
                        const productItem = table_cr_form[productIndex];

                        // Check if productItem exists and has products
                        if (productItem && Array.isArray(productItem?.products) && productItem?.products.length > 0) {
                            const removedIds = productItem.products
                                .map((i) => i?.id)
                                .filter((id) => id !== undefined); // Filter out undefined ids

                            // Set removed_products_ids to an empty array if no valid ids are found
                            updateTableStopsForm(productIndex, "removed_products_ids", removedIds.length > 0 ? removedIds : []);
                        }

                        const pd = item?.split(",");
                        console.log("BEFORE UPDATE table_cr_form", table_cr_form);
                        updateTableStopsForm(
                            productIndex,
                            "products",
                            pd?.filter((item) => item.trim() !== "").map((item) => ({ text: item }))
                        );
                        console.log("AFTER UPDATE table_cr_form", table_cr_form);
                    });

                    setLoading(false);
                }
                setAddProduct(true);
            }


            if (property === "address") data = data.map((item) => item.trim());

            if (property === "address") {
                // setIsAddressFieldDisable(true);
                const body = { addresses: data }
                const response = await getAddress(body);
                await setTableCrFullForm([...response]);

                console.log("data address", response);


                // setTimeout(() => {
                //     setIsAddressFieldDisable(false);
                // }, 1);
            }

            if (
                property === "floor_number" ||
                property === "comment" ||
                property === "duration"
            ) {
                data?.map((item, index) => {
                    const trimmedItem = item.slice(0, 255);
                    updateTableStopsForm(index, property, trimmedItem);
                });
            }
        }
        setTimeout(() => {
            setLoading(false);
        }, 700);
    };

    useEffect(() => {
        totalTableRowsRef.current = table_cr_form?.length;
    }, [table_cr_form]);

    return (
        <>
            <div onClick={() => {
                console.log("cr_form DATA: ", cr_form);
            }} className="flex flex-row items-center justify-between space-x-2 text-cBodyText text-fs14 font-fw500">
                <div className="max-w-[24px] min-w-[24px] text-center">#</div>

                <div
                    className={`${user_role === role.company
                        ? "2xl:max-w-[380px] 2xl:min-w-[380px] xl:max-w-[200px] xl:min-w-[200px] lg:max-w-[200px] lg:min-w-[200px] min-w-[200px]"
                        : "2xl:max-w-[480px] 2xl:min-w-[480px] xl:max-w-[300px] xl:min-w-[300px] lg:max-w-[200px] lg:min-w-[200px] min-w-[200px]"
                        } `}
                >
                    <HeaderItem
                        onClick={() => pasteInfo("address")}
                        label={t("Address*")}
                    />
                </div>

                <div className={`${floor_width}`}>
                    <HeaderItem
                        onClick={() => pasteInfo("floor_number")}
                        label={t("Floor")}
                    />
                </div>
                {user_role === role.company && (
                    <>
                        <div className={`${date_width}`}>
                            <HeaderItem onClick={() => pasteInfo("date")} label={t("Date")} />
                        </div>

                        <div className={time_width}>
                            <HeaderItem
                                onClick={() => pasteInfo("start_time")}
                                label={t("Time")}
                            />
                        </div>
                    </>
                )}

                <div className={`${product_width}`}>
                    <HeaderItem
                        onClick={() => pasteInfo("product")}
                        label={t("Product")}
                    />
                </div>

                <div className={`${comment_width}`}>
                    <HeaderItem
                        onClick={() => pasteInfo("comment")}
                        label={t("Comment")}
                    />
                </div>
                <div
                    title={t("Remove All Stops")}
                    className="max-w-[38px] min-w-[38px] flex justify-center"
                >
                    <div className="flex  h-[24px] justify-start cursor-pointer"></div>
                </div>
            </div>

            <AddAdditionalStopsModal
                pasteInfo={() => {
                    pasteInfo(propertyData);
                }}
                additionalStops={additionalStopsRef.current}
                totalCopyData={
                    additionalStopsRef.current + parseInt(table_cr_form?.length)
                }
                totalTableRows={totalTableRowsRef.current}
            />
        </>
    );
};

const HeaderItem = ({
    label,
    onClick,
    paste = true,
    showPosition = "items-start justify-start",
}) => {
    const [isHover, setHover] = useState(false);

    const { t } = useTranslation();
    return (
        <div
            className={` flex justify-start space-x-[2px] items-center space-c flex-row ${showPosition} `}
        >
            <div className="flex items-center justify-center font-normal w-fit text-fs14 text-cGray800">
                {t(label)}
            </div>
            {paste && (
                <Tooltip color={"#242826"} title={t("paste")}>
                    <div
                        className={`${isHover ? "bg-cGray50" : ""
                            } p-[6px] cursor-pointer rounded-full`}
                        onMouseEnter={() => {
                            setHover(true);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                        onClick={onClick}
                    >
                        <img
                            src={iPast}
                            className="text-base cursor-pointer min-w-[15px] max-w-[15px] max-h-[15px] min-h-[16px]"
                            alt=""
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

const AddAdditionalStopsModal = ({
    pasteInfo,
    additionalStops,
    totalCopyData,
    totalTableRows = 0
}) => {
    const {
        table_cr_form,
        addDeliveryTable,
        is_show_AdditionStopAlertModal,
        setShowAdditionStopAlertModal,
    } = useCreateRequestStore();

    const { setLoading } = useGeneralStore();

    const [rowAddOngoing, setRowAddOngoing] = useState(false);

    const { t } = useTranslation();

    const addMore = async () => {
        setRowAddOngoing(true);
        try {
            for (let i = 0; i < additionalStops; i++) {
                await addDeliveryTable();
            }
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };


    return (
        <CommonModal
            preventClose={rowAddOngoing}
            showCrossIcon={!rowAddOngoing}
            showModal={is_show_AdditionStopAlertModal}
            setShowModal={setShowAdditionStopAlertModal}
            modalTitle={t("Warning")}
            mainContent={
                <>
                    <div className="mt-s20">
                        {`${t("You have copied")} ${totalCopyData} ${t(
                            "items but you have only generated"
                        )} ${totalTableRows} ${t(
                            "rows. Increase"
                        )} ${additionalStops} ${t("rows by clicking below.")}`}
                    </div>
                    <div className="flex flex-row justify-end mt-5">
                        <CommonButton
                            btnLabel={rowAddOngoing ? "Please wait.." : `${t("Increase")} ${additionalStops} ${t("stops")}`}
                            isDisabled={rowAddOngoing}
                            onClick={async () => {
                                setRowAddOngoing(true);
                                setLoading(true);
                                const success = await addMore();
                                console.log("add more success: ", success);
                                setRowAddOngoing(false);
                                if (success) {
                                    setLoading(false);
                                    await pasteInfo();
                                    await setShowAdditionStopAlertModal(false);
                                }
                            }}
                            btn_type="submit"
                            width="w-[180px]"
                        />
                    </div>
                </>
            }
        />
    );
};
