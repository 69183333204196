/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useCarStore from '../../../../app/stores/company/carStore';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';
import CommonInput from '../../../../components/input/CommonInput';
import CommonTitle from '../../../../components/title/CommonTitle';
import SquareImageUploader from '../../../../components/imageUpload/SquareImageUploader';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toastr, countEmptySpaces } from '../../../../app/utility/utilityFunctions';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import BackLink from '../../../../components/backLink/BackLink';

const AddCar = () => {

    const { addCarForm, setNoLicenseApply, setAddCarForm, setCarDetails, resetLicenseAddUpdateForm } = useCarStore();

    const { setPathRecord } = useGeneralStore();

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const location = useLocation();

    const handleSubmit = async () => {
        if (!addCarForm?.name) {
            return Toastr({ message: t("Name is required"), type: "warning" });
        } else if (!addCarForm?.car_license_plate_number) {
            return Toastr({ message: t("License number is required"), type: "warning" });
        } else if (addCarForm?.car_license_plate_number?.length > 9) {
            return Toastr({ message: t("License number max 9 characters"), type: "warning" });
        } else if (!addCarForm?.comment || countEmptySpaces(addCarForm?.comment)) {
            return Toastr({ message: t("Car Specification is required"), type: "warning" });
        }
        resetLicenseAddUpdateForm();
        setCarDetails({ id: null })
        setNoLicenseApply(false)
        navigateTo("/car-manager/add/license");
    };

    useEffect(() => { setPathRecord(location.pathname); }, [location.pathname]);

    return (
        <div>

            <div className='flex justify-between'>
                <div>
                    <CommonTitle
                        title={t("Add Car")}
                        link={"/car-manager"}
                        withConfirm={addCarForm?.name || addCarForm?.car_license_plate_number || addCarForm?.comment || addCarForm?.image}
                        icon={true}
                    />
                    <div className='mt-2 mb-5'>
                        <BackLink
                            linksArray={[
                                { label: t("Add Car"), isActive: true, linkFn: '' },
                                { label: t("Choose License"), onClick: () => handleSubmit(), cursorPointer: true }
                            ]}
                        />
                    </div>
                </div>

                <div className='flex justify-end space-x-5'>
                    <CommonButtonOutlined
                        onClick={() => navigateTo("/car-manager")}
                        colorType='danger' btnLabel={t('Cancel')}
                        width={'w-[100px]'}
                    />
                    <CommonButton
                        isDisabled={!addCarForm?.name || !addCarForm?.car_license_plate_number || !addCarForm?.comment?.trim()}
                        width={'w-[100px]'}
                        type='submit'
                        btnLabel={t('Next')}
                        onClick={handleSubmit}

                    />
                </div>
            </div>

            <div className='flex space-x-10'>
                <div className='w-full'>
                    <SquareImageUploader
                        iImage={addCarForm?.image ?? null}
                        onChange={(e) => { setAddCarForm({ ...addCarForm, image: e }) }}
                        clearImage={() => { setAddCarForm({ ...addCarForm, image: null }) }}
                        rounded={"!rounded-full"}
                    />
                    <div className='mt-5 sub-title'>{t("Details")}</div>

                    <form className='mt-4' onSubmit={(e) => e.preventDefault()}>

                        <div className='flex justify-between space-x-5'>
                            <CommonInput
                                labelText={t('Car Name')}
                                value={addCarForm?.name}
                                name={'name'}
                                max_input={55}
                                type='text'
                                required={true}
                                show_asterisk={true}
                                onChange={(e) => {
                                    if (e.target.value !== " ") {
                                        const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                        setAddCarForm({ ...addCarForm, name: trimmedValue })
                                    }
                                }}
                            />

                            <CommonInput
                                labelText={t('Car License Number')}
                                value={addCarForm?.car_license_plate_number}
                                max_input={9}
                                name={'license'}
                                required={true}
                                show_asterisk={true}
                                onChange={(e) => {
                                    if (e.target.value !== " ") {
                                        const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                        setAddCarForm({ ...addCarForm, car_license_plate_number: trimmedValue })
                                    }
                                }}
                            />
                        </div>
                        <CommonInput
                            textarea={true}
                            rows={1}
                            max_input={255}
                            labelText={t('Car Specification')}
                            value={addCarForm?.comment}
                            name={'details'}
                            required={true}
                            show_asterisk={true}
                            onChange={(e) => {
                                setAddCarForm({ ...addCarForm, comment: e.target.value })
                            }} />
                    </form>
                </div>

                <div className='max-w-[380px] min-w-[380px]'></div>

            </div>
        </div>
    )
}

export default AddCar
