/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useShiftStore from "../../../../../../app/stores/company/shiftStore";
import useCreateRequestStore, { isCrFormValidation, isFormComplete, saveRequest } from "../../../../../../app/stores/others/createRequestStore";
import useRequestStore, { getRequestShiftPlannerList } from "../../../../../../app/stores/others/requestStore";
import { all_shift_path, create_request_steps, k_cr_actions, k_request_paths } from "../../../../../../app/utility/const";
import RequestAccordion from "../../../../../../components/Accordion/RequestAccordion";
import AvailableShifts from "./components/AvailableShifts";
import AvailableShiftsView from "../../../../requests/requestDetails/components/summary/components/AvailableShifts";
import CommonEmptyView from "./components/CommonEmptyView";

export default function SelectShift() {
  const location = useLocation();

  const { request_id, type } = useParams();

  const isNotPlannedEditMode = location.pathname.includes(`requests/not-planned/details/${request_id}/edit`);
  const isNotPlannedViewMode = location.pathname.includes(`requests/not-planned/details/${request_id}`);

  const { available_shifts, setAvailableShifts, selectedShift, setSelectedShift, request_details } = useRequestStore();

  const { setShowAddShiftModal, setAllShiftCarList, setAllShiftDriverList } = useShiftStore();

  const { isApplyGenerate, setAvailableShiftSearchKey, changeCrForm, cr_form, expandedAccordion, setExpandedAccordion, setCurrentSetup } = useCreateRequestStore();

  const [isExpandedShift, setExpandedShift] = useState(false);

  const { t } = useTranslation();

  const selectedNewRequestShift = available_shifts?.find((i) => parseInt(i.id) === parseInt(cr_form?.shift_id))

  const showShiftList = isNotPlannedEditMode || isFormComplete(cr_form);

  const navigateTo = useNavigate();

  useEffect(() => {
    if (isNotPlannedEditMode) {
      setAvailableShifts([]);
      setSelectedShift(null);
      const startTime = request_details?.pickup_stop?.start_time;
      const endTime = request_details?.pickup_stop?.end_time;
      const pickupDate = request_details?.pickup_stop?.date;
      getRequestShiftPlannerList(startTime, endTime, pickupDate, true);
    }

  }, [request_id]);

  return (
    <RequestAccordion
      isInvalid={isApplyGenerate && !isCrFormValidation()?.shift}
      onClick={() => setExpandedShift(!isExpandedShift)}
      showClearButton={false}
      isTable={available_shifts?.length > 0 ? true : false}
      title={expandedAccordion === create_request_steps.select_shift && showShiftList ? t("Shift Planner") + ` (${Object.values(available_shifts)?.length < 10 ? Object.values(available_shifts)?.length : "10"})` : t("Shift Planner")}
      id="accordion3"
      isExpanded={expandedAccordion === create_request_steps.select_shift}
      onExpand={() => {
        setExpandedAccordion(expandedAccordion === create_request_steps.select_shift ? null : create_request_steps.select_shift)
        if (expandedAccordion !== create_request_steps.select_shift && isNotPlannedEditMode && available_shifts?.length === 0) {
          const startTime = request_details?.pickup_stop?.start_time;
          const endTime = request_details?.pickup_stop?.end_time;
          const pickupDate = request_details?.pickup_stop?.date;

          getRequestShiftPlannerList(startTime, endTime, pickupDate, true);
        }
      }}
      secondaryTitle={showShiftList ? available_shifts?.length > 10 ? t("See all shifts") : t("Add Shift") : ""}
      handleSecondaryTitleClick={() => {
        if (showShiftList && available_shifts?.length > 10) {
          saveRequest(k_cr_actions.save, false);
        }

        if (available_shifts?.length > 10) {
          setAvailableShiftSearchKey("");
          setCurrentSetup(create_request_steps?.allShifts)
          navigateTo(
            all_shift_path,
            {
              state:
              {
                ...location?.state,
                referrer: location?.state?.referrer ? location?.state?.referrer : location?.pathname?.includes(`/global-request/details/${request_id}`) ? "global-request" : type,
                isEditMode: (location.pathname?.includes(`/request/edit/${request_id}`) || location.pathname === `/request/create` || location.pathname === `/requests/not-planned/details/${request_id}/edit`) ? true : false
              }
            }
          )
        } else {
          setShowAddShiftModal(true);
          setAllShiftCarList([]);
          setAllShiftDriverList([]);
        }
      }}
      subTitleOne={selectedNewRequestShift?.driver_name ?? location.pathname.includes(k_request_paths.not_planned) ? `${Object.values(available_shifts)?.length} Shifts found` : ""}
    >
      <div>
        {
          // isNotPlannedViewMode ?
          //   <AvailableShiftsView withoutTitles />
          //   :
          showShiftList || isNotPlannedViewMode ?
            <AvailableShifts
              selectedId={isNotPlannedEditMode ? selectedShift?.id : cr_form?.shift_id}
              onChange={async (item) => {
                if (isNotPlannedEditMode) {
                  if (item?.id === selectedShift?.id) setSelectedShift(null);
                  else setSelectedShift(item);
                } else {
                  if (item?.id === cr_form?.shift_id) {
                    // await changeCrForm("shift_plan", null);
                    // await changeCrForm("shift_id", null);
                  } else {
                    await changeCrForm("shift_plan", item);
                    await changeCrForm("shift_id", item?.id);
                  }
                }
              }}
            />
            :
            <CommonEmptyView message={location.pathname.includes(k_request_paths.not_planned) ? "No shift available." : t("Fill out the required fields first.")} />

        }

      </div>
    </RequestAccordion>
  );
}
