/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import CommonSearchBox from '../../../../components/input/CommonSearchBox';
import CommonModal from '../../../../components/modal/CommonModal';
import { iAdmin, iUserAvatar } from '../../../../app/utility/imageImports';
import useChatStore, { getChatUserList, getMessageList } from '../../../../app/stores/others/chatStore';
import { chat_role, user_role as role } from '../../../../app/utility/const';
import Image from '../../../../components/image/Image';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import PageScrollLoader from '../components/PageScrollLoader';

const AddChatModal = () => {

    const { t } = useTranslation();

    const { user_role } = useGeneralStore();

    const { selectChat, setTextFieldCount, current_role, setAddChatUserList, chat_list, setSelectChat, showAddChatModal, setShowAddChatModal, add_chat_user_list, user_search, setUserSearch, setAddMessageArray } = useChatStore();

    const [searchValue] = useDebounce(user_search, 500);

    const [scroll, setScroll] = useState(false);

    const incrementData = async () => {
        setScroll(true);
        const success = await getChatUserList({ search: user_search, user_role: user_role, pagination: true, isLoading: false })
        if (success?.success) {
            setAddChatUserList({
                current_page: success?.data?.current_page,
                last_page: success?.data?.last_page,
                data: [...add_chat_user_list?.data, ...success?.data?.data],
                total: success?.data?.total
            });
            setScroll(false);
        } else {
            setScroll(false);
        }
    }

    useEffect(() => {
        setUserSearch("")
        if (showAddChatModal) {
            getChatUserList({ search: user_search, user_role: user_role, pagination: false, isLoading: true });
        }
    }, [showAddChatModal])

    useEffect(() => {
        if (showAddChatModal) {
            getChatUserList({ search: user_search, user_role: user_role, pagination: false, isLoading: false });
        }
    }, [searchValue])


    return (
        <div>
            <CommonModal
                showModal={showAddChatModal}
                setShowModal={setShowAddChatModal}
                modalTitle={t("Chat with")}
                modalSpace={true}
                mainContent={
                    <>
                        <div className='my-5'>
                            <CommonSearchBox
                                value={user_search}
                                onChange={(e) => { setUserSearch(e.target.value) }}
                                fullWidth={true}
                                onSearchClear={() => { setUserSearch("") }}
                                withClearSearch={true}
                                roundedFull={false}
                            />
                        </div>

                        <div className='space-y-3'>
                            {
                                add_chat_user_list?.data?.length > 0 ?
                                    <div id="scrollableDiv">
                                        <InfiniteScroll
                                            height={add_chat_user_list?.data?.length > 6 ? 400 : add_chat_user_list?.data?.length * 60}
                                            dataLength={add_chat_user_list?.data?.length ?? 0} //This is important field to render the next data
                                            next={() => {
                                                if (!scroll && (add_chat_user_list?.current_page < add_chat_user_list?.last_page)) {
                                                    incrementData()
                                                }
                                            }}
                                            hasMore={!scroll && add_chat_user_list?.current_page < add_chat_user_list?.last_page}
                                            scrollableTarget="scrollableDiv"
                                            scrollThreshold={0.9}
                                        >
                                            {
                                                add_chat_user_list?.data?.map((item, index) => (
                                                    <CommonUserList
                                                        onClick={async () => {
                                                            setAddMessageArray([]);
                                                            setTextFieldCount(40);
                                                            await setSelectChat({
                                                                id: item?.chat_id,
                                                                channel_name: current_role === chat_role.customer ? `${chat_role.customer}-${item?.user_role}` :
                                                                    item?.user_role + "-" + chat_role.company,
                                                                image: item?.image,
                                                                is_active: 1,
                                                                name: item?.name,
                                                                user_id: item?.id,
                                                                user_type: item?.user_role,
                                                                new: selectChat?.new ? !selectChat?.new : true
                                                            });

                                                            if (item?.chat_id) { await getMessageList({ "chat_id": item?.id, take: 10 }) }

                                                            setShowAddChatModal(false);
                                                            if (chat_list?.data?.length > 0) {
                                                                const index = chat_list?.data?.findIndex(i => i?.id === item?.chat_id);
                                                                chat_list.data[index].last_message.to_is_read = 1;
                                                            }
                                                        }}
                                                        key={index}
                                                        name={item?.name}
                                                        user_type={item?.user_role}
                                                        imageUrl={item?.image}
                                                    />
                                                ))
                                            }

                                            {
                                                scroll && <PageScrollLoader />
                                            }
                                        </InfiniteScroll>
                                    </div>
                                    :
                                    <div className='text-center'>{t("No user found !")}</div>
                            }
                        </div>
                    </>
                }
            />
        </div>
    )
}

export default AddChatModal


const CommonUserList = ({ name = "", user_type = "", imageUrl, onClick = () => { } }) => {

    return (
        <div onClick={onClick} className='flex p-2 space-x-2 rounded cursor-pointer hover:bg-cPrimary100'>
            <div className='bg-cTintBlue rounded-full w-[44px] h-[44px] flex items-center justify-center'>
                {
                    user_type === role?.admin ?
                        <img alt="" src={iAdmin} className='max-w-[44px] min-w-[44px] h-[44px]' />

                        :
                        <Image
                            alt=""
                            src={imageUrl}
                            roundedFull={false} dummyImage={iUserAvatar}
                            className='max-w-[44px] min-w-[44px] h-[44px] rounded'
                        />
                }

            </div>
            <div>
                <div className='capitalize text-cMainBlack font-fw500 text-fs16'>{name}</div>
                <div className='capitalize text-cGrey font-fw400 text-fs12 mt-s2'>
                    {user_type === role?.admin ? "admin" : user_type}
                </div>
            </div>
        </div>
    )
}