import React, { useState, useEffect } from 'react';

function NotificationPermission() {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const notificationShown = localStorage.getItem('notificationShown');
        if (!notificationShown && Notification.permission === 'denied') {
            setIsOpen(true);
        }
    }, []);

    const togglePopup = () => {
        setIsOpen(!isOpen);
        localStorage.setItem('notificationShown', 'true');
    };

    return (
        <div>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex justify-center h-fit">
                    <div className="max-w-sm p-6 mx-auto bg-white rounded-lg shadow-lg">
                        <h2 className="mb-4 text-xl font-bold">Browser notifications are disabled</h2>
                        <p className="mb-4 text-gray-700">Please enable browser notifications to receive updates</p>
                        <button className="px-4 py-2 font-bold text-white rounded bg-cBrand focus:outline-none focus:shadow-outline" onClick={togglePopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default NotificationPermission;
