/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import useCreateRequestStore from "../../../../../../../../app/stores/others/createRequestStore";
import StopRow from "../StopRow";
import { Toastr } from "../../../../../../../../app/utility/utilityFunctions";
import { common_take } from "../../../../../../../../app/utility/const";
import RemoveAllStopConfirmationModal from "./components/RemoveAllStopConfirmModal";
import { useTranslation } from "react-i18next";
import { iNext, iPrevious } from "../../../../../../../../app/utility/imageImports";
import { useEffect } from "react";
import { Header } from "./components/Header";

function StopsTable() {

  const { setDeliverySelectIndex, setSelectStop, setShowEditDeliveryModal, table_cr_form, cr_form, addDeliveryTable, tableCurrentPage, setTableCurrentPage } = useCreateRequestStore();

  const { t } = useTranslation();

  const [stopCount, setStopCount] = useState(1);

  useEffect(() => {
    for (let i = 0; i < 30 - cr_form?.deliveries?.length; i++) {
      addDeliveryTable();
    }
    window.scrollTo(0, 0);
  }, [])

  const totalStops = parseInt(stopCount) + parseInt(table_cr_form?.length);

  const addMoreStops = () => {
    return new Promise((resolve, reject) => {

      if (stopCount < 1) {
        Toastr({ message: t("Please enter a number between 1 and 200."), type: "warning" });
        reject("Minimum limit not met");
        return;
      }

      if (totalStops > 200) {
        Toastr({ message: t("Maximum 200 items allowed."), type: "warning" });
        reject("Maximum limit exceeded");
        return;
      }

      let deliveriesAdded = 0;
      const batchSize = 10; // Adjust batch size as needed

      const addBatch = () => {
        const remainingDeliveries = stopCount - deliveriesAdded;
        const deliveriesToAdd = Math.min(batchSize, remainingDeliveries);

        for (let i = 0; i < deliveriesToAdd; i++) {
          addDeliveryTable(); // Add one delivery
          deliveriesAdded++;
        }

        if (deliveriesAdded < stopCount) {
          // Schedule the next batch
          requestAnimationFrame(addBatch);
        } else {
          setStopCount(1); // Reset stop count
          resolve(); // All deliveries added
        }
      };

      // Start adding deliveries with the first batch
      requestAnimationFrame(addBatch);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [tableCurrentPage])

  const numberOfPages = Math.ceil(table_cr_form?.length / common_take);

  const transformedArray = Array.isArray(table_cr_form)
    ? table_cr_form.slice((tableCurrentPage - 1) * common_take, tableCurrentPage * common_take)
    : [];

  // useEffect(() => {
  //   console.log("table_cr_form", table_cr_form);
  // }, [table_cr_form]);


  return (
    <>
      <div className="relative flex flex-col items-start w-full">
        <div className="w-full p-3 rounded bg-cGray100">
          <Header />
          <div className="space-y-2 pt-[4px]">
            {
              table_cr_form?.slice((tableCurrentPage - 1) * common_take, tableCurrentPage * common_take)?.map(
                (stop, index) => {
                  const originalIndex = (tableCurrentPage - 1) * common_take + index;
                  return (
                    <StopRow
                      transformedArray={transformedArray?.length}
                      currentPage={tableCurrentPage}
                      id={originalIndex}
                      key={index}
                      data={stop}
                      productArray={stop?.products?.map((item) => item.text).join(", ")}
                      index={originalIndex}

                      onSelect={() => {
                        setDeliverySelectIndex(originalIndex);
                        setSelectStop(stop)
                        setShowEditDeliveryModal(true);
                      }}
                    />
                  );
                })}
          </div>
        </div>

        <div className="flex justify-between w-full mt-5">
          <div className="flex items-center justify-center space-x-3">
            <div>
              <input
                type="number"
                className="py-1 font-normal text-center rounded outline-none w-s70 border-[2px] text-fs18 text-cGray800 border-cGray200"
                maxLength={3}
                max={100}
                min={1}
                minLength={1}
                pattern="\d*" // Allow only digits
                // onInput={(e) => {
                //   // Replace non-digits with nothing
                //   e.target.value = e.target.value.replace(/[^0-9]/g, '');
                // }}
                onChange={(e) => {
                  setStopCount(e.target.value);
                }}
                value={stopCount}
              />
            </div>
            <div
              onClick={addMoreStops}
              className="flex items-center justify-center rounded cursor-pointer h-s38 px-s10 tex-fs16 text-medium hover:bg-cPrimary100 text-cBrand"
            >
              {t("Add Row")}
            </div>
          </div>


          <div>
            <div className="flex items-center justify-center">
              <button className="h-s40 w-s40 border-y-[1px] border-l-[1px] border-cGray200 rounded-tl flex justify-center items-center rounded-bl" onClick={() => setTableCurrentPage(Math.max(1, tableCurrentPage - 1))} disabled={tableCurrentPage === 1}>
                <img src={iPrevious} alt='' className='max-w-[16px] min-w-[16px] max-h-[16px] min-h-[16px]' />
              </button>
              {[...Array(numberOfPages).keys()].map(i => {
                if (i < 4 || i === numberOfPages - 1 || (i >= tableCurrentPage - 1 && i <= tableCurrentPage + 2)) {
                  return (
                    <button
                      key={i}
                      onClick={() => setTableCurrentPage(i + 1)}
                      className={`h-s40 w-s40 ${tableCurrentPage === i + 1 ? 'text-cMainBlack border-[1px] border-cBrand  hover:bg-cBorderBottom bg-cPrimary100 hover:text-cMainBlack'
                        : `${tableCurrentPage + 1 === i + 1 ? "" : "border-l-[1px]"}  border-y-[1px] border-cGray200 bg-white hover:text-cTextShopDetails`}`}
                    >
                      {i + 1}
                    </button>
                  );
                } else if (i === 4 || (i === tableCurrentPage + 3 && i !== numberOfPages - 2)) {
                  return <span className="flex items-center justify-center border h-s40 w-s40 border-cGray200 " key={i}>...</span>;
                }
                return null;
              })}
              <button className="h-s40 w-s40 border-[1px] flex justify-center items-center rounded-tr rounded-br border-cGray200 " onClick={() => setTableCurrentPage(Math.min(numberOfPages, tableCurrentPage + 1))} disabled={tableCurrentPage === numberOfPages}>
                <img src={iNext} alt='' className='max-w-[16px] min-w-[16px] max-h-[16px] min-h-[16px]' />
              </button>
            </div>
          </div>

        </div>
      </div>
      <RemoveAllStopConfirmationModal />
    </>
  );
}

export default StopsTable;



