import React from 'react';
import useRequestStore from '../../../../../../app/stores/others/requestStore';
import CommonModal from '../../../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import Image from '../../../../../../components/image/Image';
import { iFavCompanyGray, iFavoriteIcon, iFavoriteIconGray } from '../../../../../../app/utility/imageImports';
import RatingFiveStar from '../../../../../../components/rating/RatingFiveStar';
import CommonViewComponent from '../../../../../../components/viewer/CommonViewComponent';

const BiddingDetailsModal = ({ data }) => {

    const { showBiddingModal, setBiddingModal } = useRequestStore();

    const { t } = useTranslation();

    return (
        <div>
            <CommonModal
                widthClass='w-[720px]'
                showModal={showBiddingModal}
                setShowModal={setBiddingModal}
                modalTitle={t("Bidding Details")}
                mainContent={
                    <>
                        <div className='mt-5'>
                            <div className='flex justify-between'>
                                <div className='flex mb-5 space-x-2'>
                                    <Image dummyImage={iFavCompanyGray} className="rounded-full max-w-[60px] min-w-[60px] h-s60" src={data?.company_info?.image} />
                                    <div className='flex flex-col justify-center gap-1'>
                                        <div className='text-cGray800 text-medium text-fs16'>{data?.company_info?.name ?? "NA"}</div>
                                        <RatingFiveStar rating={Math.round(data?.company_info?.rate)} />
                                        {data?.company_info?.is_favorite ? 
                                            <img className='mt-s9 max-w-[15px] min-w-[15px] h-[15px]' src={ iFavoriteIcon } alt='' />
                                            : 
                                            <></>
                                        }
                                    </div>
                                </div>

                                <div className='flex items-center justify-center title text-cBrand'>DKK {data?.budget?.toLocaleString("da-DK")}</div>
                            </div>

                            <CommonViewComponent labelText={t("Description")} value={data?.details} />
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default BiddingDetailsModal;