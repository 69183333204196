/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import CommonModal from '../../../../../../components/modal/CommonModal'
import CommonDatePicker from '../../../../../../components/input/CommonDatePicker'
import CommonSelect from '../../../../../../components/select/CommonSelect'
import CommonButton from '../../../../../../components/button/CommonButton'
import useRequestStore, { getTableViewRequestsData } from '../../../../../../app/stores/others/requestStore'
import { k_requests_status, user_role as role } from '../../../../../../app/utility/const'
import { getStringFromDateObject } from '../../../../../../app/utility/utilityFunctions'
import useGeneralStore from '../../../../../../app/stores/others/generalStore'
import { useTranslation } from 'react-i18next'
import { kuGetRequestsNew } from '../../../../../../app/urls/commonUrl'

export default function SavedFilter({ showModal, setShowModal }) {
  const { setRequestApiUrl, request_filter_form, changeRequestFilterForm, transport_type, resetRequestFilterForm, resetFilterRange, updateRequestFilterForm, request_filter_form_copy, updateRequestFilterFormCopy, } = useRequestStore();
  const { user_role } = useGeneralStore();
  const [status, setStatus] = React.useState([]);
  const { t } = useTranslation();


  const divRef = useRef(null);

  useEffect(() => { updateRequestFilterForm(request_filter_form_copy) }, [showModal]);

  useEffect(() => {
    if (divRef.current) divRef.current.focus();
    if (user_role === role.customer) {
      setStatus([
        { title: t('Saved in Pickup'), value: 'init' },
        { title: t('Saved in Delivery'), value: 'stops' },
        { title: t('Saved in Company'), value: 'company' },
        // { title: t('Saved in summary'), value: 'summary' },
      ]);
    } else {
      setStatus([
        { title: t('Saved in Pickup'), value: 'init' },
        { title: t('Saved in Delivery'), value: 'stops' },
        { title: t('Saved in Shift'), value: 'shift' },
        // { title: t('Saved in summary'), value: 'summary' },
        { title: t('Saved in Proposal'), value: 'proposal' },
      ]);
    }
  }, []);


  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={
        <div className='flex items-baseline'>
          <div>{t('Filter')}</div>
          <div className='pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed'
            onClick={async () => {
              await resetRequestFilterForm();
              resetFilterRange();
              setRequestApiUrl(kuGetRequestsNew);
              await getTableViewRequestsData({
                data_set: k_requests_status.saved,
                filter: {
                  transport_type: "",
                  pickup_date_from: "",
                  pickup_date_to: "",
                  saved_date_from: "",
                  saved_date_to: "",
                  status: "",
                }
              });
              updateRequestFilterFormCopy("");
              setShowModal(false);
            }} >{t('Clear')}</div>
        </div>
      }
      mainContent={
        <>
          <form className='mt-6'
            onSubmit={async (e) => {
              e.preventDefault();
              setRequestApiUrl(kuGetRequestsNew);
              await getTableViewRequestsData({
                data_set: k_requests_status.saved, filter: {
                  transport_type: request_filter_form?.transport_type,
                  pickup_date_from: request_filter_form?.pickup_date_from,
                  pickup_date_to: request_filter_form?.pickup_date_to,
                  saved_date_from: request_filter_form?.saved_date_from,
                  saved_date_to: request_filter_form?.saved_date_to,
                  status: request_filter_form?.status,
                }
              });
              updateRequestFilterFormCopy(request_filter_form);
              setShowModal(false);
            }}>

            <div tabIndex="0" ref={divRef} className='pt-0' ></div>

            <div className='grid items-center grid-cols-2 gap-7'>
              <CommonSelect label={t('Transportation Type')} dataArray={transport_type} has_subtitle={false} onChange={(e, value) => {
                changeRequestFilterForm('transport_type', value);
              }} value={request_filter_form?.transport_type} />

              {/* <CommonSelect label={t('Select Status')} placeholder={t('Select Status')} dataArray={status} has_subtitle={false} onChange={(e, value) => {
                changeRequestFilterForm('status', e.target.value);
              }} value={request_filter_form?.status} /> */}
            </div>


            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonDatePicker
                value={request_filter_form?.pickup_date_from}
                onChange={(date) => {
                  if (new Date(date) > new Date(request_filter_form?.pickup_date_to)) {
                    changeRequestFilterForm('pickup_date_from', getStringFromDateObject(date))
                    changeRequestFilterForm('pickup_date_to', "")
                  } else {
                    changeRequestFilterForm('pickup_date_from', getStringFromDateObject(date))
                  }
                }}
                label={t('Pickup From')}
              />

              <CommonDatePicker
                startDate={request_filter_form?.pickup_date_from}
                value={request_filter_form?.pickup_date_to}
                onChange={(date) => changeRequestFilterForm('pickup_date_to', getStringFromDateObject(date))}
                label={t('Pickup To')}
              />
            </div>

            <div className="mt-[26px] grid grid-cols-2 items-center gap-7">
              <CommonDatePicker
                value={request_filter_form?.saved_date_from}
                onChange={(date) => {
                  if (new Date(date) > new Date(request_filter_form?.saved_date_from)) {
                    changeRequestFilterForm('saved_date_from', getStringFromDateObject(date))
                    changeRequestFilterForm('saved_date_to', "")
                  } else {
                    changeRequestFilterForm('saved_date_from', getStringFromDateObject(date))
                  }
                }}
                label={t('Saved From')}
              />

              <CommonDatePicker
                startDate={request_filter_form?.saved_date_from}
                value={request_filter_form?.saved_date_to}
                onChange={(date) => changeRequestFilterForm('saved_date_to', getStringFromDateObject(date))}
                label={t('Saved To')}
              />
            </div>

            <div className='flex flex-row items-center justify-end w-full mt-5'>
              <CommonButton btnLabel={t('Apply Filter')} type='submit' />
            </div>

          </form>
        </>
      }
    />
  )
}
