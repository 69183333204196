import React from "react";
import useCreateRequestStore from "../../app/stores/others/createRequestStore";
import RequestAccordion from "./RequestAccordion";
import { useTranslation } from "react-i18next";
import { Toastr } from "../../app/utility/utilityFunctions";

const DeliveryAccordion = ({
  optionArray = [],
}) => {

  const {
    setIsInvalidProduct,
    stopData,
    tampDeliveries,
    tampDeliveriesIndex,
    setCrFullForm,
    setTampDeliveriesIndex,
    setTampDeliveries,
    deliveryExpandedAccordion,
    setDeliveryExpandedAccordion,
    cr_form,
    removeDelivery,
    updateCrField,
    isApplyGenerate
  } = useCreateRequestStore();

  const { t } = useTranslation();

  const updateDelivery = (indexToRemove, newDelivery) => {
    // Create a copy of the current deliveries array
    const updatedDeliveries = [...cr_form?.deliveries];

    // Remove the delivery at the specified index
    if (indexToRemove >= 0 && indexToRemove < updatedDeliveries.length) {
      updatedDeliveries.splice(indexToRemove, 1);
    }

    // Insert the new delivery object at the same index
    updatedDeliveries.splice(indexToRemove, 0, newDelivery);

    // Update the state with the new deliveries array
    setCrFullForm({
      ...cr_form,
      deliveries: updatedDeliveries,
    });
  };

  const isCrossVisible =
    !!tampDeliveries?.date ||
    !!tampDeliveries?.start_time ||
    !!tampDeliveries?.duration ||
    !!tampDeliveries?.end_time ||
    !!tampDeliveries?.delivery_time ||
    !!tampDeliveries?.floor_number ||
    !!tampDeliveries?.address ||
    !!tampDeliveries?.comment ||
    !!tampDeliveries?.attachment ||
    stopData?.products.length > 1
  // || !!tampDeliveries?.products?.filter((item) => !!item.text).length;
  return (
    <div className="space-y-5">
      {
        optionArray?.map((itemOriginal, index) => {
          // Calculate the reversed index
          const reversedIndex = optionArray.length - 1 - index;
          let item = { ...itemOriginal, ...itemOriginal?.products?.length === 0 ? { products: [{ text: '' }] } : {} };
          return (
            <RequestAccordion
              onClick={() => {
                console.log("STOP DATA: ", item);
              }}
              isInvalid={isApplyGenerate && (!(item?.lat && item?.lng) || new Date(item?.date) <= new Date(cr_form?.pickup?.date))}
              showCancelRightButton={true}
              clearButtonTooltipMessage={tampDeliveriesIndex !== index ? t("Delete") : t("Clear")}
              showClearButton={tampDeliveriesIndex !== index ? true : isCrossVisible}
              clearHandler={() => {

                if (tampDeliveriesIndex === index) {
                  setTampDeliveries({
                    date: "",
                    formatted_date: "",
                    start_time: "",
                    end_time: "",
                    delivery_time: "",
                    floor_number: "",
                    address: "",
                    lat: null,
                    lng: null,
                    comment: "",
                    attachment: "",
                    attachment_url: "",
                    temp_attachment: null,
                    products: [{ text: "Product" }],
                    has_old_image: 0,
                  })
                } else {
                  removeDelivery(index);
                  if (cr_form?.removed_stops_ids?.length > 0) {
                    updateCrField("removed_stops_ids", [...cr_form?.removed_stops_ids, parseInt(item?.id) ? parseInt(item?.id) : null]);
                  } else {
                    updateCrField("removed_stops_ids", parseInt(item?.id) ? [parseInt(item?.id)] : []);
                  }
                }
              }}

              thirdTitle={tampDeliveriesIndex === index && t("Cancel")}
              handleThirdTitleClick={() => {
                setTampDeliveriesIndex(null)
                setTampDeliveries(null)
              }}

              secondaryTitle={tampDeliveriesIndex === index ? t("Update") : t("Edit")}
              isDisabledSecondaryTitle={new Date(tampDeliveries?.date) < (cr_form?.pickup?.date ? new Date(cr_form?.pickup?.date) : new Date())}
              handleSecondaryTitleClick={async () => {
                setIsInvalidProduct(false)
                if (tampDeliveriesIndex === index) {  //note: update mode codes...

                  if (tampDeliveries?.date && !tampDeliveries?.start_time) return Toastr({ message: t("Please fill in the time field!"), type: "warning" });
                  if (tampDeliveries?.date && !tampDeliveries?.end_time) return Toastr({ message: t("Please fill in the range field!"), type: "warning" });
                  // if (new Date(tampDeliveries?.date) < new Date()) {
                  //   return Toastr({ message: t("Invalid Date!"), type: "warning" });
                  // }
                  // console.log("tampDeliveries", tampDeliveries)
                  if (tampDeliveries?.address && tampDeliveries?.lat && tampDeliveries?.lng) {
                    setTampDeliveriesIndex(null)
                    setTampDeliveries(null)
                    await updateDelivery(tampDeliveriesIndex, tampDeliveries);
                  }
                  else {
                    return Toastr({ message: t("Please fill in the address field!"), type: "warning" });
                  }
                } else {  //note: view mode codes...
                  setTampDeliveriesIndex(index)
                  // let finalStop = item?.stop;
                  // if (finalStop?.products) { if (finalStop?.products?.length === 0) finalStop.products = [{ text: "" }]; }
                  // else { finalStop = { ...finalStop, products: [{ text: "" }] }; }
                  // console.log("final stop : ", finalStop);
                  // setTampDeliveries(finalStop);
                  setTampDeliveries(item?.stop?.products ? item?.stop?.products?.length === 0 ? { ...item?.stop, products: [{ text: '' }] } : item?.stop : { ...item?.stop, products: [{ text: '' }] })

                }
              }}

              fontSize="text-fs18"
              key={index}
              // title={t("Delivery") + " " + parseInt(reversedIndex + 1)}
              title={t("Delivery") + " " + parseInt(index + 1)}
              isForm={true}
              isBorder={true}
              subTitleTwo={item?.address}
              subTitleOne={item?.products?.length + " " + t("products")}
              isExpanded={deliveryExpandedAccordion === index}
              onExpand={() => {
                setDeliveryExpandedAccordion(deliveryExpandedAccordion === index ? null : index)
                setTampDeliveriesIndex(null)
                setTampDeliveries(null)
              }}
              handleCancel={() => {
                removeDelivery(index);
                if (cr_form?.removed_stops_ids?.length > 0) {
                  updateCrField("removed_stops_ids", [...cr_form?.removed_stops_ids, parseInt(item?.id)]);
                } else {
                  updateCrField("removed_stops_ids", parseInt(item?.id) ? [parseInt(item?.id)] : []);
                }
              }}
              TransitionProps={{
                timeout: 500
              }}
            >
              {item?.children}
            </RequestAccordion>
          );
        })
      }

    </div>
  );
};

export default DeliveryAccordion;






