/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import CommonViewComponent from '../../../../components/viewer/CommonViewComponent';
import ImageViewer from '../../../../components/image/ImageViewer';
import CommonInput from '../../../../components/input/CommonInput';
import CommonTimePicker from '../../../../components/input/CommonTimePicker';
import RequestDetailsTextTitle from '../../../common/requests/requestDetails/components/mainContents/components/RequestDetailsTextTitle';
import { useEffect } from 'react';
import useRequestStore, { defineNotPlannedDeliveryDate } from '../../../../app/stores/others/requestStore';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTimeHourMinutes } from '../../../../app/utility/utilityFunctions';
import { iGrayCancel } from '../../../../app/utility/imageImports';
import { Tooltip } from 'antd'
import { useParams } from 'react-router-dom';

const Details = () => {

    const { request_details } = useRequestStore();

    const { generateStops, setPickupDuration, not_planned_stops, updateStopInfo, pickup_duration } = useRequestStore();

    const { t } = useTranslation();

    const { request_id } = useParams();

    useEffect(() => {
        generateStops({ stops: request_details?.stops, date: request_details?.pickup_date });
        setPickupDuration(request_details?.pickup_duration ?? "")
    }, [request_details, request_id, request_details?.pickup_duration])



    return (
        <div>
            {/*b         main content (left) */}
            <div className='border-[1px] border-cGray200 rounded p-3 space-y-2 w-full'>
                <RequestDetailsTextTitle title={t('pickup overview')} />

                <CommonViewComponent labelText={t('Title')} value={request_details?.pickup_title ?? "NA"} />

                <CommonViewComponent labelText={t('Type')} value={request_details?.transport_type ?? "NA"} />
                <CommonViewComponent labelText={t('Address')} value={request_details?.pickup_address ?? "NA"} />
                <CommonViewComponent labelText={t('Floor number')} value={request_details?.pickup_floor_number} />

                <CommonViewComponent className='z-100' labelText={t('Date & Time')} value={request_details?.pickup_date ? formatDate(request_details?.pickup_date) + ", " + formatTimeHourMinutes(request_details?.pickup_expected_time) : "NA"} />

                <div className="w-[45%]">
                    <div className={`relative mt-[-7px]`}>
                        <CommonInput
                            type='number'
                            labelText={t('Duration (min)')}
                            name={'pickup_duration'}
                            value={pickup_duration}
                            max_input={5}
                            onChange={(e) => { setPickupDuration(e.target.value); }}
                        />

                        <div className='absolute right-0 flex space-x-2 bottom-1'>
                            {
                                pickup_duration ?
                                    <Tooltip title={t('Clear Duration')} color={"#242826"} >
                                        <img onClick={() => {
                                            setPickupDuration("")
                                        }} className='w-5 h-5 cp' src={iGrayCancel} alt="" />
                                    </Tooltip> :
                                    <></>
                            }
                            {/* <img className='w-5 h-5' src={iClockGray} alt="" /> */}
                        </div>
                    </div>
                </div>

                <div className='pt-1'>
                    <CommonViewComponent
                        labelText={t('Comment')}
                        value={request_details?.pickup_comment ?? "NA"}
                    />
                </div>

                {
                    request_details?.pickup_attachment &&
                    <ImageViewer src={request_details?.pickup_attachment} label={t('Attachment')} withExtension={true}
                    />
                }

            </div>

            {
                request_details?.stops?.length > 0 ? request_details?.stops?.map((stop, index) => (

                    <div key={index} className='pt-5' >

                        {/* 2nd main content */}
                        <div className='border-[1px] border-cGray200 rounded p-3 space-y-2 w-full'>
                            <RequestDetailsTextTitle title={`${t("delivery")} ` + (index + 1)} />

                            <CommonViewComponent labelText={t('Delivery Address')} value={stop?.address ?? "NA"} />
                            <CommonViewComponent labelText={t('Floor Number')} value={stop?.floor_number} />

                            <>
                                <CommonViewComponent labelText={t('Delivery Date')} value={defineNotPlannedDeliveryDate(request_details?.pickup_date, not_planned_stops[index]?.start_time, request_details?.pickup_start_time)} className='my-[20px]' />

                                <div className='grid grid-cols-2 mt-2 gap-x-5'>
                                    <CommonTimePicker
                                        label={t('Time')}
                                        name={'start_time'}
                                        init_time={not_planned_stops[index]?.start_time?.slice(0, 5)}
                                        onChange={(start_time, end_time) => {
                                            updateStopInfo(index, 'start_time', start_time)
                                            updateStopInfo(index, 'end_time', end_time)
                                        }}
                                        showExtendedTimeUi={false}
                                        showClear={true}
                                        handleClear={() => {
                                            updateStopInfo(index, 'start_time', '')
                                            updateStopInfo(index, 'end_time', '')
                                        }}
                                        clearIconTooltipText={t('Clear delivery time')}
                                    />


                                    <CommonTimePicker
                                        label={t('Range')}
                                        name={'end_time'}
                                        init_time={not_planned_stops[index]?.end_time?.slice(0, 5)}
                                        showExtendedTimeUi={false}
                                        onChange={(start_time, end_time) => {
                                            updateStopInfo(index, 'end_time', start_time)
                                        }}
                                        showClear={true}
                                        handleClear={() => {
                                            updateStopInfo(index, 'end_time', '')
                                        }}
                                        clearIconTooltipText={t('Clear delivery time')}
                                    />

                                    <div className='flex mb-3 mt-[-16px]'>
                                        <div className='relative w-full'>
                                            <CommonInput
                                                max_input={5}
                                                type='number'
                                                labelText={t('Duration (min)')}
                                                name={'duration'}
                                                value={not_planned_stops[index]?.duration}
                                                onChange={(e) => {
                                                    updateStopInfo(index, 'duration', e.target.value)
                                                }}
                                            />

                                            <div className='absolute right-0 flex space-x-2 bottom-1'>
                                                {
                                                    not_planned_stops[index]?.duration ?
                                                        <Tooltip title={t('Clear Duration')} color={"#242826"} >
                                                            <img onClick={() => {
                                                                updateStopInfo(index, 'duration', "")
                                                            }} className='w-5 h-5 cp' src={iGrayCancel} alt="" />
                                                        </Tooltip> :
                                                        <></>
                                                }
                                                {/* <img className='w-5 h-5' src={iClockGray} alt="" /> */}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>

                            {/* list of products */}
                            {stop?.products?.length > 0 ? stop?.products?.map((product, index) => (

                                <CommonViewComponent key={index} labelText={t('Product')} value={product?.text ?? "NA"} />
                            ))
                                : ""
                            }

                            <CommonViewComponent
                                labelText={t('Comment')}
                                value={stop?.comment ?? "NA"}
                            />

                            {stop?.attachment ?
                                <ImageViewer src={stop?.attachment} label={t('Attachment')} withExtension={true}/>
                                : ""
                            }
                        </div>

                    </div>
                ))
                    : ""
            }
        </div>
    );
};

export default Details;