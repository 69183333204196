/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useTranslation } from "react-i18next";
import CommonTitle from "../../../../components/title/CommonTitle";
import useGeneralStore from "../../../../app/stores/others/generalStore";
import useCarStore, {
    updateOrApplyForLicense,
} from "../../../../app/stores/company/carStore";
import CommonButtonOutlined from "../../../../components/button/CommonButtonOutlined";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../components/button/CommonButton";
import BackLink from "../../../../components/backLink/BackLink";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toastr } from "../../../../app/utility/utilityFunctions";
import { car_manager_path, k_license_status } from "../../../../app/utility/const";

const CarLicensePackageTopBar = ({
    handleAddCar,
    setTermsAccepted,
    termsAccepted,
}) => {
    const { t } = useTranslation();

    const {
        setCarDetails,
        carDetails,
        licenseAddUpdateForm,
        resetLicenseAddUpdateForm,
        isNoLicenseApply,
    } = useCarStore();

    const navigateTo = useNavigate();

    const handleUpdateLicense = async () => {
        if (
            (licenseAddUpdateForm?.license_start &&
                licenseAddUpdateForm?.license_id &&
                termsAccepted &&
                licenseAddUpdateForm?.purchase_license_comment) ||
            isNoLicenseApply
        ) {
            if (isNoLicenseApply) {
                const success = await handleAddCar();
                if (success?.success) {
                    resetLicenseAddUpdateForm();
                    return navigateTo(car_manager_path.list);
                }
            }

            if (!carDetails?.id) {
                const success = await handleAddCar();
                if (success?.success) {
                    let purchaseSuccess = false;
                    if (
                        success?.data?.id &&
                        licenseAddUpdateForm?.license_start &&
                        licenseAddUpdateForm?.license_id &&
                        termsAccepted
                    )
                        purchaseSuccess = await updateOrApplyForLicense(
                            {
                                ...licenseAddUpdateForm,
                                id: success?.data?.id,
                            },
                            carDetails?.license_id === null
                                ? false
                                : carDetails?.license_status === k_license_status?.expire_warning
                                    ? true
                                    : false
                        );
                    if (purchaseSuccess) {
                        setTermsAccepted(false);
                        resetLicenseAddUpdateForm();
                        navigateTo(car_manager_path.list);
                        setCarDetails({ id: null, license_status: "none" });
                    }
                }
            } else {
                let purchaseSuccess = false;
                if (
                    licenseAddUpdateForm?.license_start &&
                    licenseAddUpdateForm?.license_id &&
                    termsAccepted
                )
                    purchaseSuccess = await updateOrApplyForLicense(
                        licenseAddUpdateForm,
                        carDetails?.license_id === null
                            ? false
                            : carDetails?.license_status === k_license_status?.expire_warning
                                ? true
                                : false
                    );
                if (purchaseSuccess) {
                    setTermsAccepted(false);
                    resetLicenseAddUpdateForm();
                    navigateTo("/car-manager");
                    setCarDetails({ id: null, license_status: "none" });
                }
            }
        } else {
            Toastr({
                message: t("Please fill all the required fields."),
                type: "warning",
            });
        }


    };

    // const isDetailsPath = path_record?.old?.toLowerCase().includes('/car-manager/details/');
    // const isEditPath = path_record?.old?.toLowerCase().includes('/car-manager/edit/')
    // const isAddPath = path_record?.old?.toLowerCase().includes('/car-manager/add')
    const isAddLicensePath =
        window.location.pathname === car_manager_path.license;

    // const getLink = () => {
    //     if (isAddPath) {
    //         return "/car-manager/add"
    //     } else if (isDetailsPath) {
    //         return path_record?.old
    //     } else if (isEditPath) {
    //         return `/car-manager/details/${carDetails?.id}`
    //     } else {
    //         return "/car-manager"
    //     }
    // };

    const getButtonName = () => {
        if (window.location.pathname === car_manager_path.license) {
            return isNoLicenseApply ? t("Create") : t("Purchase & create");
        } else if (window.location.pathname === car_manager_path.license_edit) {
            return carDetails?.license_status === k_license_status?.expire_warning ||
                carDetails?.license_status === k_license_status?.expired ||
                carDetails?.license_status === k_license_status?.no_license ||
                carDetails?.license_status === k_license_status?.rejected
                ? t("Purchase")
                : t("Purchase & create");
        }
    };

    const location = useLocation();

    const { setPathRecord } = useGeneralStore();

    useEffect(() => {
        setPathRecord(location.pathname);
    }, [location.pathname]);

    return (
        <div>
            <div className="flex items-center justify-between">
                <CommonTitle
                    title={t("Choose License Package")}
                    link={"/car-manager"}
                    // withConfirm={true}
                    icon={true}
                />

                <div className="flex space-x-5">
                    <CommonButtonOutlined
                        colorType="danger"
                        btnLabel={t("Cancel")}
                        onClick={() => {
                            if (isAddLicensePath) {
                                navigateTo(car_manager_path.list);
                            } else {
                                navigateTo(car_manager_path.details + carDetails?.id);
                            }
                        }}
                        width="w-[120px]"
                    />

                    {!carDetails?.id && (
                        <CommonButtonOutlined
                            btnLabel={t("Previous")}
                            colorType="primary"
                            width="w-[100px]"
                            onClick={() => {
                                navigateTo(car_manager_path.add);
                            }}
                        />
                    )}

                    <CommonButton
                        onClick={handleUpdateLicense}
                        // colorType={(licenseAddUpdateForm?.license_start && licenseAddUpdateForm?.license_id && termsAccepted && licenseAddUpdateForm?.purchase_license_comment) || isNoLicenseApply ? "primary" : "disable"}
                        isDisabled={(licenseAddUpdateForm?.license_start && licenseAddUpdateForm?.license_id && termsAccepted && licenseAddUpdateForm?.purchase_license_comment) || isNoLicenseApply ? false : true}
                        type="submit"
                        // width={
                        //     isNoLicenseApply ||
                        //         carDetails?.license_status === k_license_status?.expire_warning ||
                        //         carDetails?.license_status === k_license_status?.expired ||
                        //         carDetails?.license_status === k_license_status?.no_license ||
                        //         carDetails?.license_status === k_license_status?.rejected
                        //         ? "w-[120px]"
                        //         : "w-[190px]"
                        // }
                        width="w-[160px]"
                        btnLabel={getButtonName()}
                    />
                </div>
            </div>

            <div className="mt-3 mb-4">
                {isAddLicensePath && (
                    <BackLink
                        linksArray={[
                            {
                                label: carDetails?.id ? t("Edit Car") : t("Add Car"),
                                linkTo: carDetails?.id ? "/car-manager/edit" : "/car-manager/add",
                                cursorPointer: true,
                                onClick: () => {
                                    // const result = getLink();
                                    // if (carDetails?.id) {
                                    //     setPreviousBackLink(`/car-manager/edit/${carDetails?.id}`)
                                    //     setPreviousBackConfirmModal(true);
                                    // } else {
                                    //     setPreviousBackLink(result)
                                    //     setPreviousBackConfirmModal(true);
                                    // }
                                },
                            },
                            { label: t("Choose License"), isActive: true },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};

export default CarLicensePackageTopBar;
