/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useState } from 'react';
import useFavoriteAddressStore, { editFavoriteAddress, getFavoriteAddressDetails } from '../../../../app/stores/others/favoriteAddressStore';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';
import CommonInput from '../../../../components/input/CommonInput';
import AddressAutoComplete from '../../../../components/input/AddressAutoComplete';
import CommonTitle from '../../../../components/title/CommonTitle';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import { Toastr } from '../../../../app/utility/utilityFunctions';
import { k_fav_address_paths } from '../../../../app/utility/AllRoute';

const EditFavoriteAddress = () => {

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const { fav_address_id } = useParams();

    const { setShowAddFavoriteAddressModal, setEditFavoriteAddress_form, favoriteAddressDetails, editFavoriteAddress_form } = useFavoriteAddressStore();

    const [doSearch, setDoSearch] = useState(false);
    const [addAddressLabel, setAddAddressLabel] = useState("");
    const [lat, setLat] = useState("");


    const handleFavoriteAddress = async (e) => {
        e.preventDefault();
        if (editFavoriteAddress_form?.title &&
            editFavoriteAddress_form?.lng && lat
            && addAddressLabel) {


            await setEditFavoriteAddress_form({ ...editFavoriteAddress_form, address: addAddressLabel, lat: lat });
            const body = {
                fav_id: editFavoriteAddress_form?.fav_id,
                title: editFavoriteAddress_form?.title,
                address: addAddressLabel,
                floor_number: editFavoriteAddress_form?.floor_number ?? "",
                note: editFavoriteAddress_form?.note,
                lat: lat,
                lng: editFavoriteAddress_form?.lng,
            }

            const success = await editFavoriteAddress(body);
            if (success) {
                setShowAddFavoriteAddressModal(false)
                setEditFavoriteAddress_form({
                    title: "",
                    address: "",
                    note: "",
                    lng: "",
                    lat: "",
                    floor_number: ""
                })
                setAddAddressLabel("");
                setLat("");
                navigateTo(k_fav_address_paths.details + `${fav_address_id}`)
            }
        } else {
            Toastr({ message: t("Please fill up all required fields"), type: "warning" })
            return;
        }
    }



    const handleChange = async (name, value) => {
        if (name === "address") {
            await setAddAddressLabel(value);
        } else if (name === "lat") {
            await setLat(value)
        } else if (name === "lng") {
            await setEditFavoriteAddress_form({ ...editFavoriteAddress_form, lng: value });
        }
    };

    useEffect(() => {
        setUpdateData();
    }, []);


    const setCarFormData = (data) => {
        setEditFavoriteAddress_form({
            fav_id: data?.id,
            title: data?.title,
            address: data?.address,
            note: data?.note ? data?.note : "",
            floor_number: data?.floor_number ?? "",
            lng: data?.lng,
            lat: data?.lat,
        });
        setAddAddressLabel(data?.address);
        setLat(data?.lat);
    };


    const setUpdateData = async () => {
        if (!editFavoriteAddress_form?.title) {
            const success = await getFavoriteAddressDetails(fav_address_id);
            setCarFormData(success);
        } else {
            setCarFormData(favoriteAddressDetails);
        }
    };

    return (
        <form className='flex space-x-20' onSubmit={handleFavoriteAddress}>

            <div className='w-full'>
                <CommonTitle
                    title={t("​Edit Favorite Address")}
                    icon={true}
                    link={k_fav_address_paths.list}
                />

                <div className='mb-s5'>
                    <CommonInput
                        min={'1'}
                        type='text'
                        labelText={t('Name')}
                        required={true}
                        value={editFavoriteAddress_form?.title}
                        name={'title'}
                        onChange={(e) => {
                            if (e.target.value !== " ") {
                                const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                setEditFavoriteAddress_form({ ...editFavoriteAddress_form, title: trimmedValue })
                            }
                        }} />
                </div>

                <div className='mb-s5'>
                    <AddressAutoComplete
                        required={true}
                        label={t("Address")}
                        placeholder={("Type Address..")}
                        name={"address"}
                        address={addAddressLabel}
                        latName={"lat"}
                        lngName={"lng"}
                        changeValue={handleChange}
                        doSearch={doSearch}
                        setDoSearch={setDoSearch}
                        icon={false}
                        isInvalid={addAddressLabel && (!lat || !editFavoriteAddress_form?.lng)}
                    />
                </div>

                <CommonInput
                    max_input={55}
                    type='text'
                    labelText={t('Floor Number')}
                    value={editFavoriteAddress_form?.floor_number}
                    name={'floor_number'}
                    onChange={(e) => {
                        setEditFavoriteAddress_form({ ...editFavoriteAddress_form, floor_number: e.target.value })
                    }}
                />

                <CommonInput
                    type='text'
                    textarea={true}
                    max_input={255}
                    value={editFavoriteAddress_form?.note}
                    name={'note'}
                    rows={3}
                    labelText={t('Note')}
                    onChange={(e) => { setEditFavoriteAddress_form({ ...editFavoriteAddress_form, note: e.target.value }) }}
                />
            </div>

            <div className='flex justify-end space-x-5'>
                <CommonButtonOutlined
                    onClick={() => { navigateTo(k_fav_address_paths.details + fav_address_id) }}
                    colorType='danger'
                    btnLabel={t('Cancel')}
                    width='w-[100px]'
                />

                <CommonButton
                    width='w-[100px]'
                    type='submit'
                    btnLabel={t("Update")}
                    isDisabled={!editFavoriteAddress_form?.title || !editFavoriteAddress_form?.lng || !lat || !addAddressLabel}
                />
            </div>
        </form>
    )
}

export default EditFavoriteAddress