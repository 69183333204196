/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect } from 'react';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';
import TrackTraceFilterModal from './modal/TrackTraceFilterModal';
import TrackTraceTopBar from './components/TrackTraceTopBar';
import TrackTraceTable from './components/TrackTraceTable';
import { isTableFiltered } from '../../../app/stores/others/requestStore';
import { k_requests_status } from '../../../app/utility/const';
import useTrackTraceStore from '../../../app/stores/others/trackTraceStore';
import { iTrackTraceNoData } from '../../../app/utility/imageImports';
import CommonEmptyData from '../../../components/emptyData/CommonEmptyData';


const TrackTrace = () => {

    const { t } = useTranslation();

    const { searchKey, copyFilterForm, trackTraceList } = useTrackTraceStore();

    useEffect(() => { changePageTitle(t('Limadi | Track & Trace')); }, []);

    const isFilterActive = isTableFiltered(k_requests_status.trackTrace, copyFilterForm);

    return (
        <div>
            <TrackTraceTopBar />

            {
                (isFilterActive || searchKey) &&
                    trackTraceList?.current_page ?
                    trackTraceList?.data?.length > 0 ?
                        <TrackTraceTable /> : <CommonEmptyData />
                    :
                    <div className='flex flex-col items-center justify-center h-[70vh]'>
                        <img className='flex items-center justify-center' src={iTrackTraceNoData} alt='' />
                        <div className='mt-5 font-bold text-center text-cGray600 font-fs24'>{t("Please SEARCH or FILTER to see the result.")}</div>
                    </div>
            }

            <TrackTraceFilterModal />
        </div>
    );
};

export default TrackTrace;