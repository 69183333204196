/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { Toastr } from '../../app/utility/utilityFunctions';

function TimePickerNew({
    init_time = null,
    init_date = null,
    size = "large",
    endTime = null,
    selectAction,
    showExtendedTimeUi = true,
    isCurrentTimeValidation = false,
    customMinTimeValue = null,
    isCustomMinTimeValidation = false
}) {

    const [hour, setHour] = useState('12')
    const [minute, setMinute] = useState('00')
    const [time, setTime] = useState(null)
    const [customMinHour, setCustomMinHour] = useState('12');
    const [customMinMinutes, setCustomMinMinutes] = useState('00');
    const [customMinDate, setCustomMinDate] = useState(new Date(init_date));
    const [updatedMinDateTime, setUpdatedMinDateTime] = useState(new Date(init_date));

    const [endHour, setEndHour] = useState('2')
    const [endMinute, setEndMinute] = useState('00')
    const [eTime, setETime] = useState(null) // end time

    // const [disabledHourDownBtn, setDisabledHourDownBtn] = useState(false);//
    // const [disabledHourUpBtn, setDisabledHourUpBtn] = useState(false);
    // const [disabledMinDownBtn, setDisabledMinDownBtn] = useState(false);//
    // const [disabledMinUpBtn, setDisabledMinUpBtn] = useState(false);


    // setup initial times
    useEffect(() => {
        let curr_hour = (new Date()).getHours();
        let curr_min = (new Date()).getMinutes();
        let curr_date = new Date(init_date);

        curr_min = Math.ceil(curr_min / 15) * 15;

        if (curr_min === 60) {
            curr_min = 0;
            curr_hour += 1;
        }

        if (curr_hour === 24) {
            curr_hour = 0;
        }

        if (curr_hour.toString().length < 2) curr_hour = '0' + curr_hour;
        if (curr_min.toString().length < 2) curr_min = '0' + curr_min;

        let curr_end_hour = curr_hour + 2;
        let curr_end_min = curr_min;

        if (isCustomMinTimeValidation && customMinTimeValue) {
            const time_array = customMinTimeValue?.split(":");
            curr_hour = time_array[0] || (new Date()).getHours()
            curr_min = time_array[1] || ((new Date()).getMinutes())

            curr_date.setHours(curr_hour);
            curr_date.setMinutes(curr_min);

            setCustomMinHour(curr_hour);
            setCustomMinMinutes(curr_min);
            setCustomMinDate(curr_date);
        } else if (init_time) {
            const time_array = init_time?.split(":");
            curr_hour = time_array[0] || (new Date()).getHours()
            curr_min = time_array[1] || ((new Date()).getMinutes())
        }

        if (endTime) {
            const end_time_array = endTime?.split(":");
            curr_end_hour = end_time_array[0] || (new Date()).getHours()
            curr_end_min = end_time_array[1] || (new Date()).getMinutes()
        }

        if (curr_hour.toString().length < 2) curr_hour = '0' + curr_hour
        if (curr_min.toString().length < 2) curr_min = '0' + curr_min

        if (curr_end_hour.toString().length < 2) curr_end_hour = '0' + curr_end_hour
        if (curr_end_min.toString().length < 2) curr_end_min = '0' + curr_end_min

        console.log("TIME:::: initial setup",
            {
                curr_hour,
                curr_min,
                curr_end_hour,
                curr_end_min
            }
        );


        setHour(curr_hour)
        setMinute(curr_min)

        setEndHour(curr_end_hour)
        setEndMinute(curr_end_min)
    }, [])

    // if hour or minute value changes update time
    useEffect(() => {
        setTime(`${hour}:${minute}`)
    }, [hour, minute])

    // if hour or minute value changes update end time
    useEffect(() => {
        setETime(`${endHour}:${endMinute}`)
    }, [endHour, endMinute])

    //it time or end time change, update time-picker data
    useEffect(() => {
        if (selectAction) selectAction(time, eTime)
    }, [time, eTime])

    // Get current hour
    // const now = new Date();
    // const currentHour = now.getHours();

    const hourChange = (action) => {
        let newHour = parseInt(hour);
        newHour = action === 'up' ? (newHour + 1) % 24 : (newHour - 1 + 24) % 24;

        // Get current hour for validation
        const now = new Date();
        const currentHour = now.getHours();

        // Prevent selecting time earlier than current time
        if (isCurrentTimeValidation && newHour < currentHour) {
            Toastr({
                message: "Time cannot be less than current time",
                type: "warning"
            });
            return;
        }

        if (isCustomMinTimeValidation) {
            let newDateTime = new Date(updatedMinDateTime);
            newDateTime.setHours(newHour);

            if (newDateTime < customMinDate) {
                Toastr({ message: `Time can not be less than ${customMinTimeValue}`, type: "warning" });
                return;
            }
        }

        const formattedHour = newHour.toString().padStart(2, '0');
        setHour(formattedHour);

        let updatedDateTime = new Date(updatedMinDateTime);
        updatedDateTime.setHours(newHour);
        setUpdatedMinDateTime(updatedDateTime);
    };

    const minuteChange = (action) => {
        let newMinute = parseInt(minute);
        newMinute = action === 'up' ? newMinute + 15 : newMinute - 15;

        let hourAdjustment = 0;
        if (newMinute >= 60) {
            hourAdjustment = 1;
            newMinute -= 60;
        } else if (newMinute < 0) {
            hourAdjustment = -1;
            newMinute += 60;
        }

        let newHour = (parseInt(hour) + hourAdjustment + 24) % 24;

        if (isCustomMinTimeValidation) {
            let newDateTime = new Date(updatedMinDateTime);
            newDateTime.setHours(newHour, newMinute);

            if (newDateTime < customMinDate) {
                Toastr({ message: `Time can not be less than ${customMinTimeValue}`, type: "warning" });
                return;
            }
        }

        const formattedMinute = newMinute.toString().padStart(2, '0');
        const formattedHour = newHour.toString().padStart(2, '0');

        setMinute(formattedMinute);
        setHour(formattedHour);

        let updatedDateTime = new Date(updatedMinDateTime);
        updatedDateTime.setHours(newHour, newMinute);
        setUpdatedMinDateTime(updatedDateTime);
    };


    // update end time from plus or minus button action
    useEffect(() => {
        let tempEndHour = parseInt(hour) + 2;
        if (tempEndHour > 23) tempEndHour = tempEndHour - 24
        if (tempEndHour.toString().length < 2) tempEndHour = '0' + tempEndHour
        setEndHour(tempEndHour)
        setEndMinute(minute)
        setETime(`${tempEndHour}:${minute}`)
    }, [hour, minute])


    const endTimeChange = (action) => {
        let tempEndHour = parseInt(endHour);
        let tempEndMinute = parseInt(endMinute);

        if (action === 'plus') {
            tempEndMinute += 15;
            if (tempEndMinute >= 60) {
                tempEndMinute = tempEndMinute - 60
                tempEndHour += 1
                if (tempEndHour > 23) tempEndHour = 0
            }
        } else {
            tempEndMinute -= 15;
            if (tempEndMinute < 0) {
                tempEndMinute = tempEndMinute + 60
                tempEndHour -= 1
                if (tempEndHour < 0) tempEndHour = 23
            }
        }

        let startTimeInMin = (parseInt(hour.toString()) * 60) + (parseInt(minute.toString()))
        let enTimeInMin = (tempEndHour * 60) + tempEndMinute

        if (startTimeInMin === enTimeInMin) return



        if (tempEndHour.toString().length < 2) tempEndHour = '0' + tempEndHour
        if (tempEndMinute.toString().length < 2) tempEndMinute = '0' + tempEndMinute

        setEndHour(tempEndHour)
        setEndMinute(tempEndMinute)
        setETime(`${tempEndHour}:${tempEndMinute}`)
    }

    return (
        <div className='inline-block px-2 text-gray-700 bg-white rounded-lg full-shadow-around outline-none'>
            <div className='flex items-center justify-between outline-none'>
                <div className={`flex flex-col items-center justify-between px-1 text-4xl rounded-lg select-none cursor-n-resize`}>
                    <IoIosArrowUp className='p-2 m-1 cursor-pointer hover:bg-cListItem rounded-br5' onClick={(e) => hourChange('up')} />
                    <div>{hour}</div>
                    <IoIosArrowDown className={`p-2 m-1 cursor-pointer hover:bg-cListItem rounded-br5`} onClick={(e) => hourChange('down')} />
                </div>
                <div className='px-2 text-4xl'>:</div>
                <div className='flex flex-col items-center justify-between px-1 text-4xl rounded-lg select-none cursor-n-resize'>
                    <IoIosArrowUp className='p-2 m-1 cursor-pointer hover:bg-cListItem rounded-br5' onClick={(e) => minuteChange('up')} />
                    <div>{minute}</div>
                    <IoIosArrowDown className={`p-2 m-1 cursor-pointer hover:bg-cListItem rounded-br5`} onClick={(e) => minuteChange('down')} />
                </div>
            </div>

            {showExtendedTimeUi ? <div className='flex items-center justify-between mx-0 mb-2 text-2xl bg-gray-200 select-none rounded-br5'>
                <AiOutlineMinus onClick={() => endTimeChange('minus')} className='p-2 text-3xl cursor-pointer bg-cListItem rounded-br5' />
                <div>{eTime}</div>
                <AiOutlinePlus onClick={() => endTimeChange('plus')} className='p-2 text-3xl cursor-pointer bg-cListItem rounded-br5' />
            </div> : ""}

        </div>
    )
}

export default TimePickerNew
