/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Clamp from 'react-multiline-clamp';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import useGeneralStore from '../../../../../app/stores/others/generalStore';
import useLayoutStore from '../../../../../app/stores/others/layoutStore';
import useRequestStore, { getTableViewRequestsData, handleRequestOrder, isTableFiltered } from '../../../../../app/stores/others/requestStore';
import { kuGetRequestsNew } from '../../../../../app/urls/commonUrl';
import { common_take, k_orderBy, k_request_paths, k_requests_status, user_role as role } from '../../../../../app/utility/const';
import { formatDateTime } from '../../../../../app/utility/utilityFunctions';
import CommonTable from '../../../../../components/table/CommonTable';
import AwardedFilter from './components/AwardedFilter';

export default function Awarded({ tableTitleClassName, seeAllText = false }) {
  const { setSelectedShiftIndex, setVisitNotPlanedShift, awarded, search_key, setSearchKey, request_take, setRequestTake, setRequestApiUrl, setRequestsOrder, setIsAsc, request_filter_form, resetRequestFilterForm, resetFilterRange, clearFilterRange, resetRequestFilterFormCopy, is_asc, requests_order, request_filter_form_copy } = useRequestStore();
  const { path_record, user_role } = useGeneralStore();
  const { setSideBarSelectItemTitle } = useLayoutStore();

  const [showModal, setShowModal] = useState(false);
  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { t } = useTranslation();


  const headers = (user_role === role.customer) ? [
    { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.awarded_company, sort: is_asc, name: t("Company"), onClickAction: () => handleRequestOrder(k_orderBy.awarded_company, getData), showSortImage: true },
    { isActive: requests_order === k_orderBy.title, sort: is_asc, name: t("Title"), onClickAction: () => handleRequestOrder(k_orderBy.title, getData), showSortImage: true},
    { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true},
    { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true},
    { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: t("Date & Time"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true},
    { isActive: requests_order === k_orderBy.awarded_budget, sort: is_asc, name: t("Budget"), onClickAction: () => handleRequestOrder(k_orderBy.awarded_budget, getData), showSortImage: true },
  ] :
    [
      { isActive: requests_order === k_orderBy.request_id, sort: is_asc, name: t("Req. ID"), onClickAction: () => handleRequestOrder(k_orderBy.request_id, getData), showSortImage: true },
      { isActive: requests_order === k_orderBy.customer_from, sort: is_asc, name: t("Customer"), onClickAction: () => handleRequestOrder(k_orderBy.customer_from, getData), showSortImage: true},
      { isActive: requests_order === k_orderBy.driver_name, sort: is_asc, name: t("Driver Name"), onClickAction: () => handleRequestOrder(k_orderBy.driver_name, getData), showSortImage: true},
      { isActive: requests_order === k_orderBy.transport_type, sort: is_asc, name: t("Type"), onClickAction: () => handleRequestOrder(k_orderBy.transport_type, getData), showSortImage: true },
      { isActive: requests_order === k_orderBy.stops_count, sort: is_asc, name: t("Load"), onClickAction: () => handleRequestOrder(k_orderBy.stops_count, getData), showSortImage: true},
      { isActive: requests_order === k_orderBy.pickup_starts_at, sort: is_asc, name: t("Schedule"), onClickAction: () => handleRequestOrder(k_orderBy.pickup_starts_at, getData), showSortImage: true},
    ];

  const getData = () => getTableViewRequestsData({
    data_set: k_requests_status.awarded, filter: {
      is_planned: user_role === role.company ? 1 : null,
      transport_type: request_filter_form?.transport_type,
      pickup_date_from: request_filter_form?.pickup_date_from,
      pickup_date_to: request_filter_form?.pickup_date_to,
      min_budget: request_filter_form?.min_budget,
      max_budget: request_filter_form?.max_budget,
      awarded_company: user_role === role.customer ? request_filter_form?.awarded_company : null,
      shift_driver: user_role === role.company ? request_filter_form?.shift_driver : null,
    }
  });

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey('');
    await setRequestApiUrl(kuGetRequestsNew);
    if (window.location.pathname === "/") {
      if (request_take !== 10) await setRequestTake(10);
    } else {
      if (request_take !== common_take) await setRequestTake(common_take);
    }
    await setRequestsOrder(k_orderBy.pickup_starts_at);
    await setIsAsc(1);
    await resetRequestFilterForm();
    await resetFilterRange();
    await clearFilterRange();
    await resetRequestFilterFormCopy();
    getTableViewRequestsData({ data_set: k_requests_status.awarded, filter: { is_planned: user_role === role.company ? 1 : null } });
  }

  const handleInitialSetup = async () => {
    window.scrollTo(0, 0);
    if (window?.location?.pathname === "/" || !path_record?.old?.includes(k_request_paths.awarded + '/details/') || !path_record?.old?.includes('/')) {
      resetTable();
    } else {
      getData();
    }
  }

  useEffect(() => { handleInitialSetup() }, []);

  useEffect(() => { handleInitialSetup() }, [window?.location?.pathname === "/"]);
  useEffect(() => {
    console.log("window?.location?.pathname : ", window?.location?.pathname);
    window.scrollTo(0, 0);
  }, [window?.location?.pathname]);

  useEffect(() => { getData() }, [searchValue])

  const contents = awarded?.data?.map(item =>  {
    return user_role===role.customer ? {
      contents: [
        {value: item?.request_id},
        {value: <Clamp lines={1}> {item?.awarded_company ?? ''}</Clamp>},
        {value: <Clamp lines={1}> {item?.title ?? ''}</Clamp>},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>{"1 | " + (item?.stops_count ?? 0) + ' | ' + (item?.products_count ?? 0)}</Tooltip>},
        {value: formatDateTime(item?.pickup_starts_date, item?.pickup_starts_time)},
        {value: `DKK ${item?.awarded_budget?.toLocaleString("da-DK") ?? '0'}`}
      ],
      clickAction: () => {
        setVisitNotPlanedShift(false)
        setSideBarSelectItemTitle(k_request_paths.awarded + `/details/${item?.id}`)
        navigateTo(k_request_paths.awarded + `/details/${item?.id}`)
      }
    }: {
      contents: [
        {value: item?.request_id},
        {value: <Clamp lines={1}> {item?.creator?.user_role === role.company ? t("Created by company") : item?.customer_from ?? ''}</Clamp>},
        {value: <Clamp lines={1}>{item?.driver_name ?? ''}</Clamp>},
        {value: item?.transport_type},
        {value: <Tooltip title={"Request | Stops | Packages"}>{"1 | " + (item?.stops_count ?? 0) + ' | ' + (item?.products_count ?? 0)}</Tooltip>},
        {value: formatDateTime(item?.pickup_starts_date, item?.pickup_starts_time)},
      ],
      clickAction: () => {
        setVisitNotPlanedShift(false)
        setSelectedShiftIndex(null)
        setSideBarSelectItemTitle(k_request_paths.awarded + `/details/${item?.id}`)
        navigateTo(k_request_paths.awarded + `/details/${item?.id}`)
      }
    }
  })

  return (
    <>
      <CommonTable
        tableTitle={t('Upcoming') + (window?.location?.pathname !== "/" ? ` (${awarded?.total ?? 0})` : '')}
        showSearchBox={!seeAllText}
        showTopRightFilter={!seeAllText}
        firstIndexColWidth='flex justify-start !w-max'
        showTakeOption={!seeAllText}
        showPagination={!seeAllText}
        showPageCountText={!seeAllText}
        headers={headers}
        outerPadding='p-s0'
        topRightFilterComponentOnClick={() => setShowModal(true)}
        tableTitleClassName={tableTitleClassName}
        paginationObject={awarded}
        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths={user_role === role.customer ? "90px 18% auto 15% 120px 15% 10%": "90px auto 25% 18% 120px 12%"}

        withClearSearch={true}
        onSearchClear={() => { setSearchKey("") }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setRequestApiUrl(kuGetRequestsNew);
          setSearchKey(e.target.value);
        }}

        seeAllText={seeAllText && awarded?.total ? t("See all") : null}
        seeAllLink={k_request_paths.awarded}
        seeAllOnClick={() => {
          setSideBarSelectItemTitle(k_request_paths.awarded)
          resetTable();
        }}

        currentTakeAmount={request_take}
        withReloader={true}
        onReload={resetTable}
        filtered={isTableFiltered(k_requests_status.awarded, request_filter_form_copy)}
        takeOptionOnChange={async (e) => {
          await setRequestTake(e.target.value);
          await setRequestApiUrl(kuGetRequestsNew);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setRequestApiUrl(url);
          getData();
        }}
      />

      <AwardedFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
