import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRequestStore, {
  deleteInBiddingRequest,
  getRequestDetails,
} from "../../../../../../../../app/stores/others/requestStore";
import CommonButton from "../../../../../../../../components/button/CommonButton";
import CommonInput from "../../../../../../../../components/input/CommonInput";
import CommonModal from "../../../../../../../../components/modal/CommonModal";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Toastr } from "../../../../../../../../app/utility/utilityFunctions";

export default function CancelInBiddingRequest({
  submitButtonText,
  title,
  showModal,
  setShowModal,
}) {

  const { type, request_id } = useParams();
  const navigateTo = useNavigate();
  const [comment, setComment] = React.useState("");
  const { request_details } = useRequestStore();
  const { t } = useTranslation();

  const submitForm = async (e) => {
    e.preventDefault();
    // return console.log("submitForm: ", request_details?.my_bid?.id, comment);

    if (!comment.trim()) {
      // Show a toast message and return from the function
      Toastr({ message: t('Please enter a comment.'), type: "warning" });
      return;
    }
    const success = await deleteInBiddingRequest(request_details?.my_bid?.id, comment);
    if (success) {
      setShowModal(false);
      setComment("");
      navigateTo(`/requests/${type}`);
    }
  };

  useEffect(() => {
    getRequestDetails(type, request_id);

    setComment("");
  }, [showModal])

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={title}
      widthClass="w-[50vw]"
      mainContent={
        <>
          <div className="mt-s20 pb-s10">
            {t("Are you sure you want to cancel the bid?")}
          </div>
          <form onSubmit={submitForm}>
            <CommonInput
              className={"my-3"}
              name={"comment"}
              value={comment}
              onChange={(e) => {
                if (e.target.value !== " ") {
                  const trimmedValue = e.target.value.replace(/\s+/g, " ");
                  setComment(trimmedValue)
                }
              }}
              labelText={t("Reason")}
              textarea={true}
              type={"text"}
              max_input={255}
              required={true}
              show_asterisk={false}
            />
            <div className="flex justify-end mt-s30">
              <CommonButton
                btnLabel={submitButtonText}
                colorType="danger"
                width="w-[100px]"
                type="submit"
              />
            </div>
          </form>
        </>
      }
    />
  );
}
