import { FormControlLabel, Radio } from "@mui/material";
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Clamp from "react-multiline-clamp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useShiftStore, {
    calculateShiftOverView,
    defineScheduleInfo,
} from "../../../../../../../app/stores/company/shiftStore";
import useCreateRequestStore, { saveRequest } from "../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import useRequestStore, {
    isTableFiltered,
} from "../../../../../../../app/stores/others/requestStore";
import { k_create_request_paths } from "../../../../../../../app/utility/AllRoute";
import {
    create_request_steps,
    k_cr_actions,
    k_requests_status,
} from "../../../../../../../app/utility/const";
import {
    iFilterGreen,
    iFilterWhite
} from "../../../../../../../app/utility/imageImports";
import CommonButton from "../../../../../../../components/button/CommonButton";
import CommonButtonOutlined from "../../../../../../../components/button/CommonButtonOutlined";
import CommonTable from "../../../../../../../components/table/CommonTable";
import AvailableShiftFilter from "./AvailableShiftFilter";
import CommonEmptyView from "./CommonEmptyView";

const AvailableShifts = ({ selectedId, onChange }) => {

    const { t } = useTranslation();
    const { user_role } = useGeneralStore();

    const {
        current_step,
        available_shift_search_key,
        setAvailableShiftSearchKey,
        setSavedCrFormRes,
        cr_form,
    } = useCreateRequestStore();
    const { available_shifts, selectedNewShiftId } = useRequestStore();
    const { setShowAddShiftModal, filter_form_copy, setAllShiftCarList, setAllShiftDriverList } = useShiftStore();

    const location = useLocation();
    const { request_id } = useParams();

    const navigateTo = useNavigate();

    const allShiftHeaders = [
        { name: "#", placement: "justify-center items-center" },
        { name: t("Drivers") },
        { name: t("License") },
        { name: t("Date & Time") },
        { name: t("Location") },
        { name: t("Load") },
    ];

    const headers = [
        { name: "#", placement: "justify-center items-center" },
        { name: t("Drivers") },
        { name: t("Schedule") },
        { name: t("Load") },
    ];

    function checkEditMode() {
        if (location?.pathname === `/request/create`) {
            return true;
        }
        else if (location?.state?.isEditMode === true) {
            return true;
        } else if (location.pathname?.includes(`/request/edit/${request_id}`)) {
            return true;
        } else if (location.pathname?.includes(`/details/${request_id ?? location?.state?.request_id}/edit`)) {
            return true;
        } else {
            return false;
        }
    }

    // const isAllShift = current_step === create_request_steps.allShifts;
    const isAllShift = location?.pathname?.includes("/request/create/all-shift");
    const isCreateRequestPage = location?.pathname?.includes("request/create")
    const isEditMode = checkEditMode();

    function reduceArrayWithSelected(arr, selectedId) {

        return arr.slice(0, 10);
        //todo: optimize later...

        // Find the selected item based on the selectedId
        const selectedItem = arr.find(item => item.id === selectedId);

        // Filter out the selected item from the array
        let filteredArray = arr.filter(item => item.id !== selectedId);

        // Slice the filtered array to get only 10 items
        filteredArray = filteredArray.slice(0, 10);

        // Add the selected item at the 10th (index 9) position
        if (selectedItem) {
            filteredArray.push(selectedItem);
        }

        return filteredArray;
    }

    const shiftList = !isAllShift
        ? reduceArrayWithSelected(Object.values(available_shifts), selectedId) //Object.values(available_shifts)?.slice(0, 10)
        : Object.values(available_shifts);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (selectedNewShiftId) {
            let list = !isAllShift ? shiftList : available_shifts;
            const selectedShift = list?.find(item => item.id === selectedNewShiftId);
            if (selectedShift?.id) {
                onChange(selectedShift)
            }
        }
    }, [selectedNewShiftId, available_shifts?.length])

    const tableHeader = location?.pathname?.includes("all-shift") ? location?.state?.isEditMode === false ? headers : allShiftHeaders : headers

    const contents = shiftList
        ?.slice()
        ?.reverse()
        ?.map((item, index) => {
            const handleRadioChange = async (e) => {
                e.stopPropagation();
                await onChange(item);
            };

            const shiftDetailsData = calculateShiftOverView(item);
            return tableHeader?.length === 6 ? {
                contents: [{
                    value: isEditMode ?
                        <div className="ml-s25">
                            <FormControlLabel
                                size="small"
                                checked={item?.id === selectedId}
                                value={true}
                                control={
                                    <Radio
                                        disableRipple
                                        style={{
                                            color: item?.id === selectedId ? "#60B436" : "#939D98",
                                        }}
                                    />
                                }
                                onClick={handleRadioChange}
                            />
                        </div>
                        : (index + 1)
                },
                {
                    value: <Clamp withTooltip lines={1}>
                        {item?.shift_drivers?.length > 0
                            ? item?.shift_drivers
                                ?.map((driver) =>
                                    driver?.is_main_driver
                                        ? driver?.driver?.name + " (M)"
                                        : driver?.driver?.name
                                )
                                .join(", ")
                            : item?.driver_user?.name + " (M)" ?? "NA"}
                    </Clamp>
                },
                { value: item?.car_number },
                {
                    value: defineScheduleInfo({
                        start_date: item?.shift_start_date,
                        end_date: item?.shift_end_date,
                        start_time: item?.shift_start_time,
                        end_time: item?.shift_end_time,
                    })
                },
                { value: item?.location },
                {
                    value: <Tooltip
                        title={t("Request | Stops | Packages")}
                        color={"#242826"}
                        placement="topRight"
                    >{`${shiftDetailsData?.request_count ?? 0} | ${shiftDetailsData?.stops_count ?? 0} | ${shiftDetailsData?.products_count ?? 0}`}
                    </Tooltip>
                },
                ],
                clickAction: async () => {
                    if (location?.pathname === k_create_request_paths.create) await saveRequest(k_cr_actions.save, false);
                    else setSavedCrFormRes(cr_form);

                    navigateTo(
                        k_create_request_paths.shift_details + item?.id,
                        {
                            state: {
                                ...location?.state,
                                request_id: location?.state?.request_id ?? item?.id,
                                referrer: location?.state?.referrer,
                                role: user_role,
                                isEditMode: isEditMode,
                                isAllShiftExists: isAllShift
                            }
                        }
                    );
                }
            } : {
                contents: [{
                    value: isEditMode ? <div className="ml-s25"><FormControlLabel
                        size="small"
                        checked={item?.id === selectedId}
                        value={true}
                        control={
                            <Radio
                                disableRipple
                                style={{
                                    color: item?.id === selectedId ? "#60B436" : "#939D98",
                                }}
                            />
                        }
                        onClick={handleRadioChange}
                    /></div>
                        : (index + 1)
                }, {
                    value: <Clamp withTooltip lines={1}>
                        {item?.shift_drivers?.length > 0
                            ? item?.shift_drivers
                                ?.map((driver) =>
                                    driver?.is_main_driver
                                        ? driver?.driver?.name + " (M)"
                                        : driver?.driver?.name
                                )
                                .join(", ")
                            : item?.driver_user?.name + " (M)" ?? "NA"}
                    </Clamp>
                }, {
                    value: defineScheduleInfo({
                        start_date: item?.shift_start_date,
                        end_date: item?.shift_end_date,
                        start_time: item?.shift_start_time,
                        end_time: item?.shift_end_time,
                    })
                }, {
                    value: <Tooltip
                        title={t("Request | Stops | Packages")}
                        color={"#242826"}
                        placement="topRight"
                    >{`${shiftDetailsData?.request_count ?? 0} | ${shiftDetailsData?.stops_count ?? 0} | ${shiftDetailsData?.products_count ?? 0}`}
                    </Tooltip>
                },
                ],
                clickAction: async () => {
                    if (location?.pathname === k_create_request_paths.create) await saveRequest(k_cr_actions.save, false);
                    else setSavedCrFormRes(cr_form);

                    navigateTo(
                        k_create_request_paths.shift_details + item?.id,
                        {
                            state: {
                                ...location?.state,
                                request_id: location?.state?.request_id ?? item?.id,
                                referrer: location?.state?.referrer,
                                role: user_role,
                                isEditMode: isEditMode,
                                isAllShiftExists: isAllShift
                            }
                        }
                    );
                }
            }
        })

    return (
        <div
            onClick={() => {
                console.log("select_shift>AVAILABLE SHIFT: ", Object.values(available_shifts));
                console.log("is edit mode: ", isEditMode);

            }}

        >
            {isAllShift ||
                (Object.values(available_shifts)?.length > 0) ? (
                <CommonTable
                    tableTitle={t("")}
                    tableTitleClassName={"sub-title"}
                    showSearchBox={isAllShift}
                    searchBoxHeight={"h-9"}
                    firstIndexColWidth="w-[30px]"
                    sort={false}
                    searchFilterTopSpacing="space-x-4"
                    tableHeaderToPaddingClassName={isAllShift ? "mb-s18" : "mb-s7"}
                    showTopRightFilter={isAllShift}
                    showTakeOption={false}
                    showPagination={false}
                    showPageCountText={true}
                    outerPadding="p-s0"
                    topRightFilterComponentOnClick={() => { }}
                    paginationObject={{}}
                    withClearSearch={true}
                    onSearchClear={() => {
                        setAvailableShiftSearchKey("");
                    }}
                    tableHeaders={location?.pathname?.includes("all-shift") ? location?.state?.isEditMode === false ? headers : allShiftHeaders : headers}
                    tableContents={contents}
                    hasClickFunction={true}
                    widths={
                        location?.pathname?.includes("all-shift") ? location?.state?.isEditMode === false ? "50px auto 20% 120px" :
                            "50px auto 10% 20% 15% 120px"
                            : "50px auto 35% 120px"
                    }
                    searchValue={available_shift_search_key}
                    searchOnChange={(e) => {
                        setAvailableShiftSearchKey(e.target.value);
                    }}
                    currentTakeAmount={10}
                    withReloader={true}
                    onReload={() => { }}
                    emptyDataMessage={t("No shifts available!")}
                    topRightMainComponent={
                        <>
                            {isAllShift ? (
                                <CommonButton
                                    btnLabel={t("Add Shift")}
                                    width="w-[130px]"
                                    className="h-9"
                                    type="button"
                                    onClick={() => {
                                        setShowAddShiftModal(true);
                                        setAllShiftDriverList([]);
                                        setAllShiftCarList([]);
                                    }}
                                />
                            ) : null}
                        </>
                    }
                    topRightFilterComponent={
                        <div className="relative">
                            {isTableFiltered(k_requests_status?.shift, filter_form_copy) && (
                                <div className="absolute z-50 w-2 h-2 rounded-full bg-cBrand right-1 top-1"></div>
                            )}
                            <CommonButtonOutlined
                                height="h-9"
                                btnLabel={t("Filter")}
                                onClick={() => setShowModal(true)}
                                colorType="primary"
                                iconLeft={iFilterWhite}
                                iconLeftHover={iFilterGreen}
                            />
                        </div>
                    }
                    headersFirstIndexFixApply={false}
                    takeOptionOnChange={async (e) => { }}
                    paginationOnClick={async (url) => { }}
                />
            ) : (
                <CommonEmptyView message={t("No shifts available!")} />
            )}

            <AvailableShiftFilter showModal={showModal} setShowModal={setShowModal} />
        </div>
    );
};

export default AvailableShifts;