/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useTranslation } from 'react-i18next';
import CommonInput from '../../../../../../components/input/CommonInput';
import useCreateRequestStore, { isCrFormValidation } from '../../../../../../app/stores/others/createRequestStore';
import RequestAccordion from '../../../../../../components/Accordion/RequestAccordion';
import { create_request_steps } from '../../../../../../app/utility/const';

function ProposalInfo() {

    const { t } = useTranslation();

    const { cr_form, isApplyGenerate, updateCrField, expandedAccordion, setExpandedAccordion } = useCreateRequestStore();

    const isFormFilled = [
        cr_form?.proposal?.budget,
        cr_form?.proposal?.customer_name,
        cr_form?.proposal?.details
    ].some(Boolean);


    return (
        <RequestAccordion
            isInvalid={isApplyGenerate && !isCrFormValidation()?.proposal}
            title={t("Proposal")}
            showClearButton={isFormFilled}
            clearButtonTooltipMessage='Clear'
            clearHandler={() => {
                updateCrField("proposal.budget", '');
                updateCrField("proposal.customer_name", '');
                updateCrField("proposal.details", '');
            }}
            isForm={true}
            id="accordion4"
            isExpanded={expandedAccordion === create_request_steps.proposal}
            onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.proposal ? null : create_request_steps.proposal) }}

            subTitleOne={cr_form?.proposal?.budget ? "DKK " + parseInt(cr_form?.proposal?.budget)?.toLocaleString("da-DK") : ""}
            subTitleTwo={cr_form?.proposal?.customer_name}
        >
            <div>
                <div className='grid grid-cols-2 gap-x-6'>
                    <CommonInput
                        isInvalid={isApplyGenerate && !cr_form?.proposal?.budget}
                        show_asterisk={true}
                        required={true}
                        min_input={0}
                        max_input={8}
                        labelText={t('Budget')}
                        type='number'
                        name={'budget'}
                        value={cr_form?.proposal?.budget ?? ""}
                        onChange={(e) => {
                            const newValue = e.target.value.replace(/[^0-9]/g, '');
                            updateCrField("proposal.budget", newValue);
                        }}
                    />

                    <CommonInput
                        show_asterisk={false}
                        required={true}
                        labelText={t('Customer')}
                        max_input={55}
                        type='text'
                        name={'customer'}
                        value={cr_form?.proposal?.customer_name ?? ""}
                        onChange={(e) => {
                            if (e.target.value !== " ") {
                                const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                updateCrField("proposal.customer_name", trimmedValue)
                            }
                        }}
                    />

                </div>

                <CommonInput
                    isInvalid={isApplyGenerate && !cr_form?.proposal?.details}
                    max_input={255}
                    show_asterisk={true}
                    required={true}
                    textareaWithoutBorderBottom={false}
                    value={cr_form?.proposal?.details ?? ""}
                    className=''
                    rows={3}
                    labelText={t('Bidding Description')}
                    textarea={true} name={'bid_details'}
                    onChange={(e) => {
                        if (e.target.value !== " ") {
                            const trimmedValue = e.target.value.replace(/\s+/g, " ");
                            updateCrField("proposal.details", trimmedValue)
                        }
                    }}
                />
            </div>
        </RequestAccordion>
    )
}

export default ProposalInfo
