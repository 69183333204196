import React from 'react';
import CommonModal from '../modal/CommonModal';
import { useTranslation } from 'react-i18next';
import { base_url_src, k_file_type } from '../../app/utility/const';


const getFileExtension = (url) => url?.split('.')?.pop()?.toLowerCase();
const getFileType = (url) => url?.split('/')[1]?.split(';')[0];
const isImage = (fileExtension) => [k_file_type.jpg, k_file_type.jpeg, k_file_type.png].includes(fileExtension);
const isPdf = (fileExtension) => ["pdf"].includes(fileExtension);


const ImageUploadViewModal2 = ({ src, show_modal, setShowModal, url, fullView = false, apiImage = null }) => {
  const { t } = useTranslation();
  const fileExtension = getFileExtension(url);
  const fileType = getFileType(url);


  const isPdfFile = fileType === k_file_type.pdf;
  const isImageFile = fileType === k_file_type.jpg || fileType === k_file_type.jpeg || fileType === k_file_type.png;

  return (
    <CommonModal
      // customClose={iRedCancel}
      showModal={show_modal}
      setShowModal={setShowModal}
      modalTitle={fullView ? "" : t("Attachment Preview")}
      widthClass={isPdfFile ? "min-w-[1000px] max-w-[1000px]" : "min-w-[350px]"}
      heightMax='max-h-[600px]'
      withPaddingBody={!fullView}
      mainContent={
        <div className={`${!fullView && "mt-s20"}`}>
          <div className='h-[440px] max-h-[440px] max-w-[600px]'>
          {
            apiImage ? <img src={apiImage} alt="" className="object-cover h-full w-full rounded-md" />
              :
              <>
                {isImage(fileExtension) && <img src={src} alt="" className="object-cover h-full w-full rounded-sm" />}
                {isPdf(fileExtension) &&
                  <div className='truncate border-2 border-dashed border-cBrand p-s10 rounded-br5'>
                    <a
                      href={base_url_src + url}
                      download="Attachment.pdf"
                      target="_blank"
                      rel="noreferrer"
                      alt=""
                      className="text-cBrand"
                    >Attachment.pdf </a>
                  </div>
                }
                {
                  isPdfFile &&
                  <div>
                    <embed
                      src={url}
                      type="application/pdf"
                      width="100%"
                      height="530px"
                    />
                  </div>
                }
                {
                  isImageFile &&
                  <img src={src} alt="" className="object-cover h-full w-full rounded-md" />
                }
              </>
          }
          </div>
        </div>
      }
    />
  );
};

export default ImageUploadViewModal2;

