/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCommonHomeStore from '../../../../app/stores/others/commonHomeStore';
import { iBlackCross, iRightArrowVector, iWhiteAdd } from '../../../../app/utility/imageImports';
import CommonHomePageCard from '../../../../components/card/CommonHomePageCard';
import { create_request_path, k_home_card_types } from '../../../../app/utility/const';
import { useTranslation } from 'react-i18next';
import { k_create_request_paths } from '../../../../app/utility/AllRoute';


const TopHeader = () => {

    const { homePageData, selectedCard, setSelectedCard } = useCommonHomeStore();

    const [topBarData, setTopBarData] = useState({ title: '', link: "", });

    const [request, setRequest] = useState(true);

    const navigateTo = useNavigate();

    const { t } = useTranslation();

    const handleBarClick = (linkFlow = true) => {
        if (linkFlow)
            navigateTo(create_request_path);
        else
            setRequest(false);
    }

    useEffect(() => {
        if (!homePageData?.is_create_request)
            setTopBarData({ title: t('Create your first request'), link: k_create_request_paths.create });
        else
            setTopBarData({ title: '', link: '' });

    }, [homePageData]);

    return (
        <div className='mb-1'>

            {
                topBarData?.title && request ?
                    <div className='z-10 flex justify-between overflow-hidden cursor-pointer rounded-br8'>
                        <div onClick={() => {
                            handleBarClick(true);
                        }} className='flex items-center w-full pl-s15 py-s10 bg-cPrimary100'>
                            <span className='text-fs16 font-fw500 text-cMainBlack'>{t('Create your first request')}</span>
                            <img src={iRightArrowVector} alt="" className='w-s28 ml-s15' />
                        </div>
                        <img onClick={() => { handleBarClick(false) }} src={iBlackCross} alt="" className='z-50 px-3 bg-cPrimary100' />
                    </div>
                    : ""
            }

            {/* Home PageCard start */}

            <div className={`title ${topBarData?.title && request ? 'my-4' : 'mb-4'}`}>{t('Dashboard')}</div>

            <div className='items-center'>
                <div className='grid grid-cols-4 gap-x-4 md:gap-x-4 lg:gap-x-4 xl:gap-x-7 2xl:gap-x-12'>


                    <CommonHomePageCard
                        onClick={() => {
                            setSelectedCard(k_home_card_types.in_bidding);
                        }}
                        title={t("In Bidding")}
                        count={homePageData?.in_bidding_count ?? 0}
                        selected={selectedCard === k_home_card_types.in_bidding}
                    />



                    <CommonHomePageCard
                        onClick={() => {
                            setSelectedCard(k_home_card_types.awarded);
                        }}
                        title={t("upcoming")}
                        count={homePageData?.awarded_count ?? 0}
                        selected={selectedCard === k_home_card_types.awarded}
                    />



                    <CommonHomePageCard
                        onClick={() => {
                            setSelectedCard(k_home_card_types.ongoing);
                        }}
                        title={t("Ongoing")}
                        count={homePageData?.ongoing_count ?? 0}
                        selected={selectedCard === k_home_card_types.ongoing}
                    />



                    <div onClick={() => navigateTo(k_create_request_paths.create)}
                        className='flex justify-center h-full cursor-pointer bg-cBrand rounded-br5'>
                        <div className='flex flex-col items-center justify-center p-s18'>
                            <span className='text-cMainWhite text-fs16 text-fw500'>{t('Create Request')}</span>
                            <img src={iWhiteAdd} alt="" className='cursor-pointer w-s30 h-s30 mt-s12' />
                        </div>
                    </div>

                </div>
            </div>
            {/* Home PageCard end */}


        </div>
    );
};

export default TopHeader;