import React from 'react';
import { iFacebook, iLinkedIn, iTwitter } from '../../app/utility/imageImports';
import { useTranslation } from 'react-i18next';

const CommonSocialLinks = ({ item }) => {

    const { t } = useTranslation();

    const hasSocialMedia = item?.filter(socialItem => !!socialItem?.link)?.length;

    return (
        <div className=''>
                <div>
                    <span className='font-normal text-fs23 text-cGray800'>
                        {t('Social Links')}
                    </span>
                    <div className='flex flex-wrap mt-2 space-x-4'>
                        {hasSocialMedia ? 
                        item?.map((item) => (
                            <div key={item?.link}>
                                {item?.domain.includes("facebook") && renderSocialLink(item, iFacebook, "Facebook icon")}
                                {item?.domain.includes("twitter") && renderSocialLink(item, iTwitter, "Twitter icon")}
                                {item?.domain.includes("linkedin") && renderSocialLink(item, iLinkedIn, "LinkedIn icon")}
                            </div>
                        )) : 
                        <div className='p-5 mt-2 space-y-3 w-full rounded-sm text-fs14 text-cGray800 font-fw400 bg-cSecondary50'>
                            <p className='text-fs14'>No social link added</p> 
                    </div>}
                    </div>
                </div>
        </div>
    );
};

export default CommonSocialLinks;


const renderSocialLink = (item, icon, altText) => {
    if (item?.link !== "") {
        const link = item?.link?.includes("http") ? item?.link : "http://" + item?.link;
        return (
            <a target="blank" href={link}>
                <img className='max-w-[30px] min-w-[40px] cursor-pointer' src={icon} alt={altText} />
            </a>
        );
    }
    return null;
}