/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { getProfileDetails } from '../../../../../app/stores/others/settingsStore';
import { iAddressBlack, iCvrBlack, iDummyCompanyAvatar, iEmailBlack, iPhoneBlack, iWebsiteBlack } from '../../../../../app/utility/imageImports';
import { changePageTitle, handleNullValue } from '../../../../../app/utility/utilityFunctions';
import RatingFiveStar from '../../../../../components/rating/RatingFiveStar';
import CommonSocialLinks from '../../../../../components/socialLinks/CommonSocialLinks';
import { useTranslation } from 'react-i18next';
import useSettingsStore from '../../../../../app/stores/others/settingsStore';
import CommonButton from '../../../../../components/button/CommonButton';
import { useLocation, useNavigate } from "react-router-dom";
import CommonTitle from '../../../../../components/title/CommonTitle';
import DummyProfileImage from '../../../../../components/image/DummyProfileImage';
import { BUTTON_WIDTH, googleMapLinkPrefix, k_settings_paths } from '../../../../../app/utility/const';
import CommonButtonOutlined from '../../../../../components/button/CommonButtonOutlined';

const CompanyProfile = () => {

    const { profileDetails, termsData, setShowReviewModal } = useSettingsStore();

    const { t } = useTranslation();

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        fetchProfileDetails();
        changePageTitle(t('Settings | Profile'));
    }, [])

    const fetchProfileDetails = async () => {
        if (location.pathname === k_settings_paths.profile) {
            const success = await getProfileDetails();
            if (success) window.scrollTo(0, 0);
        }
    }

    return (

        <div>
            <div className='flex justify-between mb-4'>
                <CommonTitle title={t('Profile')} />

                <div className='flex gap-x-5'>
                    <CommonButtonOutlined
                        onClick={() => { navigate(k_settings_paths.delete_account); }}
                        btnLabel={t("Delete")}
                        colorType='danger'
                        width={BUTTON_WIDTH}
                    />
                    <CommonButton
                        // icon={iEditIcon}
                        onClick={() => { navigate(k_settings_paths.company_edit) }}
                        btnLabel={t('Edit')}
                        width={BUTTON_WIDTH}
                    />

                </div>
            </div>

            <div className='flex flex-col'>
                <DummyProfileImage src={profileDetails?.image} dummyImage={iDummyCompanyAvatar} />
            </div>

            <div className='flex justify-between mt-3 space-x-10'>
                <div className='w-[70%]'>
                    <div className='break-all'>
                        <div className='break-all settingBar text-cGray800 mb-s4'>
                            {profileDetails?.name}
                        </div>
                        <div onClick={() => setShowReviewModal(true)} className='cursor-pointer'>
                            <RatingFiveStar rating={profileDetails?.rate ? parseFloat(profileDetails?.rate?.toFixed(1)) : 0} />
                        </div>
                    </div>
                    <div>
                        <div className='my-5'>
                            <div className='mb-2 settingBar text-cGray800'>{t("About Company")}</div>
                            <p className='custom-text text-fs14 text-cGray800 font-fw400'>
                                {profileDetails?.about ? handleNullValue(profileDetails?.about) : t("Information about the company is not updated yet.")}
                            </p>
                        </div>

                        <div>
                            <div className='mb-2 settingBar text-cGray800'>{t("Company terms and conditions")}</div>
                            {termsData !== "<p><br></p>" ?
                                <div className='break-all text-fs14 text-cGray800 font-fw400'
                                    dangerouslySetInnerHTML={{ __html: termsData ? termsData : t('Terms and conditions is not updated yet.') }}
                                /> : t('Terms and conditions is not updated yet.')}
                        </div>
                    </div>
                </div>

                <div className='flex justify-end items-end max-w-[400px] min-w-[400px]'>
                    <div>
                        <span className='sub-title text-cGray800'>{t("Company Details")}</span>
                        <div className='p-5 mt-2 space-y-3 rounded-sm text-fs14 text-cGray800 font-fw400 bg-cSecondary50'>
                            {renderCompanyDetail(iCvrBlack, "CVR Icon", profileDetails?.cvr)}
                            {renderCompanyDetail(iEmailBlack, "Email Icon", profileDetails?.email)}
                            {renderCompanyDetail(iPhoneBlack, "Phone Icon", profileDetails?.phone)}
                            {renderCompanyDetail(iAddressBlack, "Address Icon", profileDetails?.address, true, googleMapLinkPrefix)}
                            {renderCompanyDetail(iWebsiteBlack, "Website Icon", profileDetails?.website, true, profileDetails?.website?.includes("http") ? '' : 'https://')}
                            {!profileDetails?.cvr && !profileDetails?.email && !profileDetails?.phone && !profileDetails?.address && !profileDetails?.website ?
                                (<p className='text-fs14'>No info added</p> )
                                : 
                                (<></>)
                            }
                        </div>
                        <div className='mt-s21'><CommonSocialLinks item={profileDetails?.social_media} /></div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CompanyProfile;





const renderCompanyDetail = (icon, altText, detail, link = false, linkPrefix = '') => {
    if (!detail) return '';
    const content = link ? (
        <a target='_blank'
            href={`${linkPrefix}${detail}`}
            rel='noreferrer'
            className='break-all cursor-pointer'
        >
            {detail}
        </a>
    ) : (<p className='break-all'>{detail}</p>);

    return (
        <div className='flex items-center'>
            <img className='text-fs14 mr-s8 w-s16 h-s16' src={icon} alt={(altText)} />
            {content}
        </div>
    );
}