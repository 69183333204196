import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useCreateRequestStore, {
  isCrFormValidation,
  isCrFormValidationWithAddress,
  saveRequest,
} from "../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../app/stores/others/generalStore";
import { k_create_request_paths } from "../../../../../../app/utility/AllRoute";
import {
  create_request_path,
  create_request_steps,
  k_cr_actions,
  k_request_paths,
  user_role as role
} from "../../../../../../app/utility/const";
import { Toastr } from "../../../../../../app/utility/utilityFunctions";
import CommonButton from "../../../../../../components/button/CommonButton";
import CommonButtonOutlined from "../../../../../../components/button/CommonButtonOutlined";
import RequestDeleteConfirmModal from "../../../../requests/requestDetails/components/requestDetailsTopBar/components/modals/RequestDeleteConfirmModal";

export default function Actions() {
  const {
    setCurrentSetup,
    setExpandedAccordion,
    setApplyGenerate,
    setShowRequestConfirmModal,
    current_step,
    cr_form,
    changeCrForm,
    setDeliveryInvalidIndex,
  } = useCreateRequestStore();
  const { user_role } = useGeneralStore();
  const { t } = useTranslation();

  const isCompany = user_role === role.company;

  const { shift_id, request_id } = useParams();

  const location = useLocation();

  const [showDeleteModal, setDeleteModal] = useState(false);

  const navigateTo = useNavigate();

  const getCancelLink = (status) => {
    if (
      status === create_request_steps.pickup ||
      status === create_request_steps.stops ||
      status === create_request_steps.proposal ||
      status === create_request_steps.select_shift ||
      status === create_request_steps.select_company ||
      status === "init"
    ) {
      return k_request_paths.saved + `/details/${request_id}`;
    } else if (cr_form.pickup_status === "in_bidding") {
      return k_request_paths.in_bidding + `/details/${request_id}`;
    } else {
      return k_request_paths.saved + `/details/${request_id}`;
    }
  };

  const isAllShift = current_step === create_request_steps.allShifts;
  const isEditPage = window.location.pathname === "/request/edit/" + request_id;

  const isActive = isCrFormValidation();

  const handleGenerate = () => {
    setApplyGenerate(true);
    setExpandedAccordion(null);
    let invalidDateIndices = cr_form?.deliveries
      .map((delivery, index) => {
        if (new Date(delivery.date) < new Date(cr_form?.pickup?.date)) {
          return index;
        }
      })
      .filter(index => index !== undefined);

    setDeliveryInvalidIndex(invalidDateIndices)
    if (isCompany && invalidDateIndices?.length > 0) {
      return console.log("invalidDateIndices", invalidDateIndices);
    }


    if (!isCrFormValidationWithAddress().generate) {
      return console.log("isCrFormValidationWithAddress().generate", isCrFormValidationWithAddress().generate);
      ;
    }

    if (!isActive.generate) {
      Toastr({
        message: t("Please fill up all required fields"),
        type: "warning",
      });
      return;
    }

    setShowRequestConfirmModal(true);
    saveRequest(k_cr_actions.save, false);
  };

  return (
    <div onClick={() => {
      console.log("isActive", isActive);
    }} className="flex flex-row justify-end space-x-4">
      <RequestDeleteConfirmModal
        navigate={create_request_path}
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        request_id={cr_form?.id}
      />

      {location.pathname === k_create_request_paths.shift_details + shift_id ? (
        <>
          <CommonButtonOutlined
            height="h-11"
            colorType="danger"
            btnLabel={t("Cancel")}
            onClick={() => {
              setCurrentSetup(null);
              console.log("location state: ", location.state);
              if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") return navigateTo(`/request/edit/${request_id ?? location?.state?.request_id}`, { state: location?.state })
              else if (location?.state?.referrer === "not-planned") return navigateTo(`/requests/${location?.state?.referrer}/details/${request_id ?? location?.state?.request_id}/edit`, { state: location?.state })
              else if (location?.state?.referrer === "global-request") return navigateTo(`/global-request/details/${request_id ?? location?.state?.request_id}`, { state: { ...location?.state, is_fetch_shift_details: false } })
              navigateTo(k_create_request_paths.create);
            }}
          />
          {
            <CommonButton
              className="h-11"
              btnLabel={
                parseInt(cr_form?.shift_id) === parseInt(shift_id)
                  ? t("Unselect shift ")
                  : t("Select Shift")
              }
              onClick={async () => {
                console.log(cr_form?.shift_id, shift_id);
                // return
                if (parseInt(cr_form?.shift_id) == parseInt(shift_id)) {
                  console.log("unselect shift running...");

                  await changeCrForm("shift_plan", null);
                  await changeCrForm("shift_id", null);

                  console.log("unselect shift done...");

                } else {
                  await changeCrForm("shift_plan", { id: parseInt(shift_id) });
                  await changeCrForm("shift_id", parseInt(shift_id));
                }

                setCurrentSetup(null);
                if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") return navigateTo(`/request/edit/${request_id ?? location?.state?.request_id}`, { state: location?.state })
                else if (location?.state?.referrer === "not-planned") return navigateTo(`/requests/${location?.state?.referrer}/details/${request_id ?? location?.state?.request_id}/edit`, { state: { ...location?.state, is_fetch_shift_details: false } })
                else if (location?.state?.referrer === "global-request") return navigateTo(`/global-request/details/${request_id ?? location?.state?.request_id}`, { state: { ...location?.state, is_fetch_shift_details: false } })

                navigateTo(k_create_request_paths.create);
              }}
              width="w-[120px]"
            />
          }
        </>
      ) : (
        <>
          {isAllShift ? (
            <></>
          ) : (
            <>
              {location.pathname === k_create_request_paths.create ? (
                <>
                  {cr_form?.id && (
                    <CommonButtonOutlined
                      height="h-11"
                      colorType="danger"
                      onClick={() => {
                        console.log("CHECK");

                        setDeleteModal(true)
                      }}
                      width="w-[120px]"
                      btnLabel={t("Delete")}
                    />
                  )}
                </>
              ) : (
                <CommonButtonOutlined
                  height="h-11"
                  colorType="danger"
                  onClick={async () => {
                    if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") {
                      return navigateTo(`/requests/${location?.state?.referrer}/details/${location?.state?.request_id}`, { state: location?.state });
                    }

                    const link = await getCancelLink(cr_form?.pickup_status);
                    navigateTo(link);
                  }}
                  width="w-[120px]"
                  btnLabel={t("Cancel")}
                />
              )}

              {location?.state?.referrer === "awarded" ?
                <CommonButton
                  className="h-11"
                  btnLabel="Update"
                  isDisabled={!cr_form?.shift_id}
                  onClick={async () => {
                    console.log("update cr_form", cr_form);
                    // return 

                    const success = await saveRequest(k_cr_actions.generate)
                    if (success) navigateTo(`/requests/awarded/details/${request_id}`);
                  }}
                />
                :
                <>

                  <CommonButtonOutlined
                    height="h-11"
                    onClick={async () => {
                      const success = await saveRequest(k_cr_actions.save)
                      if (success && isEditPage) {
                        navigateTo(getCancelLink(cr_form?.pickup_status))
                      }
                    }
                    }
                    width="w-[120px]"
                    btnLabel={t("Save")}
                  />

                  <CommonButton
                    className="h-11"
                    width="w-[120px]"
                    btnLabel={t("Generate")}
                    onClick={handleGenerate}
                    isDisabled={!isActive.generate}
                  />
                </>

              }
            </>
          )}
        </>
      )}
    </div>
  );
}
