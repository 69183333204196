/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useLayoutStore from "../../app/stores/others/layoutStore";
import NormalSideBarItem from "./NormalSideBarItem";
import ExpandableSideBarItem from "./SidebarItemArrays/ExpandableSideBarItem";
import { useTranslation } from "react-i18next";
import useChatStore, { getChatCounter } from "../../app/stores/others/chatStore";
import useGeneralStore from "../../app/stores/others/generalStore";
import { iRightArrow } from "../../app/utility/imageImports";
import useRequestStore from "../../app/stores/others/requestStore";
import { create_request_path } from "../../app/utility/const";

const Sidebar = ({ isOpenSidebar }) => {

  const location = useLocation();

  const { setIsSidebarOpen, isSidebarOpen, activeSection, setActiveSection, sidebarItemsList, setShowExpandedSidebarItem, setSideBarSelectItemTitle } = useLayoutStore();

  const { request_counter } = useRequestStore();

  const { t } = useTranslation();

  const { total_unread_message, message_notification, setMessageNotification } = useChatStore();

  const { setPathRecord, setRequestDetailsPath } = useGeneralStore();

  useEffect(() => {
    if (location.pathname) {
      switch (location?.pathname) {
        case "/":
          setActiveSection("home");
          break;

        case "/chat":
          setActiveSection("chat");
          break;

        case create_request_path:
          setActiveSection("create");
          break;

        case "/track-trace":
          setActiveSection("track-trace");
          break;

        case "/driver-manager":
          setActiveSection("driver-manager");
          break;

        case "/car-manager":
          setActiveSection("car-manager");
          break;

        case "/favorite-companies":
          setActiveSection("favorite-companies");
          break;

        case "/favorite-companies/add":
          setActiveSection("favorite-companies");
          break;

        case "/favorite-address":
          setActiveSection("favorite-address");
          break;

        case "/notification":
          setActiveSection("notification");
          break;

        case "/play":
          setActiveSection("playground");
          break;

        default:
          setActiveSection("");
      }

      //r     for nested routes
      if (location?.pathname.includes("global-request")) setActiveSection("global-request");
      if (location?.pathname.includes("shift-manager")) setActiveSection("shift-manager");
      if (location?.pathname.includes("settings")) setActiveSection("settings");
      if (location?.pathname.includes("request/create/")) {
        if (location.state?.referrer === "global-request") setActiveSection("global-request");
        else if (location.state?.referrer === "saved") setActiveSection("saved");
        else if (location.state?.referrer === "invitation") setActiveSection("invitation");
        else if (location.state?.referrer === "not-planned") setActiveSection("not-planned");
        else if (location.state?.referrer === "awarded") setActiveSection("awarded");
        else setActiveSection("create");
      }
    }

  }, [location?.pathname]);

  useEffect(() => {
    const fetchChatCounter = async () => {
      const success = await getChatCounter();
      if (success) setMessageNotification(false);
    }
    fetchChatCounter();

  }, [message_notification])

  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div className="relative">
      <div
        onClick={() => { setIsSidebarOpen(!isSidebarOpen); }}
        className={` absolute top-[60px] w-[30px] h-[20px] z-[121] rounded-md flex justify-center 
          items-center cursor-pointer hover:bg-cBgSelectedSideBar
            transition-all duration-150 ease-in-out ${isSidebarOpen ? "left-[185px]" : "left-[65px]"}`}>
        <img
          src={iRightArrow}
          alt="BrandLogo"
          className={` ${isSidebarOpen ? "rotate-0" : "rotate-180"} transition-all duration-700 h-3`}
        />
      </div>
      {/* green: Main side bar list items */}
      <aside
        className={`${isOpenSidebar ? "w-[230px]" : "w-[100px]"}  h-full flex flex-col justify-between
        absolute top-0 bottom-0 z-index-120 overflow-x-visible md:shadow-md transform transition-all duration-150 ease-in bg-cBgSideBar`}
      >
        <div className={`flex flex-col justify-between bg-cBgSideBar ${isOpenSidebar ? "px-[8px]" : "items-center px-0"} select-none outline-none`}>

          <div className="pt-[75px] space-y-5">

            {/*l      automated sidebar items for better management */}
            {
              sidebarItemsList?.map((item, index) =>
                item?.expandedItems?.length ?
                  <ExpandableSideBarItem
                    await onClick={async () => {
                      setPathRecord("/");
                      setRequestDetailsPath(null);
                      setSelectedIndex(index);
                      item?.expandedOnClick && item?.expandedOnClick();
                      window.scrollTo(0, 0);
                    }}
                    request_counter={request_counter}
                    key={index}
                    index={index}
                    title={t(item?.title)}
                    isActiveLink={item?.isActiveLink}
                    normalIcon={item?.normalIcon}
                    selectedIcon={item?.selectedIcon}
                    expandedItems={item?.expandedItems}
                    selected={selectedIndex}

                  />
                  :
                  <NormalSideBarItem
                    total_unread_message={total_unread_message ?? 0}
                    key={index}
                    onClick={() => {
                      item?.onClick();
                      window.scrollTo(0, 0);
                      setPathRecord("/")
                      setRequestDetailsPath(null);
                      setSideBarSelectItemTitle(null)
                      setSelectedIndex(index + Math.random());
                      setShowExpandedSidebarItem(false)
                    }}

                    title={t(item?.title)}
                    linkTo={item?.linkTo}
                    isActiveLink={item?.isActiveLink === activeSection ? true : false}
                    isOpenSidebar={isOpenSidebar}
                    normalIcon={item?.normalIcon}
                    selectedIcon={item?.selectedIcon}
                  />

              )}
          </div>

        </div>

        {
          isOpenSidebar && location?.pathname !== "/chat" ?
            <div className="flex items-end justify-end pb-4 bg-cBgSideBar pt-s100">
              <div className="flex flex-col items-center justify-center w-full text-xs font-semibold text-cGrey">
                <div>{t('Version')} 1.1</div>
                <div>{t('Copyright')} ©limadi.dk</div>
              </div>
            </div> :
            ""
        }
      </aside>
    </div>
  );
};

export default Sidebar;
