/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import CommonTitle from "../../../../components/title/CommonTitle";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router";
import useGeneralStore, {
  getAllUserInfo,
} from "../../../../app/stores/others/generalStore";
import CommonDetailsInfo from "../../../../components/viewer/CommonDetailsInfo";
import ActionButton from "../../../../components/button/ActionButton";
import useDriverStore, {
  getDriverDetails,
  setDriverFormData,
} from "../../../../app/stores/company/driverStore";
import DummyProfileImage from "../../../../components/image/DummyProfileImage";
import CommonButtonOutlined from "../../../../components/button/CommonButtonOutlined";
import { commonSelectedChat } from "../../../../app/stores/others/chatStore";
import { chat_role, user_role as role } from "../../../../app/utility/const";
import CommonButton from "../../../../components/button/CommonButton";

const DriverManagerDetails = () => {
  const {
    setShowDriverDeleteModal,
    driverDetails,
    setDriverResetPasswordModal,
    resetResetPasswordForm,
  } = useDriverStore();

  const { setPathRecord, user_role } = useGeneralStore();

  const { t } = useTranslation();

  const { driver_id } = useParams();

  const location = useLocation();

  const navigateTo = useNavigate();

  useEffect(() => {
    getDriverDetails(driver_id);
  }, [driver_id]);

  useEffect(() => {
    setPathRecord(location.pathname);
  }, [location.pathname]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-between items-center">
        <CommonTitle
          icon={true}
          link="/driver-manager"
          title={t("Driver Details")}
        />
        <div className="flex justify-end col-span-4 space-x-5">
          <CommonButtonOutlined
            btnLabel={t("Chat")}
            onClick={async () => {
              const res = await getAllUserInfo(driverDetails?.user_id);
              if (res?.success) {
                await commonSelectedChat(
                  res?.data,
                  user_role === role.customer
                    ? chat_role.customer
                    : chat_role.company,
                );
                navigateTo("/chat");
              }
            }}
          />

          <CommonButtonOutlined
            btnLabel={t("Edit")}
            onClick={() => {
              setDriverFormData(driverDetails);
              navigateTo(`/driver-manager/edit/${driver_id}`);
            }}
          />

          <ActionButton
            label={t("Actions")}
            width="w-[139px]"
            dataArray={[
              {
                title: t("Delete"),
                action: () => {
                  setShowDriverDeleteModal(true);
                },
              },
              {
                title: t("Reset Password"),
                action: () => {
                  resetResetPasswordForm();
                  setDriverResetPasswordModal(true);
                },
              },
            ]}
          />
        </div>
      </div>

      <div className="flex flex-col w-[50%]">
        <DummyProfileImage
          src={driverDetails?.image}
          className="w-full h-full mt-4 "
        />

        <div className="mt-5 sub-title">{t("Details")}</div>

        <div className="mt-1 space-y-3">
          <CommonDetailsInfo
            title={t("Name")}
            description={driverDetails?.name}
          />
          <CommonDetailsInfo
            title={t("Email")}
            description={driverDetails?.email}
          />
          <CommonDetailsInfo
            title={t("Phone Number")}
            description={driverDetails?.phone_from_driver}
          />
          <CommonDetailsInfo
            title={t("Instructions")}
            description={driverDetails?.comment}
          />
        </div>
      </div>
    </div>
  );
};

export default DriverManagerDetails;
