/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRequestDetailsPathChecks } from "../../../../../../../../app/hooks/useRequestDetailsPathChecks.js";
import useCreateRequestStore from "../../../../../../../../app/stores/others/createRequestStore.js";
import useGeneralStore from "../../../../../../../../app/stores/others/generalStore";
import useRequestStore, {
  checkIsShowDeliveryOverview
} from "../../../../../../../../app/stores/others/requestStore";
import {
  create_request_steps,
  request_details_types,
  user_role as role,
} from "../../../../../../../../app/utility/const";
import { iCrossOutlined } from "../../../../../../../../app/utility/imageImports.js";
import {
  compareTime,
  extModifierDateTime,
  formatDate,
  formatTime,
  getStringFromDateObject,
  isFutureDateTime,
  Toastr
} from "../../../../../../../../app/utility/utilityFunctions";
import RequestAccordion from "../../../../../../../../components/Accordion/RequestAccordion.jsx";
import ImageViewer from "../../../../../../../../components/image/ImageViewer";
import CommonDatePicker from "../../../../../../../../components/input/CommonDatePicker.jsx";
import CommonInput from "../../../../../../../../components/input/CommonInput.jsx";
import CommonTimePicker from "../../../../../../../../components/input/CommonTimePicker";
import CommonViewComponent from "../../../../../../../../components/viewer/CommonViewComponent";
import CommonEmptyView from "../../../../../../createRequest/components/content/selectShift/components/CommonEmptyView.js";
import DeliveryOverview from "./components/DeliveryOverview";

export default function Deliveries({ data, showTableViewButton = false }) { //data = request_details object!
  const params = useParams();
  const { type, request_id } = params;
  const {
    setPickupDuration,
    updateStopInfoWithId,
    not_planned_stops,
    request_details,
    setNotPlannedStops,
    setShowRequestTableViewModal,
  } = useRequestStore();

  const location = useLocation();
  const { t } = useTranslation();
  const { user_role } = useGeneralStore();

  const [totalProducts, setTotalProducts] = useState(0);
  const [invalidStartTime, setInvalidStartTime] = useState(false);
  const [invalidEndTime, setInvalidEndTime] = useState(false);

  const addressValidation = (address) => {
    let x = address?.replace(/, 0000/g, "");
    let y = x?.replace(/, 0/g, "");
    return y?.length > 0 ? y : null;
  };

  const calculateDeliveryProducts = (stops) => {
    let totalProducts = 0;

    stops.forEach(stop => {
      totalProducts += stop.products.length;
    });

    return totalProducts;
  }

  useEffect(() => {

    if (data?.stops?.length > 0) {
      setTotalProducts(calculateDeliveryProducts(data?.stops));
    }

    if (
      type === "not-planned" ||
      type === "in-bidding" ||
      type === "invitation" ||
      type === request_details_types.awarded ||
      location?.pathname?.includes("/global-request/details")
    ) {
      let deliveryStops =
        request_details?.stops?.map((item) => ({
          date: item?.date,
          duration: !item?.duration ? "" : item?.duration ,
          end_time: item?.end_time,
          id: item?.id,
          stop_id: item?.id,
          start_time: item?.start_time,
          type: "delivery",
          isCustom: 1,
          title: item?.title,
          products: item?.products,
          address: item?.address,
        })) || [];
      let pickupStop = [
        {
          date: request_details?.pickup_date,
          duration: request_details?.pickup_duration,
          end_time: request_details?.pickup_end_time,
          id: request_details?.pickup_id,
          stop_id: request_details?.pickup_id,
          start_time: request_details?.pickup_start_time,
          type: "pickup",
          isCustom: 1,
          title: request_details?.pickup_title,
          address: request_details.pickup_address,
        },
      ];
      setNotPlannedStops(pickupStop.concat(deliveryStops));
      // generateStops({
      //   stops: pickupStop.concat(deliveryStops),
      //   date: request_details?.pickup_date,
      // });
      setPickupDuration(request_details?.pickup_duration);
    }
  }, [request_details, window.location.pathname]);

  const { isEditPath } = useRequestDetailsPathChecks(type, request_id);
  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();

  const [selectDelivery, setSelectDelivery] = useState(null);

  const handleBlurEvents = (type, event) => {
    switch (type) {
      case "date":
      // if (!item?.date) {
      //   if (errorFields.indexOf("date") === -1) {
      //     setErrorFields([...errorFields, "date"]);
      //   }
      // } else {
      //   let newFields = errorFields.filter((field) => field !== "date");
      //   setErrorFields(newFields);
      // }
      // return;
    }
  };

  console.log(not_planned_stops)

  return (
    <>
      {/* {data?.stops?.length > 0 ? ( */}
      <RequestAccordion
        showClearButton={false}
        title={
          t("Delivery") +
          `${expandedAccordion === create_request_steps.mass_import
            ? ` (${data?.stops?.length ? data?.stops?.length : 0})`
            : ""
          } `
        }
        subTitleOne={(data?.stops?.length ?? 0) + " " + t(" Deliveries")}
        subTitleTwo={totalProducts + " " + t(" Products")}
        titleRightComponent={t('Table view')}
        titleRightComponentOnClick={() => {
          setShowRequestTableViewModal(true);
        }}
        isExpanded={expandedAccordion === create_request_steps.mass_import}
        onExpand={() => {
          setSelectDelivery(null);
          setExpandedAccordion(
            expandedAccordion === create_request_steps.mass_import
              ? null
              : create_request_steps.mass_import
          );
        }}
      >
        {data?.stops?.length > 0 ?
          <div className="space-y-5 -mt-s9">
            {data?.stops?.map((stop, index) => (
              <div
                key={index}
                className={` ${selectDelivery === index &&
                  "border-[1px] rounded border-cGray300 p-4 overflow-"
                  } `}
              >
                <RequestAccordion
                  showBorder={true}
                  showClearButton={false}
                  title={`${t("Delivery")} ${index + 1}`}
                  showShift={true}
                  isForm={false}
                  isBorder={false}
                  subTitleOne={stop?.products?.length + " " + t("Products")}
                  subTitleTwo={stop?.address}
                  isExpanded={selectDelivery === index}
                  onExpand={() => {
                    setSelectDelivery(selectDelivery === index ? null : index);

                    if (selectDelivery === index) {
                      setInvalidStartTime(false);
                      setInvalidEndTime(false);
                    }

                  }}
                >
                  <div className="flex flex-col gap-3 -mt-4" key={index}>

                    <div className="relative">
                      {
                        (user_role === role.company) && (type === request_details_types.ongoing || type === request_details_types.history || type === request_details_types.completed) ?
                          <div className="absolute right-0 top-0 text-sm leading-6 text-cGray500">
                            {
                              extModifierDateTime(
                                "delivery",
                                stop?.status,
                                stop?.delivery_expected_date,
                                stop?.delivery_expected_time,
                                stop?.delivery_driver_complete_date,
                                stop?.delivery_driver_complete_time
                              )
                            }
                          </div>
                          : <></>
                      }
                    </div>

                    <CommonViewComponent
                      labelText={t("Delivery Address")}
                      value={addressValidation(stop?.address)}
                    />
                    <CommonViewComponent
                      labelText={t("Floor Number")}
                      value={stop?.floor_number ?? "No floor number"}
                    />

                    {/* only for company */}
                    {user_role === role.company ? (
                      <>
                        {(((type === request_details_types.not_planned &&
                          data?.awarded?.status === "init") ||
                          type === request_details_types.in_bidding ||
                          type === request_details_types.awarded) &&
                          isEditPath) ||
                          type === request_details_types.invitation || window.location.pathname.includes("/global-request/details") ? (
                          <>
                            {/* <CommonViewComponent labelText={t('Date')} value={defineNotPlannedDeliveryDate(data?.pickup_date, not_planned_stops[index]?.start_time, data?.pickup_start_time)} className='pb-[2px]' /> */}

                            <div className="grid grid-cols-4 gap-x-5 -mb-s12">
                              <div className="-mt-s6">
                                <CommonDatePicker
                                  startDate={
                                    data?.pickup_date
                                      ? data?.pickup_date
                                      : new Date()
                                  }
                                  required={false}
                                  allowPastDate={true}
                                  shouldUpdateValue={true}
                                  label={t("Date")}
                                  value={
                                    not_planned_stops[index + 1]?.date
                                      ? not_planned_stops[index + 1]?.date
                                      : null
                                  }

                                  onChange={(date) => {
                                    updateStopInfoWithId(
                                      stop?.id,
                                      "date",
                                      getStringFromDateObject(date)
                                    );
                                  }}
                                  onCloseEvent={() => handleBlurEvents("date")}
                                />
                              </div>
                              <CommonTimePicker
                                label={t("Time")}
                                name={"start_time"}
                                disabled={!not_planned_stops[index + 1]?.date}
                                init_time={not_planned_stops[index + 1]?.start_time ? not_planned_stops[index + 1]?.start_time?.slice(0, 5) : ""}
                                isInvalid={invalidStartTime}

                                init_date={not_planned_stops[index + 1]?.date ?? new Date()}
                                isCustomMinTimeValidation={!isFutureDateTime(not_planned_stops[index + 1]?.date)}
                                customMinTimeValue={not_planned_stops[index + 1]?.start_time ? not_planned_stops[index + 1]?.start_time?.slice(0, 5) : ""}

                                // isInvalid={invalidStartTime}
                                onChange={(start_time, end_time) => {

                                  updateStopInfoWithId(
                                    stop?.id,
                                    "start_time",
                                    start_time
                                  );
                                  updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    end_time
                                  );
                                }}
                                marginClass="mt-s6"
                                showExtendedTimeUi={false}
                                showClear={true}
                                handleClear={async () => {
                                  await updateStopInfoWithId(
                                    stop?.id,
                                    "start_time",
                                    ""
                                  );
                                  await updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    ""
                                  );
                                }}
                                clearIconTooltipText={t("Clear delivery time")}
                              />

                              <CommonTimePicker
                                label={t("Range")}
                                name={"end_time"}
                                marginClass="mt-s6"
                                disabled={!not_planned_stops[index + 1]?.date}

                                init_date={not_planned_stops[index + 1]?.date ?? new Date()}
                                isCustomMinTimeValidation={!isFutureDateTime(not_planned_stops[index + 1]?.date)}
                                customMinTimeValue={not_planned_stops[index + 1]?.start_time ? not_planned_stops[index + 1]?.start_time?.slice(0, 5) : "00:00"}

                                init_time={not_planned_stops[index + 1]?.end_time?.slice(0, 5)}
                                showExtendedTimeUi={false}
                                isInvalid={invalidEndTime}
                                onChange={(start_time, end_time) => {

                                  updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    start_time
                                  );
                                }}
                                showClear={true}
                                handleClear={async () => {
                                  await updateStopInfoWithId(
                                    stop?.id,
                                    "end_time",
                                    ""
                                  );
                                }}
                                clearIconTooltipText={t("Clear delivery time")}
                              />
                              {console.log(not_planned_stops[index + 1]?.duration, typeof(not_planned_stops[index + 1]?.duration))}

                              <div className="flex -mt-s18">
                                <div className="relative w-full">
                                  <CommonInput
                                    max_input={5}
                                    type="number"
                                    labelText={t("Duration (min)")}
                                    // parentClassName={"mt-s2"}
                                    name={"duration"}
                                    isInvalid={not_planned_stops[index + 1]?.duration && not_planned_stops[index + 1]?.duration < 3}
                                    value={
                                      not_planned_stops[index + 1]?.duration
                                    }
                                    onChange={(e) => {
                                      if (!not_planned_stops[index + 1]?.duration && e.target.value == 0) {
                                        e.preventDefault();
                                        return;
                                      }
                                      updateStopInfoWithId(
                                        stop?.id,
                                        "duration",
                                        e.target.value
                                      );
                                    }}
                                  />

                                  <div className="absolute right-0 flex space-x-2 bottom-[15px]">
                                    {not_planned_stops[index + 1]?.duration ? (
                                      <Tooltip
                                        title={t("Clear Duration")}
                                        color={"#242826"}
                                      >
                                        <img
                                          onClick={() => {
                                            updateStopInfoWithId(
                                              stop?.id,
                                              "duration",
                                              ""
                                            );
                                          }}
                                          className="w-5 h-5 cp"
                                          src={iCrossOutlined}
                                          alt=""
                                        />
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <CommonViewComponent
                              labelText={t("Date")}
                              value={
                                stop?.start_date ? formatDate(stop?.start_date) : stop?.date ? formatDate(stop?.date) : "No date"
                              }
                            />
                            <CommonViewComponent
                              labelText={t("Time")}
                              value={
                                stop?.start_time || stop?.end_time
                                  ? ` ${stop?.start_time
                                    ? formatTime(stop?.start_time)
                                    : "00:00"
                                  } ${stop?.end_time ? " - " : ""} ${stop?.end_time
                                    ? formatTime(stop?.end_time)
                                    : "- 00:00"
                                  }`
                                  : "No time"
                              }
                            />
                            <CommonViewComponent
                              labelText={t("Duration (min)")}
                              value={
                                stop?.duration ? `${stop?.duration} min` : "No duration"
                              }
                            />
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {stop?.products?.map((product, index) => (
                      <CommonViewComponent
                        key={index}
                        labelText={t(`Product`)}
                        value={product?.text}
                      />
                    ))}

                    <CommonViewComponent
                      labelText={t("Comment")}
                      value={stop?.comment ?? "No comment"}
                    />

                    {stop?.attachment && (
                      <div className="">
                        <ImageViewer
                          src={stop?.attachment}
                          label={t("Attachment")}
                          withExtension={true}
                        />
                      </div>
                    )}

                    {checkIsShowDeliveryOverview(stop, type) && (
                      <DeliveryOverview index={index} stop={stop} />
                    )}
                  </div>
                </RequestAccordion>
              </div>
            ))}

            {/* todo customer profile */}
          </div> :
          <CommonEmptyView
            message="No delivery stops found!"
          />
        }
      </RequestAccordion >
      {/* ) : (
        ""
      )} */}
    </>
  );
}
