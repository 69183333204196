import React from 'react'
import CommonTopTitleSection from '../../../../components/title/CommonTopTitleSection';
import useShiftStore from '../../../../app/stores/company/shiftStore';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import CommonButton from '../../../../components/button/CommonButton';
import { useTranslation } from 'react-i18next';
import { k_shift_status } from '../../../../app/utility/const';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import RequestDetailsTopBar from '../../../common/requests/requestDetails/components/requestDetailsTopBar/RequestDetailsTopBar';
import { k_create_request_paths } from '../../../../app/utility/AllRoute';
import TitleAndActions from '../../../common/createRequest/components/titleAndActions/TitleAndActions';

const ShiftDetailsHeader = () => {

    const { t } = useTranslation();

    const { shiftDetailsData, setShowDeleteShiftModal } = useShiftStore();

    const params = useParams();

    const { type, shift_id } = params;

    const navigateTo = useNavigate();
    const location = useLocation();

    const isShiftInit = !shiftDetailsData?.request_count && !type;
    const backLink = !type && "/shift-manager";
    const title = t('Shift Details');

    const rightSideComponent = isShiftInit ? (
        <div className='flex xl:flex-row flex-col xl:items-center items-baseline space-y-2.5 xl:space-y-0 xl:space-x-2.5'>
            <div className='flex items-center space-x-5' >
                <CommonButtonOutlined btnLabel={t('Delete')} colorType='danger' onClick={() => setShowDeleteShiftModal(true)} />
                <CommonButton
                    btnLabel={t('Edit')}
                    onClick={() => {
                        // setShowEditShiftModal(true);
                        navigateTo(`/shift-manager/edit/${shift_id}`)
                    }}
                />
            </div>
        </div>
    ) : "";

    return !!type ?(
            <RequestDetailsTopBar SectionTitle={t("Request Details")} />
        ) : (

            location.pathname === k_create_request_paths.shift_details + shift_id ?
                <TitleAndActions />
                :
                <CommonTopTitleSection
                    withBackLink={backLink}
                    title={title}
                    rightSideComponent={rightSideComponent}
                />
        ) 
    ;
}

export default ShiftDetailsHeader