import { useState } from 'react';
import { useEffect } from 'react';
import { RotatingLines } from "react-loader-spinner";
import { ThreeDots } from 'react-loader-spinner';
import { base_url_src, k_file_type } from '../../../../app/utility/const';
import { iChatBabbleFrom, iChatBabbleTo } from '../../../../app/utility/imageImports';
import ImageUploadViewModal2 from '../../../../components/imageUpload/ImageUploadViewModal2';
import { useTranslation } from 'react-i18next';

const ChatMessageItem = ({
    messageText = 'Hello',
    isReplyType = false,
    time = "",
    fail = false,
    newMsg = false,
    attachment = "",

}) => {

    const { t } = useTranslation();

    const fileExtension = attachment?.split('.')?.pop()?.toLowerCase();

    const imageExtensions = [k_file_type.jpg, k_file_type.jpeg, k_file_type.png, k_file_type.gif, k_file_type.bmp];

    const [loading, setLoading] = useState(true);

    const [show_modal, setShowModal] = useState(false);

    useEffect(() => {
        const image = new Image();
        image.src = attachment;

        const handleLoad = () => {
            setLoading(false);
        };

        const handleError = () => {
            setLoading(false);
        };

        image.addEventListener('load', handleLoad);
        image.addEventListener('error', handleError);

        return () => {
            image.removeEventListener('load', handleLoad);
            image.removeEventListener('error', handleError);
        };
    }, [attachment]);


    return (
        <div>
            <div className={`flex items-start space-x-3 ${isReplyType ? 'justify-end' : ''}`}>
                <div className="flex">
                    {!isReplyType && <div className='flex items-end justify-end'>
                        <img alt='' src={iChatBabbleFrom} className='max-w-[8px] min-w-[8px] h-[15px]' />
                    </div>}
                    <div className={`p-2 rounded-br6 ${isReplyType ? 'bg-cGreen50' : 'bg-cGray50'} `}>
                        {
                            attachment &&
                            (loading ? (
                                <div className='h-[350px] max-w-[350px] flex justify-center items-center mb-1'>
                                    <RotatingLines
                                        width="100"
                                        strokeColor="#285D43"
                                        strokeWidth={4}
                                        strokeWidthSecondary={3}
                                    />
                                </div>
                            ) :
                                <div className={`${isReplyType ? "flex justify-end items-end" : "flex justify-start items-start"} `}>
                                    <img
                                        onClick={() => { setShowModal(true) }}
                                        src={imageExtensions?.includes(fileExtension) ? base_url_src + attachment : attachment}
                                        alt="" className={`min-w-[200px] max-w-[420px] mb-1 object-fill rounded-br6 max-h-[350px] cursor-pointer`}
                                    />
                                </div>
                            )
                        }

                        {
                            messageText &&
                            <div className="max-w-[420px] whitespace-pre-line text-fs14 font-fw400 text-cGray800 w-full break-all flex justify-start">
                                {messageText}
                            </div>
                        }

                        {
                            time &&
                            <div className='flex justify-end mt-1 space-x-1'>
                                <div className='text-fs10 font-fw400 text-cGray500'>{time}</div>
                            </div>
                        }

                    </div>
                    {
                        isReplyType && <div className='flex items-end justify-end'>
                            <img alt='' src={iChatBabbleTo} className='max-w-[8px] min-w-[8px] h-[15px]' />
                        </div>
                    }

                </div>
            </div>
            {fail && <div className='flex justify-end text-fs12 text-cRed font-fw400'>{t("Failed to send")}</div>}
            {
                newMsg && <div className='flex justify-end space-x-1'>
                    <div className='text-fs10 text-cBrand font-fw400'>{t("Sending")}</div>
                    <div>
                        <ThreeDots
                            height="20"
                            width="20"
                            radius="9"
                            color="#285D43"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                        />
                    </div>
                </div>
            }
            <ImageUploadViewModal2
                fullView={true}
                show_modal={show_modal}
                setShowModal={setShowModal}
                src={base_url_src + attachment}
                url={base_url_src + attachment}

            />
        </div>
    )
}

export default ChatMessageItem