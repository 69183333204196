import React from 'react'
import { iAdmin, iUserAvatar } from '../../../../app/utility/imageImports';
import Image from '../../../../components/image/Image';
import { user_role } from '../../../../app/utility/const';
import Clamp from "react-multiline-clamp";
import classNames from 'classnames';

const CommonChatBox = ({ user_type = "", isSeen = false, name = "", date_time = "", onClick = () => { }, image = "" }) => {

    const userRoleClass = classNames('capitalize', {
        'text-cGray500 font-fw400 text-fs14': isSeen,
        'text-cGray800 font-fw900 text-fs16': !isSeen
    });

    const userTypeTextClass = classNames('flex justify-between w-full', {
        'text-cGray500': isSeen,
        'text-cGray800': !isSeen
    });

    return (
        <div onClick={onClick} className='flex w-full p-2 space-x-2 rounded cursor-pointer hover:bg-cPrimary100'>
            {user_type === user_role.admin ? (
                <img alt='Admin Icon' src={iAdmin} className='max-w-[44px] min-w-[44px] h-[44px]' />
            ) : (
                <Image alt='User Avatar' src={image} roundedFull={true} dummyImage={iUserAvatar} className='max-w-[44px] min-w-[44px] h-[44px] rounded' />
            )}

            <div className="space-y-[2px] w-full">
                <div className={userRoleClass}>
                    <Clamp withTooltip lines={1}>{name}</Clamp>
                </div>

                <div className={userTypeTextClass}>
                    <div className='capitalize break-all text-cNero font-fw400 text-fs12'>{user_type === user_role.admin ? "Admin" : user_type}</div>
                    {date_time && <div className='text-fs10 max-w-[109px] min-w-[109px] flex items-end justify-end font-fw400'>{date_time}</div>}
                </div>
            </div>
        </div>
    )
}

export default CommonChatBox