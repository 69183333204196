/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useCreateRequestStore, {
  clearPickupFrom,
  isCrFormValidationWithAddress,
} from "../../../../../../../app/stores/others/createRequestStore";
import { create_request_steps, user_role as role } from "../../../../../../../app/utility/const";
import { formatDate, formatDateV2, getStringFromDateObject, handleInputChange, isFutureDateTime, isWhitespaceOnly } from "../../../../../../../app/utility/utilityFunctions";
import ImageUpload from "../../../../../../../components/imageUpload/ImageUpload";
import AddressAutoComplete from "../../../../../../../components/input/AddressAutoComplete";
import CommonDatePicker from "../../../../../../../components/input/CommonDatePicker";
import CommonInput from "../../../../../../../components/input/CommonInput";
import CommonTimePicker from "../../../../../../../components/input/CommonTimePicker";
import CommonSelect from "../../../../../../../components/select/CommonSelect";
import { useTranslation } from "react-i18next";
import { iBorderedImageUploadIcon } from "../../../../../../../app/utility/imageImports";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import RequestAccordion from "../../../../../../../components/Accordion/RequestAccordion";
import ConfirmChangeDateTimeModal from "./ConfirmChangeDateTimeModal";

export default function Pickup() {

  const {
    isApplyGenerate,
    cr_form,
    changeCrForm,
    updateCrField,
    type_of_transportation,
    expandedAccordion,
    setExpandedAccordion,
    updateStopField,
    oldPickupDateTime,
    setOldPickupDateTime,
  } =
    useCreateRequestStore();
  const [doSearch, setDoSearch] = useState(false);
  const { t } = useTranslation();
  const { user_role } = useGeneralStore();

  const [isInvalidAddress, setIsInvalidAddress] = useState(false);
  const [showConfirmPickupDateTimeChange, setShowPickupConfirmDateTimeChange] = useState(false);

  const {
    title,
    transport_type,
    pickup: {
      address,
      floor_number,
      start_time,
      end_time,
      duration,
      comment,
      attachment,
      date,
    } = {},
  } = cr_form || {};

  const isPickupFormFilled = [
    title,
    transport_type,
    address,
    floor_number,
    start_time,
    end_time,
    duration,
    comment,
    attachment,
    cr_form?.pickup_attachment_url,
    date,
  ].some(Boolean);

  const handleChange = (e) => {
    updateCrField(e.target.name, e.target.value);
  };

  const isValidAddress = () => {
    if (cr_form?.pickup?.address && cr_form?.pickup?.lat && cr_form?.pickup?.lng) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setIsInvalidAddress(!isValidAddress())
  }, [cr_form]);

  let curr_hour = (new Date()).getHours();
  let curr_min = (new Date()).getMinutes();

  curr_min = Math.ceil(curr_min / 15) * 15;

  if (curr_min === 60) {
    curr_min = 0;
    curr_hour += 1;
  }

  if (curr_hour === 24) {
    curr_hour = 0;
  }

  return (
    <div className="overflow-hidden">
      <RequestAccordion
        isInvalid={isApplyGenerate && !isCrFormValidationWithAddress()?.pickup}
        showClearButton={isPickupFormFilled}
        clearButtonTooltipMessage="Clear"
        clearHandler={() => {
          clearPickupFrom(); console.log("clear Pickup HITS !!");
        }}
        title={t("Pickup")}
        subTitleOne={cr_form?.transport_type}
        subTitleTwo={cr_form?.pickup?.address}
        isForm={true}
        isExpanded={expandedAccordion === create_request_steps.pickup}
        onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.pickup ? null : create_request_steps.pickup) }}
      >
        <div className="flex flex-col justify-start">

          <ConfirmChangeDateTimeModal
            showModal={showConfirmPickupDateTimeChange}
            setShowModal={setShowPickupConfirmDateTimeChange}
            onConfirm={() => {
              console.log("CONFIRMED!");
              setShowPickupConfirmDateTimeChange(false);
            }}
            onCancel={() => {
              console.log("CANCELLED!");
              setShowPickupConfirmDateTimeChange(false);
            }}
          />

          <div className="flex flex-row justify-between w-full space-x-5">

            <CommonInput
              max_input={55}
              name={"title"}
              value={cr_form?.title ?? ""}
              onChange={handleChange}
              labelText={t("Title")}
            />

            <div className="w-full mt-[12px]">
              <CommonSelect
                isInvalid={isApplyGenerate && !cr_form?.transport_type}
                required={true}
                label={t("Type")}
                dataArray={type_of_transportation}
                has_subtitle={false}
                onChange={(e, value) => {
                  const targetObject = type_of_transportation?.find((item) => item.value === e.target.value);

                  const { duration } = targetObject ?? 0;
                  const transport_id = targetObject?.id ?? null;

                  updateCrField("transport_type", value);
                  updateCrField("typeoftransportation_id", transport_id);
                  // updateCrField("transport_type", value);
                  if (user_role === role.company) {
                    updateCrField("pickup.duration", duration)
                  }
                }}
                value={cr_form?.transport_type}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-x-5">

            {/* delivery type and address */}
            <AddressAutoComplete
              isInvalid={cr_form?.pickup?.address && isInvalidAddress}
              className="w-full"
              required={true}
              label={t("Pickup Address")}
              name={"pickup_address"}
              address={cr_form?.pickup?.address ?? ""}
              lat={cr_form?.pickup?.lat}
              lng={cr_form?.pickup?.lng}
              latName={"pickup_lat"}
              lngName={"pickup_lng"}
              changeValue={(name, value) => {

                console.log("address change value", name, value);


                if (name === "pickup_address") {
                  updateCrField("pickup.address", value);
                } else if (name === "pickup_lat") {
                  updateCrField("pickup.lat", value?.toString());
                } else if (name === "pickup_lng") {
                  updateCrField("pickup.lng", value?.toString());
                }
              }}
              doSearch={doSearch}
              setDoSearch={setDoSearch}
              onBlurEvent={() => { }}
              onFavAddressModalItemClick={(address) => {
                updateCrField("pickup.address", address?.address);
                updateCrField("pickup.lat", address?.lat);
                updateCrField("pickup.lng", address?.lng);
                updateCrField(
                  "pickup.floor_number",
                  address.floor_number === null ? "" : address.floor_number
                );
              }}
            />

            <CommonInput
              type="text"
              labelText={t("Floor number")}
              name={"pickup_floor_number"}
              value={cr_form?.pickup?.floor_number ?? ""}
              onChange={handleInputChange(updateCrField, "pickup.floor_number")}
              max_input={55}
            />
          </div>

          {/* pickup date and time */}
          <div className="grid w-full grid-cols-2 mt-4 gap-x-6">

            <div className={`grid ${user_role === role.company ? "grid-cols-2" : "grid-cols-1"} gap-x-[30px] w-full`}>

              <CommonDatePicker
                isInvalid={isApplyGenerate && !cr_form?.pickup?.date}
                required={true}
                allowPastDate={false}
                label={t("Date")}
                name="pickup_date_formatted"
                value={cr_form?.pickup?.date ?? ""}
                startDate={new Date()}
                shouldUpdateValue={true}

                onChange={(date) => {

                  //TODO: if (cr_form?.deliveries?.length) {
                  //   console.log("cr_form::::", cr_form);
                  //   setOldPickupDateTime({
                  //     date: cr_form?.pickup?.date,
                  //     start_time: cr_form?.pickup?.start_time,
                  //     end_time: cr_form?.pickup?.end_time,
                  //   })
                  //   return setShowPickupConfirmDateTimeChange(true);
                  // }


                  updateCrField("pickup.date", getStringFromDateObject(date));
                  updateCrField("pickup.start_time", "");
                  updateCrField("pickup.end_time", "");
                  updateStopField("date", "");
                  changeCrForm("shift_plan", null);
                  changeCrForm("shift_id", null);
                  updateStopField(
                    "start_time",
                    ""
                  );
                  updateStopField(
                    "end_time",
                    ""
                  );
                }}
              />

              {user_role === role.company && <div className="w-full mt-2">
                <CommonTimePicker
                  // isCurrentTimeValidation={formatDate(cr_form?.pickup?.date ?? "") === formatDate(new Date())} //={new Date(cr_form?.pickup_date_formatted)?.toLocaleDateString() === new Date().toLocaleDateString()}
                  isInvalid={isApplyGenerate && !cr_form?.pickup?.start_time}
                  required={true}
                  label={t("Time")}
                  marginClass="mt-s4"
                  disabled={cr_form?.pickup?.date ? false : true}

                  init_date={cr_form?.pickup?.date ?? new Date()}
                  isCustomMinTimeValidation={!isFutureDateTime(cr_form?.pickup?.date)}
                  customMinTimeValue={curr_hour + ":" + curr_min}

                  init_time={cr_form?.pickup?.start_time}

                  showExtendedTimeUi={false}
                  name="pickup_start_time"
                  onChange={(start_time, end_time) => {
                    //TODO: if (cr_form?.deliveries?.length) {
                    //   console.log("cr_form::::", cr_form);
                    //   setOldPickupDateTime({
                    //     date: cr_form?.pickup?.date,
                    //     start_time: cr_form?.pickup?.start_time,
                    //     end_time: cr_form?.pickup?.end_time,
                    //   })
                    //   return setShowPickupConfirmDateTimeChange(true);
                    // }

                    updateCrField("pickup.start_time", start_time);
                    updateCrField("pickup.end_time", end_time);
                  }}
                />
              </div>}

            </div>

            <div className="grid grid-cols-2 gap-x-[30px]">
              {user_role === role.customer &&
                <div className="w-full mt-2">
                  <CommonTimePicker
                    isInvalid={isApplyGenerate && !cr_form?.pickup?.start_time}
                    required={true}
                    label={t("Time")}
                    marginClass="mt-s4"
                    disabled={cr_form?.pickup?.date ? false : true}


                    init_date={cr_form?.pickup?.date ?? new Date()}
                    isCustomMinTimeValidation={!isFutureDateTime(cr_form?.pickup?.date)}
                    customMinTimeValue={curr_hour + ":" + curr_min}

                    init_time={cr_form?.pickup?.start_time}
                    showExtendedTimeUi={false}
                    name="pickup_start_time"
                    onChange={(start_time, end_time) => {
                      //TODO: if (cr_form?.deliveries?.length) {
                      //   console.log("cr_form::::", cr_form);
                      //   setOldPickupDateTime({
                      //     date: cr_form?.pickup?.date,
                      //     start_time: cr_form?.pickup?.start_time,
                      //     end_time: cr_form?.pickup?.end_time,
                      //   })
                      //   return setShowPickupConfirmDateTimeChange(true);
                      // }

                      updateCrField("pickup.start_time", start_time);
                      updateCrField("pickup.end_time", end_time);
                    }}
                  />
                </div>
              }

              <div className="w-full mt-2">
                <CommonTimePicker
                  isInvalid={isApplyGenerate && !cr_form?.pickup?.end_time}
                  required={true}
                  label={t("Range")}
                  marginClass="mt-s4"
                  init_time={cr_form?.pickup?.end_time ?? ""}
                  disabled={cr_form?.pickup?.start_time ? false : true}

                  // customMinTimeValue={cr_form?.pickup?.start_time ?? ""}
                  // isCustomMinTimeValidation={true}

                  showExtendedTimeUi={false}
                  name="pickup_end_time"
                  onChange={(start_time, end_time) => {
                    //TODO: if (cr_form?.deliveries?.length) {
                    //   console.log("cr_form::::", cr_form);
                    //   setOldPickupDateTime({
                    //     date: cr_form?.pickup?.date,
                    //     start_time: cr_form?.pickup?.start_time,
                    //     end_time: cr_form?.pickup?.end_time,
                    //   })
                    //   return setShowPickupConfirmDateTimeChange(true);
                    // }

                    updateCrField("pickup.end_time", start_time);
                  }}
                />
              </div>

              {user_role === role.company && (
                <div className="pb-2 gap-x-5 mt-[-12px]">
                  <CommonInput
                    type="number"
                    labelText={t("Duration (min)")}
                    name={"pickup_duration"}
                    value={cr_form?.pickup?.duration ?? ""}
                    max_input={5}

                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, "");
                      updateCrField("pickup.duration", value);
                    }}
                  />
                </div>
              )}
            </div>

          </div>

          <CommonInput
            type="text"
            textarea={true}
            multiline={true}
            max_input={255}
            value={isWhitespaceOnly(cr_form?.pickup?.comment) ? "" : cr_form?.pickup?.comment}
            name={"pickup_comment"}
            rows={2}
            max={255}
            labelText={t("Comment")}
            onChange={handleInputChange(updateCrField, "pickup.comment")}
          />

          <div className="mt-5">
            <ImageUpload
              setImage={(value) => {
                updateCrField("pickup.attachment", value);
              }}
              image_url={cr_form?.pickup_attachment_url}
              src={cr_form?.pickup?.attachment}
              setSrc={(value) => {
                updateCrField("pickup.attachment", value);
              }}
              title="Add Attachment"
              imageUploadIcon={iBorderedImageUploadIcon}
              addButtonClassName="border border-dashed border-cGray300 p-s10 w-max"
              width="w-max"
              showInfoText={true}
              textStyle="text-fs14 font-fw400 text-cBrand leading-[25px]"
            />
          </div>
        </div>
      </RequestAccordion>
    </div>
  );
}
