/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Clamp from 'react-multiline-clamp';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import useShiftStore, { defineScheduleInfo, defineShiftStatus, getAllShiftCarsAndDriversList, getShiftTableData, handleOrder } from '../../../app/stores/company/shiftStore';
import useGeneralStore from '../../../app/stores/others/generalStore';
import { isTableFiltered } from '../../../app/stores/others/requestStore';
import { kuShiftManagerTableData } from '../../../app/urls/companyUrl';
import { common_take, k_orderBy, k_requests_status } from '../../../app/utility/const';
import { iFilterGreen, iFilterWhite } from '../../../app/utility/imageImports';
import CommonButton from '../../../components/button/CommonButton';
import CommonButtonOutlined from '../../../components/button/CommonButtonOutlined';
import CommonTable from '../../../components/table/CommonTable';
import ShiftManagerFilter from './components/ShiftManagerFilter';

export default function ShiftManagerTable() {

  const { t } = useTranslation();

  const { resetAddShiftForm, shift_table_data, search_key, setSearchKey, take, setTake, setApiUrl, setOrder, setIsAsc, is_asc, order, filter_form_copy, setAllShiftCarList, setAllShiftDriverList, filter_form, resetFilterForm, resetFilterFormCopy } = useShiftStore();

  const [searchValue] = useDebounce(search_key, 500);
  const navigateTo = useNavigate();
  const { path_record } = useGeneralStore();
  const [showModal, setShowModal] = useState(false);

  const headers = [
    { name: "#", placement: "justify-center items-center" },

    { isActive: order === k_orderBy.driver_name, sort: is_asc, name: t("Driver"), onClickAction: () => handleOrder(k_orderBy.driver_name, getData), showSortImage: true},

    { isActive: order === k_orderBy.car_number, sort: is_asc, name: t("License"), onClickAction: () => handleOrder(k_orderBy.car_number, getData), showSortImage: true},

    { isActive: order === k_orderBy.shift_date_time_raw, sort: is_asc, name: t("Date & Time"), onClickAction: () => handleOrder(k_orderBy.shift_date_time_raw, getData), showSortImage: true},

    { isActive: order === k_orderBy.status, sort: is_asc, name: t("Status"), onClickAction: () => handleOrder(k_orderBy.status, getData), showSortImage: true},
  ];

  const getData = () => getShiftTableData({
    filter: {
      start_date: filter_form?.start_date,
      end_date: filter_form?.end_date,
      start_time: filter_form?.start_time,
      end_time: filter_form?.end_time,
      plate_number: filter_form?.plate_number,
      driver_id: filter_form?.driver_id,
      is_maintenance: filter_form?.is_maintenance,
      status: filter_form?.status,
    }
  });

  const resetTable = async () => {
    if (searchValue?.length > 0) await setSearchKey('');
    await setApiUrl(kuShiftManagerTableData);
    if (take !== common_take) await setTake(common_take);
    await setOrder(k_orderBy.shift_date_time_raw);
    await setIsAsc(1);
    await resetFilterForm();
    await resetFilterFormCopy();
    await getShiftTableData({ filter: { start_date: "" } });
    return;
  }

  const handleInitialSetup = async () => {
    if (!path_record?.old?.toLowerCase().includes('/shift-manager/details/')) {
      await resetTable();
      getAllShiftCarsAndDriversList(null, null, null, null, false);
    } else {
      getData();
    }
  }

  useEffect(() => { handleInitialSetup() }, []);

  useEffect(() => { getData() }, [searchValue]);

  const contents = shift_table_data?.data?.map((item, index) => {
    return {
      contents: [
        {value: (shift_table_data?.current_page * common_take - common_take) + index + 1},
        {value: 
          <Clamp lines={2}>
            {
              item?.shift_drivers?.length > 0 ? item?.shift_drivers?.map((driver) => driver?.is_main_driver ? driver?.driver?.name + " (M)" : driver?.driver?.name).join(", ")
                : item?.driver_name + `${item.driver_name === "Maintenance" ? "" : " (M)"}` ?? ''
            }
          </Clamp>
        },
        {value: item?.car_number},
        {value: defineScheduleInfo({ start_date: item?.shift_start_date, end_date: item?.shift_end_date, start_time: item?.shift_start_time, end_time: item?.shift_end_time })},
        {value: defineShiftStatus(item?.status, item?.is_maintenance) ?? ''},
      ],
      clickAction: () => {
        navigateTo('details/' + item?.id);
      }
    }
  })

  return (
    <>
      <CommonTable
        tableTitle={t('Shift') + ` (${shift_table_data?.total ?? 0})`}
        tableTitleClassName="title"
        showSearchBox={true}
        showTakeOption={true}
        showPagination={true}
        showPageCountText={true}
        showTopRightFilter={true}
        headers={headers}
        outerPadding='p-s0'
        paginationObject={shift_table_data}

        withClearSearch={true}
        onSearchClear={() => { setSearchKey("") }}
        searchValue={search_key}
        searchOnChange={(e) => {
          setApiUrl(kuShiftManagerTableData);
          setSearchKey(e.target.value);
        }}

        currentTakeAmount={take}
        withReloader={true}
        onReload={resetTable}
        takeOptionOnChange={async (e) => {
          await setTake(e.target.value);
          await setApiUrl(kuShiftManagerTableData);
          getData();
        }}
        paginationOnClick={async (url) => {
          await setApiUrl(url);
          getData();
        }}

        tableHeaders={headers}
        tableContents={contents}
        hasClickFunction={true}
        widths="50px auto 10% 20% 10%"

        searchFilterTopSpacing='space-x-4'
        topRightMainComponent={
          <>
            <CommonButton
              btnLabel={t('Add Shift')}
              width="w-[120px]"
              type='button'
              onClick={() => {
                setAllShiftCarList([]);
                setAllShiftDriverList([]);
                resetAddShiftForm();
                navigateTo('add');
              }} />
          </>
        }
        topRightFilterComponent={
          <div className="relative">
            {isTableFiltered(k_requests_status.shift, filter_form_copy) && <div className="absolute z-50 w-2 h-2 rounded-full bg-cBrand right-1 top-1"></div>}
            <CommonButtonOutlined btnLabel={t('Filter')} onClick={() => setShowModal(true)} colorType="primary" iconLeft={iFilterWhite} iconLeftHover={iFilterGreen} />
          </div>
        }
      />
      <ShiftManagerFilter showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}
