import React from 'react'
import { useTranslation } from 'react-i18next';
import useCarStore from '../../../../app/stores/company/carStore'
import CommonTitle from '../../../../components/title/CommonTitle';
import CommonButton from '../../../../components/button/CommonButton';
import { car_manager_path, k_license_status } from '../../../../app/utility/const';
import { useParams, useNavigate } from 'react-router';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';

const CarDetailsTopBar = () => {

    const navigateTo = useNavigate();

    const { car_id } = useParams();

    const { t } = useTranslation();

    const { carDetails, setCarLicenseRenewID, setShowCarDeleteModal, setNoLicenseApply } = useCarStore();

    return (
        <div className='flex justify-between'>
            <CommonTitle
                title={t('Car Details')}
                link='/car-manager'
                icon={true}
            />

            <div className='flex space-x-5'>
                {
                    (

                        carDetails?.license_status === k_license_status.expired ||
                        carDetails?.license_status === k_license_status.expire_warning ||
                        carDetails?.license_status === k_license_status.rejected ||
                        carDetails?.license_status === k_license_status.no_license ||
                        carDetails?.license_status === k_license_status.active
                    )
                    &&
                    <>
                        <CommonButtonOutlined
                            btnLabel={t("Delete")}
                            onClick={() => {
                                setShowCarDeleteModal(true);
                            }}
                            colorType='danger'
                            width='w-[100px]'
                        />
                        <CommonButton
                            btnLabel={t("Edit")}
                            onClick={() => {
                                navigateTo(car_manager_path?.edit + `/${car_id}`);
                            }}
                            width='w-[100px]'
                        />

                        
                    </>

                }
                <div>
                    {
                        ((carDetails?.license_status === k_license_status?.expire_warning) && !carDetails?.renew_license_start) ||
                            carDetails?.license_status === k_license_status?.expired ?
                            <CommonButton
                                onClick={() => {
                                    setCarLicenseRenewID(carDetails?.license_id);
                                    setNoLicenseApply(false);
                                    navigateTo(car_manager_path.license_edit);
                                }}
                                btnLabel={t('Renew License')}
                                width='w-[150px]'
                            />
                            : carDetails?.license_status === k_license_status?.no_license ?
                                <CommonButton
                                    onClick={() => {
                                        setCarLicenseRenewID(carDetails?.license_id);
                                        setNoLicenseApply(false);
                                        navigateTo(car_manager_path.license_edit)
                                    }}
                                    btnLabel={t('Purchase License')}
                                    width='w-[150px]'
                                />
                                : carDetails?.license_status === k_license_status?.rejected ?
                                    <CommonButton
                                        onClick={() => {
                                            setCarLicenseRenewID(carDetails?.license_id);
                                            setNoLicenseApply(false);
                                            navigateTo(car_manager_path.license_edit)
                                        }}
                                        btnLabel={t('Apply Again')}
                                        width='w-[150px]'
                                    />
                                    : null
                    }
                </div>
            </div>
        </div>
    )
}

export default CarDetailsTopBar