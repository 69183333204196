import React from 'react'
import CommonModal from '../../../../components/modal/CommonModal'
import { useTranslation } from 'react-i18next';
import useRequestStore from '../../../../app/stores/others/requestStore';
import Image from '../../../../components/image/Image';
import { iChatGreen } from '../../../../app/utility/imageImports';
import useGeneralStore, { getAllUserInfo } from '../../../../app/stores/others/generalStore';
import { useNavigate } from 'react-router-dom';
import { commonSelectedChat } from '../../../../app/stores/others/chatStore';
import { chat_role, user_role as role } from '../../../../app/utility/const';

const OnGoingChatModal = () => {

    const { t } = useTranslation();

    const { showOnGoingChatModal, setShowOOnGoingChatModal, request_details } = useRequestStore();

    const { user_role } = useGeneralStore();

    const navigateTo = useNavigate();

    return (
        <div>
            <CommonModal
                showModal={showOnGoingChatModal}
                setShowModal={setShowOOnGoingChatModal}
                modalTitle={t("Chat with")}
                widthClass='w-[45vw]'
                mainContent={
                    <div className='mt-5'>
                        {request_details?.shift_derivers?.length > 0 ? (
                            <div className='space-y-4'>
                                {request_details?.shift_derivers?.map((item, index) => (
                                    <DriverChatInfo
                                        onClick={async () => {
                                            const res = await getAllUserInfo(item?.driver_user_id);
                                            if (res?.success) {
                                                await commonSelectedChat(res?.data, user_role === role.customer ? chat_role.customer : chat_role.company);
                                                setShowOOnGoingChatModal(false)
                                                navigateTo("/chat");
                                            }
                                        }}
                                        key={index} driver={item?.driver} />
                                ))}
                            </div>
                        ) : (
                            <DriverChatInfo
                                onClick={async () => {
                                    const res = await getAllUserInfo(request_details?.driver?.details.id);
                                    if (res?.success) {
                                        await commonSelectedChat(res?.data, user_role === role.customer ? chat_role.customer : chat_role.company);
                                        setShowOOnGoingChatModal(false)
                                        navigateTo("/chat");
                                    }
                                }} driver={request_details?.driver?.details} />
                        )}
                    </div>
                }
            />
        </div>
    )
}

export default OnGoingChatModal

const DriverChatInfo = ({ driver, onClick }) => {

    const { t } = useTranslation();

    return (
        <div onClick={onClick}
            className='flex justify-between cursor-pointer'>
            <div className='flex space-x-2'>
                <Image className="rounded-full max-w-[44px] min-w-[44px] min-h-[44px] max-h-[44px]" src={driver?.image} alt='' />
                <div className='flex flex-col justify-center '>
                    <div className='font-medium text-fs16 text-cGray800'>{driver?.name}</div>
                    <div className='font-normal text-fs14 mt-s2 text-cGray400'>{t("Driver")}</div>
                </div>
            </div>
            <div className='flex items-center justify-center'>
                <img src={iChatGreen} alt="" />
            </div>
        </div>
    )
}
