import * as React from 'react';
import { iDownArrowBlack, iDownArrowWhite } from '../../app/utility/imageImports';
import useCreateRequestStore from '../../app/stores/others/createRequestStore';


const ActionButton = ({
    label = "Action Button",
    width = "w-[180px]",
    dataArray = [
        { title: "one", action: () => { console.log("one clicked !"); } },
        { title: "two", action: () => { console.log("two clicked !"); } },
        { title: "three", action: () => { console.log("three clicked !"); } },
        { title: "four", action: () => { console.log("four clicked !"); } },
    ],
}) => {

    const [open, setOpen] = React.useState(false);
    const [panelHeight, setPanelHeight] = React.useState(100);
    const { setIsResolve } = useCreateRequestStore();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        setPanelHeight(44 * (dataArray?.length + 1))
    }, [dataArray]);

    return (
        <div
            tabIndex={0}
            onBlur={handleClose}
            onClick={handleOpen}
            className={`
                 bg-cBrand rounded-t-md relative cursor-pointer flex items-center justify-center
                ${width} max-h-[40px] min-h-[40px]
                ${open ? '' : "rounded-b-md"}`}>
            <div
                className="relative flex items-center justify-center capitalize duration-300 rounded-md cursor-pointer select-none font-fw500 text-fs16">
                <div className='mr-1 font-medium text-white text-fs16 '>{label}</div>
                <img src={iDownArrowWhite} alt="" className={`w-s16 transition-all duration-300 ease-in-out ${open ? 'rotate-180' : 'rotate-0'}`} />
            </div>

            {open ? <div className={`absolute h-[${(panelHeight - 44)}px] top-[36px] right-[0px] z-[10002] ${width} bg-white border-2 border-cBrand shadow-lg rounded-b-md overflow-hidden`} >
                {dataArray?.map((item, index) => (

                    <div key={index} className='w-full space-y-5 text-center hover:bg-gray-100'>
                        {item?.title && <div
                            onClick={
                                () => {
                                    setIsResolve(false)
                                    handleClose();
                                    item?.action();
                                }}
                            className='w-full cursor-pointer'>
                            <div className='w-full py-3'>{item?.title}</div>
                        </div>}
                    </div>
                ))}
            </div> : ""}

        </div>

    );
}
export default ActionButton

