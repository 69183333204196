import React from 'react'
import { useNavigate } from 'react-router-dom';
import { requestAction, deleteRequest } from '../../../../../../../../app/stores/others/requestStore';
import CommonButton from '../../../../../../../../components/button/CommonButton';
import CommonModal from '../../../../../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import { k_request_paths } from '../../../../../../../../app/utility/const';

export default function RequestActionsModal({ showModal, setShowModal, title, sub_title, request_id, action }) {
  const navigateTo = useNavigate();
  // const [comment, setComment] = React.useState('done');
  // const { request_details } = useRequestStore();
  const { t } = useTranslation();

  // const submitForm = async (e) => {
  //   e.preventDefault();
  //   const success = await requestAction(request_id, action, comment);
  //   if (success) setShowModal(false);
  //   navigateTo(-1);
  //   setComment('');
  // }

  return (
    <CommonModal
      showModal={showModal}
      setShowModal={setShowModal}
      modalTitle={t(title)}
      widthClass="w-[50vw]"
      mainContent={
        <>
          {/* <div className='mt-s20 pb-s10'>{sub_title}</div>
          <form onSubmit={submitForm}>

            {(action === 'delete' && request_details?.start_in > 2) ? <></> : <CommonInput className={'my-3'} name={'comment'} value={comment} onChange={(e) => setComment(e.target.value)} labelText={t('Reason')} textarea={true} type={'text'} max_input={255} required={true} show_asterisk={false} />}

            <div className='flex justify-end mt-s30'>
              <CommonButton onClick={async () => {

              }}
                btnLabel={t('Confirm')}
                colorType='danger'
                width='w-[100px]'
                type='submit'
              />

            </div>

          </form> */}


          <div className='mt-s20 pb-s10'>
            {
              action === "cancel" ?
                <div class="text-cGray80 text-fs16">
                  <p>{t("Are you sure you want to cancel this request? By doing so, the following will occur")}:</p>
                  <ul class="list-disc ml-5">
                    <li>{t("The request will move to 'In Bidding'.")}</li>
                    <li>{t("Any awards to companies will be cancelled.")}</li>
                  </ul>
                  <p>{t("You will need to update your pickup or delivery date to receive new bids from other companies.")}</p>
                </div>
                : sub_title
            }
          </div>
          <div className='flex justify-end mt-s30'>
            <CommonButton onClick={
              async () => {

                let success
                if (action === 'delete') {
                  success = await deleteRequest(request_id);
                } else {
                  success = await requestAction(request_id, action, "done");
                }
                if (success) {
                  setShowModal(false);
                  if (action === "cancel") {
                    navigateTo(k_request_paths.in_bidding);
                  } else {
                    navigateTo(-1);
                  }
                }
              }}
              btnLabel={t('Confirm')}
              colorType='danger'
              width='w-[100px]'
              type='submit'
            />

          </div>
        </>
      }
    />
  )
}
