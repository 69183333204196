/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import { user_role as role, user_role } from "../../../../../../../app/utility/const";
import {
  iBorderedImageUploadIcon,
  iCloseIcon
} from "../../../../../../../app/utility/imageImports";
import {
  addMinutesToTime,
  areDatesEqual,
  compareTime,
  formatDateToApiDate,
  handleInputChange,
  isWhitespaceOnly
} from "../../../../../../../app/utility/utilityFunctions";
import ImageBtn from "../../../../../../../components/image/ImageBtn";
import ImageUpload from "../../../../../../../components/imageUpload/ImageUpload";
import AddressAutoComplete from "../../../../../../../components/input/AddressAutoComplete";
import CommonDatePicker from "../../../../../../../components/input/CommonDatePicker";
import CommonInput from "../../../../../../../components/input/CommonInput";
import CommonTimePicker from "../../../../../../../components/input/CommonTimePicker";

const DeliveryForm = ({
  lastItemRequiredField = [],
  setLastItemRequiredField,
}) => {
  const [doSearch, setDoSearch] = useState(false);
  const [isInvalidAddress, setIsInvalidAddress] = useState(false);

  const {
    isInvalidProductFromFrom,
    cr_form,
    stopData,
    setStopData,
    resetStopData,
    updateStopField,
    updateStopProductField
  } = useCreateRequestStore();
  const { t } = useTranslation();

  const isClearableStopData =
    !!stopData.date ||
    !!stopData.start_time ||
    !!stopData?.duration ||
    !!stopData.end_time ||
    !!stopData.delivery_time ||
    !!stopData.floor_number ||
    !!stopData?.address ||
    !!stopData.comment ||
    !!stopData.attachment ||
    stopData?.products.length > 1
  // || !!stopData.products.filter((item) => item.text).length;

  useEffect(() => {
    return () => {
      // if (isClearableStopData) resetStopData();
    };
  }, []);

  const updateAddress = (address, lat, lng, floor_number) => {
    if (address && lat && lng && floor_number) {
      setStopData({
        ...stopData,
        address: address,
        lat: lat,
        lng: lng,
        floor_number: floor_number,
      });
    }
    else if (address) setStopData({ ...stopData, address: address })
    else if (lat) setStopData({ ...stopData, lat: lat })
    else if (lng) setStopData({ ...stopData, lng: lng })


  };

  const removeDeliveryProduct = (productIndex) => {

    setStopData({ ...stopData, products: stopData?.products.filter((i, index) => index !== productIndex) });
  };

  const addDeliveryProduct = () => {
    let newProducts = stopData.products;
    setStopData({ ...stopData, products: [...newProducts, { text: "Product" }] });
  };

  const handleBlurEvents = (type, event) => {
    switch (type) {
      case "address":
        if (lastItemRequiredField.includes("address")) {
          setLastItemRequiredField((prev) =>
            prev.filter((field) => field !== "address")
          );
        }
        return;
      case "date":
        if (lastItemRequiredField.includes("date")) {
          setLastItemRequiredField((prev) =>
            prev.filter((field) => field !== "date")
          );
        }
        return;
      case "end_time":
        if (lastItemRequiredField.includes("end_time")) {
          setLastItemRequiredField((prev) =>
            prev.filter((field) => field !== "end_time")
          );
        }

        return;
      case "time":
        if (lastItemRequiredField.includes("start_time")) {
          setLastItemRequiredField((prev) =>
            prev.filter(
              (field) => field !== "start_time" && field !== "end_time"
            )
          );
        }
        return;

      case "product":
        if (lastItemRequiredField.includes("product")) {
          if (
            stopData.products.filter((item) => !!item.text).length ===
            stopData.products.length
          )
            setLastItemRequiredField((prev) =>
              prev.filter((field) => field !== "product")
            );
        }
        return;
      default:
        return;
    }
  };


  const isValidAddress = () => {
    if (stopData?.address && stopData?.lat && stopData?.lng) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    setIsInvalidAddress(!isValidAddress())
  }, [stopData]);
  return (
    <div
      onClick={() => {
        console.log("isClearableStopData : ", isClearableStopData);
        console.log("stopData : ", stopData);

      }}
    >
      <div className="flex flex-row justify-start gap-4">
      </div>
      <div className="grid grid-cols-2 gap-x-6">
        <AddressAutoComplete
          className="w-full"
          required={true}
          label={t("Delivery Address")}
          name={"address"}
          address={stopData?.address ?? ""}
          latName={"lat"}
          lngName={"lng"}
          onBlurEvent={() => handleBlurEvents("address")}
          changeValue={(name, value) => {
            console.log("changeValue: ", name, value);
            if (name === "address") {
              updateStopField("address", value);
            } else if (name === "lat") {
              updateStopField("lat", value?.toString());
            } else if (name === "lng") {
              updateStopField("lng", value?.toString());
            }
          }}
          // isInvalid={isClearableStopData && lastItemRequiredField.includes("address")}
          isInvalid={(stopData?.address && isInvalidAddress) || lastItemRequiredField?.includes("address") }
          doSearch={doSearch}
          setDoSearch={setDoSearch}
          onFavAddressModalItemClick={(address) => {
            updateAddress(
              address.address,
              address.lat,
              address.lng,
              address.floor_number
            );
            handleBlurEvents("address");
          }}
        />

        <CommonInput
          max_input={55}
          type="text"
          labelText={t("Floor Number")}
          name={"floor_number"}
          value={stopData?.floor_number ?? ""}
          onChange={handleInputChange(updateStopField, "floor_number")}
        />
      </div>

      {/* Delivery date and time */}
      {
        useGeneralStore.getState().user_role === role.company && (
          <div className="grid grid-cols-4 mt-5 gap-x-6">
            <div className="w-full">
              <CommonDatePicker
                startDate={cr_form?.pickup?.date ? cr_form?.pickup?.date : new Date()}
                required={false}
                allowPastDate={true}
                shouldUpdateValue={true}
                label={t("Date")}
                name="formatted_date"
                value={stopData?.date}
                onChange={(date) => {
                  updateStopField("date", formatDateToApiDate(date));
                  updateStopField(
                    "start_time",
                    ""
                  );
                  updateStopField("end_time", "");
                }}
                onCloseEvent={() => handleBlurEvents("date")}
              />
            </div>

            <div
              className="w-full">
              <CommonTimePicker
                showExtendedTimeUi={false}
                required={stopData?.date ? true : false}
                label={t("Time")}
                marginClass="mt-s12"
                disabled={stopData?.date ? false : true}

                // isCurrentTimeValidation={new Date(stopData?.date) === new Date()}
                isCustomMinTimeValidation={(cr_form?.pickup?.date === stopData?.date) && cr_form?.pickup?.end_time ? true : false}
                customMinTimeValue={addMinutesToTime(cr_form?.pickup?.start_time, 15)}
                init_date={cr_form?.pickup?.date}

                init_time={stopData?.start_time ?? addMinutesToTime(cr_form?.pickup?.start_time, 15)}
                // init_time={stopData?.start_time ?? addMinutesToTime(cr_form?.pickup?.end_time, 15)}

                end_time={stopData?.end_time ?? cr_form?.pickup?.end_time}

                heightClass=""
                name="start_time"
                onChange={(start_time, end_time) => {

                  updateStopField(
                    "start_time",
                    start_time
                  );
                  updateStopField("end_time", end_time);
                  handleBlurEvents("time");
                }}
              />
            </div>

            <div className="w-full">
              <CommonTimePicker
                showExtendedTimeUi={false}
                marginClass="mt-s12"
                required={stopData?.start_time ? true : false}
                label={t("Range")}

                isCustomMinTimeValidation={stopData?.start_time ? true : false}
                customMinTimeValue={addMinutesToTime(stopData?.start_time, 15) ?? ""}
                init_date={stopData?.date}

                disabled={stopData?.start_time ? false : true}
                init_time={stopData?.end_time}
                end_time={stopData?.end_time}
                heightClass=""
                name="start_time"
                onChange={(start_time, end_time) => {
                  updateStopField("end_time", start_time);
                  handleBlurEvents("end_time");
                }}
              />
            </div>
            <div className="w-full">
              <CommonInput
                max_input={5}
                type="number"
                labelText={t("Duration (min)")}
                parentClassName={"mt-s2"}
                name={"duration"}
                value={stopData?.duration ?? ""}
                onChange={(e) => {
                  updateStopField(
                    "duration",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
        )
      }

      {
        stopData?.products?.map((productItem, productIndex) => (
          <div
            className={`relative -mt-[${user_role === role.company ? "" : ""}]`}
            key={productIndex}
          >
            <CommonInput
              isInvalid={isInvalidProductFromFrom && (productItem?.text === "" || productItem?.text === null)}
              valuePaddingRight="28px"
              required={false}
              labelText={t("Product")}
              parentClassName={"!mt-5"}
              max_input={100}
              name={"product"}
              value={stopData?.products[productIndex]?.text === "Product" ? "" : stopData?.products[productIndex]?.text ?? ""}
              onChange={(e) => {
                if (e.target.value.trim() === "")
                  updateStopProductField(
                    productIndex,
                    "text",
                    "Product",
                  );
                else
                  updateStopProductField(
                    productIndex,
                    "text",
                    e.target.value,
                  );
              }}
              onBlurEvent={(e) => handleBlurEvents("product", e)}
              autoFocus={productIndex === stopData?.products?.length - 1}
            />
            {stopData?.products?.length > 1 && (
              <div className="absolute right-0 top-3">
                <ImageBtn
                  hoverImageSrc={iCloseIcon}
                  imageSrc={iCloseIcon}
                  hasHoverAction={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeDeliveryProduct(productIndex)
                  }}
                  showTooltip={false}
                  hoverSize="w-7 h-7"
                  iconSize="max-w-[16px] min-w-[16px] min-h-[16px] max-h-[16px]"
                  tooltipMessage={t("Clear")}
                />
              </div>
            )}
          </div>
        ))}

      <AddProduct onClick={() => {
        addDeliveryProduct()
        // const lastProductInputText = stopData?.products[stopData?.products.length - 1].text ?? ""
        // console.log(" lastProductInputText : ", lastProductInputText);
        // if (!lastProductInputText) Toastr({ "message": t(t("Please enter a product name.")), type: 'warning' });

        // const p = stopData?.products?.filter((i) => i?.text === "" || i?.text === null)
        // if (p?.length > 0) {
        //   setIsInvalidProductFrom(true)
        // } else {
        //   isInvalidProductFromFrom === true && setIsInvalidProductFrom(false)
        //   addDeliveryProduct()
        // }
      }} />

      <div className="mt-4 mb-5">
        <CommonInput
          labelText={t("Comment")}
          name={"comment"}
          value={isWhitespaceOnly(stopData?.comment) ? "" : stopData?.comment ?? ""}
          onChange={handleInputChange(updateStopField, "comment")}
          parentClassName={"mt-5"}
          textarea={true}
          max={255}
          max_input={255}
        />
      </div>


      <ImageUpload
        setImage={(value) => {
          updateStopField("attachment", value);
        }}
        image_url={stopData?.attachment}
        src={stopData?.attachment}
        setSrc={(value) => {
          updateStopField("attachment", value);
        }}
        title={t("Add Attachment")}
        imageUploadIcon={iBorderedImageUploadIcon}
        addButtonClassName="border border-dashed border-cGray300 p-s10 w-max"
        width="w-max"
        showInfoText={true}
        textStyle="text-fs14 font-fw400 text-cBrand leading-[25px]"
      />
    </div>
  );
};

export default DeliveryForm;

const AddProduct = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={onClick}
      className="flex flex-row justify-start mt-5 space-x-2 cursor-pointer w-max"
    >
      {/* <img src={iPlus} alt="" /> */}
      <div className="text-cBrand text-fs16 font-fw500">{t("Add Product")}</div>
    </div>
  );
};
