/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import CommonModal from '../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import useTrackTraceStore, { getTrackAndTrace, resetTrackAndTrace } from '../../../../app/stores/others/trackTraceStore';
import CommonDatePicker from '../../../../components/input/CommonDatePicker';
import CommonButton from '../../../../components/button/CommonButton';
import CommonSelect from '../../../../components/select/CommonSelect';
import useCreateRequestStore, { getInitData } from '../../../../app/stores/others/createRequestStore';
import { k_request_types, k_requests_status, user_role as role } from '../../../../app/utility/const';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import useDriverStore, { getDriversTableData } from '../../../../app/stores/company/driverStore';
import { formatDateToApiDate, Toastr } from '../../../../app/utility/utilityFunctions';
import { kuTrackAndTraceList } from '../../../../app/urls/commonUrl';

const TrackTraceFilterModal = () => {

    const { t } = useTranslation();

    const { searchKey, setCopyFilterForm, showTraceFilterModal, setShowTraceFilterModal, filterForm, setFilterForm, setApiUrl } = useTrackTraceStore();

    const { type_of_transportation } = useCreateRequestStore();

    const { driver_table_data, setTake } = useDriverStore();

    const { user_role } = useGeneralStore();

    useEffect(() => {
        fetchRelatedData();
    }, [showTraceFilterModal])

    const fetchRelatedData = async () => {
        if (showTraceFilterModal) {
            if (type_of_transportation?.length === 0) await getInitData();
            if (user_role === role.company) {
                setTake(100);
                await getDriversTableData(false);
            }
        }
    }

    const handleInitialSetup = async () => {
        await getTrackAndTrace({
            isLoading: true,
            ...filterForm
        });
    }

    const isDisabled = !(filterForm?.status || filterForm?.type || filterForm?.date || filterForm?.driver_id || filterForm?.pick_up_date_from || filterForm?.pick_up_date_to);

    const handleSubmitFilter = (e) => {
        if (!isDisabled) {
            e.preventDefault();
            setApiUrl(kuTrackAndTraceList);
            setCopyFilterForm(filterForm);
            handleInitialSetup();
            setShowTraceFilterModal(false);
        } else {
            e.preventDefault();
            Toastr({ message: t("Please fill at least one field!"), type: "warning" })
        }
    }

    const handleClear = async () => {
        resetTrackAndTrace(false);
        setApiUrl(kuTrackAndTraceList);
        if (searchKey) {
            getTrackAndTrace({
                isLoading: true,
                status: "",
                type: "",
                date: "",
                driver_id: "",
                pick_up_date_from: "",
                pick_up_date_to: "",
            });
        }
        setShowTraceFilterModal(false);
    }
    const driverList = driver_table_data?.data?.map(item => ({ value: item.user_id, title: item.name }));


    return (
        <div>
            <CommonModal
                showModal={showTraceFilterModal}
                setShowModal={setShowTraceFilterModal}
                iAutoFocus={false}
                modalTitle={
                    <div className='flex items-baseline'>
                        <div>{t('Filter')}</div>
                        <div className='pl-4 text-base cursor-pointer select-none drop-shadow-sm text-cRed'
                            onClick={handleClear}>
                            {t("Clear")}
                        </div>
                    </div>
                }

                mainContent={
                    <>
                        <form
                            onSubmit={handleSubmitFilter}
                            className='relative' >
                            <input className='absolute h-0 outline-none caret-transparent' />
                            <div className="grid items-center grid-cols-2 mt-3 gap-x-7">
                                <CommonSelect
                                    label={t('Type')}
                                    dataArray={type_of_transportation}
                                    has_subtitle={false}
                                    onChange={(e, value) => { setFilterForm({ ...filterForm, type: value }) }}
                                    value={filterForm?.type ?? ''}
                                />
                                <CommonSelect
                                    className="capitalize"
                                    label={t('Status')}
                                    dataArray={
                                        user_role === role.company ?
                                            [
                                                { value: k_requests_status.invitation, title: k_request_types.invitation },
                                                { value: k_requests_status.in_bidding, title: k_request_types.in_bidding },
                                                { value: k_requests_status.not_planned, title: k_request_types.not_planned },
                                                { value: k_requests_status.awarded, title: k_request_types.upcoming, },
                                                { value: k_requests_status.ongoing, title: k_request_types.ongoing },
                                                { value: k_requests_status.completed, title: k_request_types.completed },
                                            ]
                                            :
                                            [
                                                { value: k_requests_status.in_bidding, title: k_request_types.in_bidding },
                                                { value: k_requests_status.awarded, title: k_request_types.upcoming, },
                                                { value: k_requests_status.ongoing, title: k_request_types.ongoing },
                                            ]
                                    }
                                    has_subtitle={false}
                                    onChange={(e, value) => {
                                        setFilterForm({ ...filterForm, status: e.target.value })
                                    }}
                                    value={filterForm?.status}
                                />
                            </div>


                            <div className="grid items-center grid-cols-2 mt-7 gap-7">
                                <CommonDatePicker
                                    label={t('Date From')}
                                    value={filterForm?.pick_up_date_from}
                                    onChange={(e) => {
                                        if (new Date(e) > new Date(filterForm?.pick_up_date_to)) {
                                            setFilterForm({ ...filterForm, pick_up_date_from: formatDateToApiDate(e), pick_up_date_to: "" })
                                        } else {
                                            setFilterForm({ ...filterForm, pick_up_date_from: formatDateToApiDate(e) })
                                        }
                                    }}
                                />

                                <CommonDatePicker
                                    startDate={filterForm?.pick_up_date_from}
                                    label={t('Date To')}
                                    value={filterForm?.pick_up_date_to}
                                    onChange={(e) => { setFilterForm({ ...filterForm, pick_up_date_to: formatDateToApiDate(e) }) }}
                                />
                            </div>


                            <div className={`${user_role === role.company && "grid items-center grid-cols-2 mt-s10 gap-x-7"}`}>
                                {
                                    user_role === role.company && <div className='mt-2'>
                                        <CommonSelect
                                            noDataText={t('No Driver Found!')}
                                            dataArray={driverList}
                                            label={t('Driver')}
                                            has_subtitle={false}
                                            onChange={(e, value) => {
                                                setFilterForm({ ...filterForm, driver_id: e.target.value })
                                            }}
                                            value={filterForm?.driver_id}
                                        />
                                    </div>
                                }
                            </div>

                            <div className='flex flex-row items-center justify-end w-full mt-5'>
                                <CommonButton
                                    btnLabel={t('Apply Filter')}
                                    type='submit'
                                />
                            </div>

                        </form>
                    </>
                }
            />
        </div>
    );
};

export default TrackTraceFilterModal;