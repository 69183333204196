import React from 'react'
import { useTranslation } from 'react-i18next';
import CommonTable from '../../../../components/table/CommonTable';
import { formatDate } from '../../../../app/utility/utilityFunctions';
import Clamp from "react-multiline-clamp";
import { useNavigate } from 'react-router-dom'
import { k_request_paths, k_shift_section } from '../../../../app/utility/const';
import useShiftStore from '../../../../app/stores/company/shiftStore';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import { useParams } from 'react-router-dom'
import RequestAccordion from '../../../../components/Accordion/RequestAccordion';
import useCreateRequestStore from '../../../../app/stores/others/createRequestStore';
import { Tooltip } from 'antd';

const RequestList = () => {

    const { t } = useTranslation();

    const { shiftDetailsData } = useShiftStore();

    const { shift_id } = useParams();

    const { setRequestDetailsPath } = useGeneralStore();

    const { shiftExpandedAccordion, setShiftExpandedAccordion } = useCreateRequestStore();

    const navigateTo = useNavigate();

    const headers = [
        { name: "Req. ID" },
        { isActive: true, sort: 1, name: t("Title") },
        { isActive: true, sort: 1, name: t("Load") },
        { isActive: true, sort: 1, name: t("Schedule") },
    ]
    const isShiftDetails = window.location.pathname.includes('shift-manager')

    const handleNavigateTo = (data) => {
        setRequestDetailsPath(`/shift-manager/details/${shift_id}`)
        if (data?.status === 'invitation') {
            navigateTo(k_request_paths.saved + `/details/${data?.id}`);

        } else if (data?.status === 'in_bidding') {
            navigateTo(k_request_paths.in_bidding + `/details/${data?.id}`);
        } else if (data?.status === 'awarded') {
            navigateTo(k_request_paths.awarded + `/details/${data?.id}`);
        }
        else if (data?.status === 'ongoing') {
            navigateTo(k_request_paths.ongoing + `/details/${data?.id}`);
        } else if (data?.status === 'complete') {
            navigateTo(k_request_paths.completed + `/details/${data?.id}`);
        } else if (data?.status === 'history') {
            navigateTo(k_request_paths.history + `/details/${data?.id}`);
        } else navigateTo(k_request_paths.saved + `/details/${data?.id}`);
    }

    const contents = shiftDetailsData?.reqs?.map((item, index) => {
        return {
            contents: [
                {value: item?.request_id}, 
                {value: <Clamp withTooltip lines={1}>{item?.title ?? ''}</Clamp>}, 
                {value: <Tooltip title={t("Request | Stops | Packages")} color={"#242826"}>{`1 | ${item?.stops_count ?? 0} | ${item?.products_count ?? 0}`}</Tooltip>}, 
                {value: `${item?.pickup_starts_date ? formatDate(item?.pickup_starts_date, true) ?? ' ' : ' ' + (item?.pickup_starts_time ?? " ")}${item?.pickup_end_time ? " - " + item?.pickup_end_time : " "}`}, 
            ],
            clickAction: () => {
                if (isShiftDetails) {
                    // handleNavigateTo(item)
                }
            }
        }
    })

    return (
        <RequestAccordion
            showClearButton={false}
            title={t("Request List")}
            isTable={true}
            isBorder={false}
            expandedPaddingWidth="p-0"
            showShift={true}
            showBorder={true}
            showBackgroundColor={false}
            isExpanded={shiftExpandedAccordion === k_shift_section.request_list}
            subTitleOne={`${shiftDetailsData?.reqs?.length ?? 0} ${t("Requests")}`}
            onExpand={() => { setShiftExpandedAccordion(shiftExpandedAccordion === k_shift_section.request_list ? null : k_shift_section.request_list) }}
        >
            <CommonTable
                firstIndexColWidth="w-[14%]"
                headersFirstIndexFixApply={false}
                firstIndexColPosition='items-start justify-start'
                tableTitle={""}
                tableHeaderToPaddingClassName="mb-s7"
                showSearchBox={false}
                showTopRightFilter={false}
                showTakeOption={false}
                sort={false}
                showPagination={false}
                showPageCountText={true}
                headers={headers}
                tableHeaders={headers}
                tableContents={contents}
                hasClickFunction={true}
                widths="90px auto 120px 25%"
                outerPadding='p-s0'
                topRightFilterComponentOnClick={() => { }}
                paginationObject={{}}
                withClearSearch={false}
                onSearchClear={() => { }}
                searchValue={""}
                tableBorderRadius="!rounded-[4px]"
                searchOnChange={(e) => { }}
                currentTakeAmount={10}
                withReloader={true}
                onReload={() => { }}
                filtered={false}
                takeOptionOnChange={async (e) => { }}
                paginationOnClick={async (url) => { }}
                showCommonEmptyData={false}
            />
        </RequestAccordion>
    )
}

export default RequestList