import React from 'react'
import CommonModal from '../../../../../components/modal/CommonModal'
import useShiftStore from '../../../../../app/stores/company/shiftStore'
import CommonViewComponent from '../../../../../components/viewer/CommonViewComponent';
import ImageViewer from '../../../../../components/image/ImageViewer';
import RequestDetailsTextTitle from '../../../../common/requests/requestDetails/components/mainContents/components/RequestDetailsTextTitle';
import { formatTime } from '../../../../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';
import { k_shift_stop_status } from '../../../../../app/utility/const';

const CustomStopDetails = () => {
    const { t } = useTranslation();
    const { showCustomStopModal, setShowCustomStopModal, customStopModalData } = useShiftStore();
    const defineStatus = (status) => {
        if (status === k_shift_stop_status.init) return 'Not Started';
        if (status === k_shift_stop_status.hold) return 'On Hold';
        if (status === k_shift_stop_status.not_delivered) return 'Not Delivered';
        else return status;
    }

    const isShowDriverComment = (status) => {
        if (status === k_shift_stop_status.delivered || status === k_shift_stop_status.complete || status === k_shift_stop_status.not_delivered)
            return true;
        else return false;
    }

    return (
        <CommonModal
            showModal={showCustomStopModal}
            setShowModal={setShowCustomStopModal}
            modalTitle={t('Custom Stop Details')}
            mainContent={
                <div
                    onClick={() => { console.log('customStopModalData: ', customStopModalData); }}
                    className='pt-5 space-y-5'>
                    <CommonViewComponent capitalizedData={true} labelText={t('Status')}
                        value={defineStatus(customStopModalData?.status) ?? " "} />
                    <CommonViewComponent capitalizedData={true} labelText={t('Stop Type')} value={'Custom'} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Title')} value={customStopModalData?.title ?? " "} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Time Range')} value={(formatTime(customStopModalData?.start_time) ?? "--") + ' - ' + formatTime(customStopModalData?.end_time) ?? "--"} />
                    {customStopModalData?.status === k_shift_stop_status.init ? <></> : <CommonViewComponent capitalizedData={false} labelText={t('Comment')} value={customStopModalData?.comment ?? " "} />}
                    {customStopModalData?.attachment ?
                        <ImageViewer src={customStopModalData?.attachment} label={t('Attachment')} />
                        : ''
                    }

                    <RequestDetailsTextTitle title={t(`Custom Stop Overview`)} className={'text-fs16 font-fw500'} />

                    {isShowDriverComment(customStopModalData?.status) && <CommonViewComponent
                        labelText={t('Driver Comment')}
                        value={customStopModalData?.driver_comment ?? 'NA'}
                        className='my-[20px]'
                    />}

                    <div className='flex flex-row items-center justify-start space-x-5 my-s20'>

                        {customStopModalData?.driver_attachment && <ImageViewer src={customStopModalData?.driver_attachment} label={t('Attachment')} withExtension={true}/>}
                        {customStopModalData?.driver_signature && <ImageViewer src={customStopModalData?.driver_signature} label={t('Signature')} is_signature={true} />}

                    </div>
                </div>
            }
        />
    )
}

export default CustomStopDetails