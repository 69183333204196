import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { formatSearchAddressV2 } from "../../app/stores/others/generalStore";
import { kuSearchAddressLatLang, kuSearchAddressSuggestion } from "../../app/urls/commonUrl";
import Clamp from "react-multiline-clamp";

// Author:: coded by tamzidpeace(arafat) on 15/09/2022 for favorite address & profile sections
function MassImportAddressAutoComplete({
  name,
  address = '',
  latName,
  lngName,
  doSearch,
  setDoSearch,
  changeValue,
  width,
  is_valid,
  city,
}) {

  const [key] = useDebounce(address, 300); // 0.3 sec delay/debounce
  const [items, setItems] = useState([]); // address suggestion items
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [onChangeAddress, setOnChangeAddress] = useState(false);


  // show suggestions if user is typing and get any result [1 sec debounce]. for debounce, after selecting any suggestion,
  // it will not show suggestions again.
  useEffect(() => {
    if (doSearch & showSuggestion) {
      searchAddress(key, doSearch);
    } else {
      setDoSearch(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, onChangeAddress]);

  useEffect(() => {
    if (items?.length > 0) {
      setShowSuggestion(true);
      setSelectedIndex(null);
    } else {
      setShowSuggestion(false);
      setSelectedIndex(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  // check user is type or not
  const handleOnChangeAddress = (e) => {
    changeValue(e.target.name, e.target.value);
    searchLatLng(e.target.value);
    setOnChangeAddress(true);
  };

  // select address from suggestion list on mouse click
  const handleSelectItem = async (item) => {
    // setCompanyCity(item?.address?.city)
    changeValue(name, item.title);
    setShowSuggestion(false);
    setDoSearch(false);
    await searchLatLng(item.title);
  };

  const setLatLng = (position, address) => {
    if (position === null) {
      changeValue(latName, null);
      changeValue(lngName, null);
      changeValue(city, null);
    } else {
      changeValue(latName, position.lat);
      changeValue(lngName, position.lng);
      changeValue(city, address.city);
    }
  };

  // keyboard up/down/enter key event handle
  document.onkeydown = function (e) {
    switch (e.key) {
      case "ArrowUp":
        if (selectedIndex === 0) return;
        setSelectedIndex(selectedIndex - 1);
        // stop scrolling while arrow key is pressed
        document.body.classList.add("no-scroll");
        break;
      case "ArrowDown":
        if (selectedIndex === items.length - 1) return;
        setSelectedIndex(selectedIndex === null ? 0 : selectedIndex + 1);
        // stop scrolling while arrow key is pressed
        document.body.classList.add("no-scroll");
        break;
      case "Enter":
        e.preventDefault()
        // if (items?.length === 0) return;
        // changeValue(name, items[selectedIndex]?.title);
        // setShowSuggestion(false);
        // setDoSearch(false);
        // searchLatLng(items[selectedIndex].title);
        break;
      case "Escape":
        setShowSuggestion(false);
        setDoSearch(false);
        break;
      default:
        return 0;
    }
  };

  // handle mouse click outside suggestion list
  document.onclick = function (e) {
    setShowSuggestion(false);
    setDoSearch(false);
  };

  // api functions
  async function searchAddress(queryText, doSearch, softSearchMode = false) {
    if (!queryText || queryText === null || doSearch === false) return;
    if (!softSearchMode) setLatLng(null); // reset lat lng
    setIsSearching(true);
    return await fetch(
      kuSearchAddressSuggestion +
      queryText
      // + "&maxresults=5&in=countryCode:BGD,DNK"
    )
      .then((response) => response.json())
      .then((json) => {
        console.log("searchAddress::::", json?.items);

        setItems(formatSearchAddressV2(json.items));
        setIsSearching(false);
      });
  }

  async function searchLatLng(locationID, failSafeMode = true, address = "") {
    return await fetch(
      kuSearchAddressLatLang +
      locationID
    )
      .then((response) => response.json())
      .then((json) => {
        try {
          console.log("searchLatLng::::", json?.items[0]);

          const position = json.items[0].position;
          if (failSafeMode) {
            if (locationID === json?.items[0]?.title)
              setLatLng(position, json?.items[0]?.address);
          } else setLatLng(position, json?.items[0]?.address);
        } catch (error) {
          setLatLng(null);
        }
      });
  }

  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      const firstLine = textareaRef.current.querySelector('div:first-child');
      if (firstLine) {
        firstLine.classList.add('py-2');
      }
    }
  }, []);

  const handleDivClick = () => {
    setIsEditing(true);
    if (textareaRef.current) textareaRef.current.focus();
  };

  const handleTextareaBlur = () => {
    setIsEditing(false);
    setTimeout(() => {
      setShowSuggestion(false);
    }, 700);
  };

  useEffect(() => {
    if (isEditing) {
      // Focus on the textarea
      textareaRef.current.focus();
    }
  }, [isEditing]);

  const [isTyping, setIsTyping] = useState(false);
  const handleKeyDown = () => {
    setIsTyping(true);
  };

  const handleFocus = () => {
    if (address) {
      searchAddress(address, true, true);
      searchLatLng(address, true, address);
      setShowSuggestion(true);
    }
  }

  return (
    <div className={`relative ${width}`}>
      <>
        {isEditing ? (
          <div className={`${!is_valid ? "border-cRed border-[1px]" : "border-white border-[1px]"} px-2 rounded bg-white ${width} min-h-[50px] max-h-[50px] flex justify-center items-center`}>
            <textarea
              onKeyUp={() => {
                setShowSuggestion(true);
              }}
              onKeyDown={handleKeyDown}
              onPaste={(e) => {
                console.log("PASTE TEXT DETECTED: ", e.clipboardData.getData('text'));
                searchLatLng(e.clipboardData.getData('text'));
              }}
              rows={2}
              ref={textareaRef}
              className={`w-full text-cGray800 custom-scrollbars leading-[16px]  flex justify-center items-center h-auto text-fs12 font-normal overflow-y-auto overflow-hidden resize-none rounded outline-none`}
              type="text"
              name={name}
              value={address ?? ''}
              onChange={handleOnChangeAddress}
              // onChange={isTyping ? handleOnChangeAddress : undefined}
              onBlur={handleTextareaBlur}
              autoComplete="off"
              onFocus={handleFocus}
            />
          </div>
        ) : (
          <div onClick={handleDivClick} className={`text-cGray800 w-full leading-[16px] text-fs12 font-normal break-all ${!is_valid ? "border-cRed border-[1px]" : "border-white border-[1px]"} px-2 rounded bg-white ${width} min-h-[50px] max-h-[50px] flex  items-center`}>
            <Clamp lines={2}>{address}</Clamp>
          </div>
        )}
      </>

      {showSuggestion && (
        <div className="w-full text-sm absolute z-[100] rounded-none shadow-sm mt-[2px] ">
          {!isSearching ? (
            items?.map((item, index) => (
              <div
                key={item.id}
                onClick={() => handleSelectItem(item)}
                onMouseOver={() => setSelectedIndex(index)}
                onMouseLeave={() => setSelectedIndex(null)}
                className={` ${index === selectedIndex
                  ? "bg-cPrimary100"
                  : "bg-[#f5f4f4]"
                  } pl-4 pr-2 rounded-none shadow-md cursor-pointer py-1`}
              >
                {item?.title}
              </div>
            ))
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}

export default MassImportAddressAutoComplete;
// ${!is_valid ? "border-cRed border-[1px]"