/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import CommonTitle from '../../../components/title/CommonTitle'
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import CommonButton from '../../../components/button/CommonButton';
import { iChatSelected } from '../../../app/utility/imageImports';
import ChatRightBar from './components/ChatRightBar';
import ChatLeftBar from './components/ChatLeftBar';
import useChatStore, { currentRole } from '../../../app/stores/others/chatStore';
import useGeneralStore from '../../../app/stores/others/generalStore';
import { user_role as role } from '../../../app/utility/const';
import useLayoutStore from '../../../app/stores/others/layoutStore';

const Chat = () => {

    const { t } = useTranslation();

    const { setShowAddChatModal } = useChatStore();

    const { setSideBarSelectItemTitle } = useLayoutStore();

    const { user_role } = useGeneralStore();

    useEffect(() => {
        changePageTitle(t('Limadi | Chat'));
        currentRole(user_role, role);
        setSideBarSelectItemTitle("chat")
    }, [])


    return (
        <div className=''>
            <div className='flex justify-between mb-4'>
                <CommonTitle title={t("Chat")} />
                <CommonButton
                    onClick={() => {
                        setShowAddChatModal(true);
                    }}
                    colorType='warning'
                    width='w-[100px]'
                    icon={iChatSelected}
                    btnLabel={t('Chat')}
                />
            </div>

            <div className='grid grid-cols-12 rounded-md ring-1 ring-cGray300'>
                <div className='col-span-4 pr-4 border-r-[1px] border-cGray300 py-4'>  <ChatLeftBar /></div>
                <div className='col-span-8'>  <ChatRightBar /></div>
            </div>

        </div>
    )
}

export default Chat
