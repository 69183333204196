/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useState } from 'react';
import useFavoriteAddressStore, { addFavoriteAddress } from '../../../../app/stores/others/favoriteAddressStore';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';
import CommonInput from '../../../../components/input/CommonInput';
import AddressAutoComplete from '../../../../components/input/AddressAutoComplete';
import CommonTitle from '../../../../components/title/CommonTitle';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { useNavigate } from 'react-router';
import { Toastr } from '../../../../app/utility/utilityFunctions';
import { k_fav_address_paths } from '../../../../app/utility/AllRoute';

const AddFavoriteAddress = () => {

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const { setAddFavoriteAddress_form, addFavoriteAddress_form, resetFavoriteAddressForm } = useFavoriteAddressStore();

    const [doSearch, setDoSearch] = useState(false);

    const [addAddressLabel, setAddAddressLabel] = useState("");

    const [lat, setLat] = useState("");

    const handleFavoriteAddress = async (e) => {

        e.preventDefault();
        if (!addFavoriteAddress_form?.title || !addFavoriteAddress_form?.lng || !lat || !addAddressLabel) {
            Toastr({ message: t("Please fill up all required fields"), type: "warning" })
            return;
        }
        await setAddFavoriteAddress_form({ ...addFavoriteAddress_form, address: addAddressLabel, lat: lat });

        const success = await addFavoriteAddress();
        if (success) {
            resetFavoriteAddressForm()
            setAddAddressLabel("");
            setLat("");
            navigateTo(k_fav_address_paths.list)
        }
    }

    const handleChange = async (name, value) => {
        if (name === "address") {
            await setAddAddressLabel(value);
        } else if (name === "lat") {
            await setLat(value)
        } else if (name === "lng") {
            await setAddFavoriteAddress_form({ ...addFavoriteAddress_form, lng: value });
        }
    };

    return (
        <form className='flex space-x-20' onSubmit={handleFavoriteAddress}>

            <div className='w-full'>
                <CommonTitle
                    title={t('Add Favorite Address')}
                    icon={true}
                    withConfirm={addFavoriteAddress_form?.title || addAddressLabel || addFavoriteAddress_form?.note || addFavoriteAddress_form?.floor_number}
                    link={k_fav_address_paths.list}
                />

                <div className='mb-s5'>
                    <CommonInput
                        min={'1'}
                        max_input={100}
                        type='text'
                        labelText={t('Name')}
                        required={true}
                        value={addFavoriteAddress_form?.title}
                        name={'title'}
                        onChange={(e) => {
                            if (e.target.value !== " ") {
                                const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                setAddFavoriteAddress_form({ ...addFavoriteAddress_form, title: trimmedValue })
                            }
                        }} />
                </div>

                <div className='mb-s5'>
                    <AddressAutoComplete
                        required={true}
                        label={t("Address")}
                        placeholder={("Type Address..")}
                        name={"address"}
                        address={addAddressLabel}
                        latName={"lat"}
                        lngName={"lng"}
                        changeValue={handleChange}
                        doSearch={doSearch}
                        setDoSearch={setDoSearch}
                        icon={false}
                        isInvalid={addAddressLabel && (!lat || !addFavoriteAddress_form?.lng)}
                    />
                </div>

                <CommonInput
                    max_input={50}
                    type='text'
                    labelText={t('Floor Number')}
                    value={addFavoriteAddress_form?.floor_number}
                    name={'floor_number'}
                    onChange={(e) => {
                        setAddFavoriteAddress_form({ ...addFavoriteAddress_form, floor_number: e.target.value })
                    }}
                />

                <CommonInput type='text'
                    textarea={true}
                    max_input={255}
                    value={addFavoriteAddress_form?.note}
                    name={'note'}
                    rows={1}
                    labelText={t('Note')}
                    onChange={(e) => {
                        if (e.target.value !== " ") {
                            const trimmedValue = e.target.value.replace(/\s+/g, " ");
                            setAddFavoriteAddress_form({ ...addFavoriteAddress_form, note: trimmedValue })
                        }
                    }}
                />
            </div>

            <div className='flex justify-end space-x-5'>
                <CommonButtonOutlined
                    onClick={() => { navigateTo(k_fav_address_paths.list) }}
                    colorType='danger'
                    btnLabel={t('Cancel')}
                    width='w-[120px]'
                />

                <CommonButton
                    width='w-[120px]'
                    type='submit'
                    btnLabel={t('Add')}
                    isDisabled={!addFavoriteAddress_form?.title || !addFavoriteAddress_form?.lng || !lat || !addAddressLabel}
                />
            </div>
        </form>
    )
}

export default AddFavoriteAddress