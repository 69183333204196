/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore from "../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../app/stores/others/generalStore";
import { user_role as role } from "../../../../app/utility/const";
import { iBorderedImageUploadIcon, iCloseIcon } from "../../../../app/utility/imageImports";
import {
    formatDateToApiDate,
    getStringFromDateObject,
    isWhitespaceOnly,
} from "../../../../app/utility/utilityFunctions";
import CommonButton from "../../../../components/button/CommonButton";
import ImageBtn from "../../../../components/image/ImageBtn";
import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import AddressAutoComplete from "../../../../components/input/AddressAutoComplete";
import CommonDatePicker from "../../../../components/input/CommonDatePicker";
import CommonInput from "../../../../components/input/CommonInput";
import CommonTimePicker from "../../../../components/input/CommonTimePicker";
import CommonModal from "../../../../components/modal/CommonModal";

const EditDeliveryModal = ({ index = 0 }) => {
    const {
        tableSelectStopsAddDeliveryProduct,
        removeSelectStopsDeliveryProduct,
        updateSelectStopsForm,
        changeSelectStopsProductQuantity,
        showEditDeliveryModal,
        setShowEditDeliveryModal,
        selectStop,
        setTableCrFullForm,
        setAddProduct,
        cr_form
    } = useCreateRequestStore();

    const [doSearch, setDoSearch] = useState(false);

    const { t } = useTranslation();

    const { user_role } = useGeneralStore();

    const formData = selectStop && selectStop[index ? index : 0];

    const clearFormData = () => {
        // updateSelectStopsForm(index, "products", []);
        updateSelectStopsForm(index, "address", "");
        updateSelectStopsForm(index, "lat", "");
        updateSelectStopsForm(index, "lng", "");
        updateSelectStopsForm(index, "floor_number", "");
        updateSelectStopsForm(index, "formatted_date", "");
        updateSelectStopsForm(index, "date", "");
        updateSelectStopsForm(index, "start_time", "");
        updateSelectStopsForm(index, "end_time", "");
        updateSelectStopsForm(index, "duration", "");
        updateSelectStopsForm(index, "removed_products_ids", []);
        updateSelectStopsForm(index, "comment", "");
        updateSelectStopsForm(index, "attachment", "");
        updateSelectStopsForm(index, "products", [{ text: "Product" }]);
    }

    useEffect(() => {
        formData?.products?.length === 0 && tableSelectStopsAddDeliveryProduct(index);
    }, [showEditDeliveryModal]);

    const scrollDivRef = useRef(null);

    const [height, setHeight] = useState(window.innerHeight - 200);

    const isCrossVisible =
        !!formData?.date ||
        !!formData?.start_time ||
        !!formData?.duration ||
        !!formData?.end_time ||
        !!formData?.delivery_time ||
        (formData?.floor_number && !isWhitespaceOnly(formData?.floor_number)) ||
        !!formData?.address ||
        !!formData?.comment ||
        !!formData?.attachment ||
        formData?.products?.length > 1
    // || !!formData?.products?.filter((item) => item.text).length;


    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - 200);
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // useEffect(() => {
    //     console.log("formData products", formData?.products);
    //     console.log("formData products length", formData?.products.length);

    // }, [formData]);

    return (
        <div>
            <CommonModal
                showModal={showEditDeliveryModal}
                setShowModal={setShowEditDeliveryModal}
                modalTitle={
                    <div className="flex items-center space-x-4">
                        <div>{t("Edit Delivery") + ` ${index + 1}`}</div>
                        {isCrossVisible ? <div>
                            <ImageBtn
                                hoverImageSrc={iCloseIcon}
                                imageSrc={iCloseIcon}
                                hasHoverAction={true}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    clearFormData()
                                }}
                                showTooltip={true}
                                tooltipMessage={t("Clear")}
                            />
                        </div> : <></>
                        }
                    </div>
                }
                widthClass="w-[900px]"
                modalSpace={true}
                mainContent={
                    <>
                        <div
                            ref={scrollDivRef}
                            style={{ maxHeight: `${height}px` }}
                            className="pr-4 overflow-y-auto"
                            key={index}
                        >
                            <input
                                value={formData?.address}
                                onChange={() => { }}
                                className="absolute h-0 outline-none caret-transparent"
                            />
                            <div className="grid grid-cols-2 gap-x-6">
                                <AddressAutoComplete
                                    className="w-full"
                                    required={true}
                                    label={t("Delivery Address")}
                                    name={"address"}
                                    address={formData?.address}
                                    latName={"lat"}
                                    lngName={"lng"}
                                    changeValue={(name, value) => {
                                        if (name === "address") {
                                            updateSelectStopsForm(index, "address", value);
                                        } else if (name === "lat") {
                                            updateSelectStopsForm(index, "lat", value);
                                        } else if (name === "lng") {
                                            updateSelectStopsForm(index, "lng", value);
                                        }
                                    }}
                                    doSearch={doSearch}
                                    setDoSearch={setDoSearch}
                                    onFavAddressModalItemClick={(address) => {
                                        updateSelectStopsForm(index, "address", address?.address);
                                        updateSelectStopsForm(index, "lat", address?.lat);
                                        updateSelectStopsForm(index, "lng", address?.lng);
                                        updateSelectStopsForm(
                                            index,
                                            "floor_number",
                                            address.floor_number === null ? "" : address.floor_number
                                        );
                                    }}
                                />

                                <CommonInput
                                    max_input={55}
                                    type="text"
                                    labelText={t("Floor Number")}
                                    name={"floor_number"}
                                    value={formData?.floor_number !== "" && isWhitespaceOnly(formData?.floor_number) ? "" : formData?.floor_number}
                                    onChange={(e) => {
                                        updateSelectStopsForm(
                                            index,
                                            "floor_number",
                                            e.target.value
                                        );
                                    }}
                                />
                            </div>

                            {useGeneralStore.getState().user_role === role.company && (
                                <div>
                                    <div className="grid grid-cols-2 mt-3 gap-x-6">
                                        <div className="grid grid-cols-2 gap-x-6">
                                            <div className="w-full">
                                                <CommonDatePicker
                                                    startDate={cr_form?.pickup?.date ? new Date(cr_form?.pickup?.date) : new Date()}
                                                    required={false}
                                                    allowPastDate={true}
                                                    shouldUpdateValue={true}
                                                    label={t("Date")}
                                                    name="formatted_date"
                                                    value={formData?.date}
                                                    onChange={(date) => {
                                                        updateSelectStopsForm(
                                                            index,
                                                            "formatted_date",
                                                            getStringFromDateObject(date)
                                                        );
                                                        updateSelectStopsForm(
                                                            index,
                                                            "date",
                                                            formatDateToApiDate(date)
                                                        );
                                                    }}
                                                />
                                            </div>

                                            <div className="w-full mt-3">
                                                <CommonTimePicker
                                                    showExtendedTimeUi={false}
                                                    required={false}
                                                    label={t("Time")}
                                                    init_time={formData?.start_time}
                                                    end_time={formData?.end_time}
                                                    name="start_time"
                                                    onChange={(start_time, end_time) => {
                                                        updateSelectStopsForm(
                                                            index,
                                                            "start_time",
                                                            start_time
                                                        );
                                                        updateSelectStopsForm(index, "end_time", end_time);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-x-6">
                                            <div className="w-full mt-3">
                                                <CommonTimePicker
                                                    showExtendedTimeUi={false}
                                                    required={false}
                                                    label={t("Range")}
                                                    init_time={formData?.end_time}
                                                    end_time={formData?.end_time}
                                                    name="start_time"
                                                    onChange={(start_time, end_time) => {
                                                        updateSelectStopsForm(
                                                            index,
                                                            "end_time",
                                                            start_time
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="-mt-3">
                                                <CommonInput
                                                    max_input={5}
                                                    type="number"
                                                    labelText={t("Duration (min)")}
                                                    name={"duration"}
                                                    value={formData?.duration === 0 ? "" : formData?.duration}
                                                    onChange={(e) => {
                                                        updateSelectStopsForm(
                                                            index,
                                                            "duration",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {formData?.products?.map((productItem, productIndex) => (
                                <div className={`relative -mt-[${user_role === role.company ? "11px" : ""}]`}>
                                    <CommonInput
                                        valuePaddingRight="28px"
                                        required={false}
                                        labelText={t("Product")}
                                        max_input={255}
                                        name={"product"}
                                        value={(formData?.products[productIndex]?.text === "Product" || formData?.products[productIndex]?.text === " Product") ? "" : formData?.products[productIndex]?.text ?? ""}
                                        onChange={(e) => {
                                            if (e.target.value.trim() === "")
                                                changeSelectStopsProductQuantity(
                                                    "Product",
                                                    index,
                                                    productIndex
                                                );
                                            else changeSelectStopsProductQuantity(
                                                e.target.value,
                                                index,
                                                productIndex
                                            );
                                        }}
                                    />
                                    {formData?.products?.length > 1 && (
                                        <div className="absolute right-0 top-3">
                                            <ImageBtn
                                                hoverImageSrc={iCloseIcon}
                                                imageSrc={iCloseIcon}
                                                hasHoverAction={true}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (productItem && productItem?.id) {
                                                        if (formData?.removed_products_ids?.length > 0) {
                                                            updateSelectStopsForm(index, "removed_products_ids", [
                                                                ...formData?.removed_products_ids,
                                                                productItem?.id,
                                                            ]);
                                                        } else {
                                                            updateSelectStopsForm(index, "removed_products_ids", [
                                                                productItem?.id,
                                                            ]);
                                                        }
                                                    }
                                                    removeSelectStopsDeliveryProduct(index, productIndex);
                                                }}
                                                showTooltip={false}
                                                hoverSize="w-7 h-7"
                                                iconSize="max-w-[16px] min-w-[16px] min-h-[16px] max-h-[16px]"
                                            />
                                        </div>

                                    )}
                                </div>
                            ))}

                            <AddProduct
                                onClick={() => {
                                    tableSelectStopsAddDeliveryProduct(index);
                                    setTimeout(() => {
                                        const scrollDiv = scrollDivRef?.current;
                                        if (scrollDiv) {
                                            scrollDiv.scrollTop = scrollDiv.scrollHeight;
                                        }
                                    }, 0);
                                }}
                            />

                            <div className="mt-4 mb-8">
                                <CommonInput
                                    labelText={t("Comment")}
                                    name={"comment"}
                                    value={isWhitespaceOnly(formData?.comment) ? "" : formData?.comment}
                                    onChange={(e) => {
                                        updateSelectStopsForm(
                                            index,
                                            "comment",
                                            e.target.value
                                        );

                                    }}
                                    textarea={true}
                                    max={255}
                                    max_input={255}
                                />
                            </div>

                            <ImageUpload
                                setImage={(value) => {
                                    updateSelectStopsForm(index, "attachment", value);
                                }}
                                image_url={formData?.attachment_url}
                                src={formData?.attachment}
                                setSrc={(value) => {
                                    updateSelectStopsForm(index, "attachment", value);
                                }}
                                title="Add Attachment"
                                imageUploadIcon={iBorderedImageUploadIcon}
                                addButtonClassName="border border-dashed border-cGray300 p-s10 w-max"
                                width="w-max"
                                showInfoText={true}
                                textStyle="text-fs14 font-fw400 text-cBrand leading-[25px]"
                            />
                        </div>

                        <div className="flex justify-end mt-7">
                            <CommonButton
                                onClick={() => {
                                    setTableCrFullForm(selectStop);
                                    setAddProduct(true);
                                    setShowEditDeliveryModal(false);
                                }}
                                btnLabel={t("Update")}
                            />
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default EditDeliveryModal;

const AddProduct = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <div
            onClick={onClick}
            className="flex flex-row items-center justify-start mt-5 space-x-2 cursor-pointer w-max"
        >
            {/* <img src={iPlus} alt="" /> */}
            <div className="text-cBrand text-fs16 font-fw500">{t("Add Product")}</div>
        </div>
    );
};
