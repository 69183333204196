import React from 'react'
import { useNavigate } from 'react-router-dom';
import useCreateRequestStore, { countAllStopsProducts, createRequestClear, saveRequest } from '../../../../app/stores/others/createRequestStore';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import { k_cr_actions, user_role as role } from '../../../../app/utility/const';
import { formatDate, formatTime } from '../../../../app/utility/utilityFunctions';
import CommonModal from '../../../../components/modal/CommonModal'
import SummaryInfoItem from '../../../../components/utility/summary/components/SummaryInfoItem';
import { useTranslation } from 'react-i18next';
import SliderToSubmit from '../../../../components/input/SliderToSubmit';
import useRequestStore from '../../../../app/stores/others/requestStore';

export default function RequestConfirmModal() {

  const { is_show_request_confirm_modal, setShowRequestConfirmModal, cr_form, setSelectedCompanies } = useCreateRequestStore();
  const { user_role } = useGeneralStore();
  const { available_shifts } = useRequestStore();
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const selectedShift = available_shifts?.find((i) => parseInt(i.id) === parseInt(cr_form?.shift_id))

  return (
    <CommonModal
      showModal={is_show_request_confirm_modal}
      setShowModal={setShowRequestConfirmModal}
      widthClass='w-[65vw] max-w-[670px]'
      modalTitle={`${t('Create Request')}`}
      mainContent={
        <div onClick={() => {
          console.log("cr_form", cr_form);

        }}>
          <div className='mt-s12'>
            <div className='font-fw500 text-fs24 text-cMainBlack'>{t('Summary')}</div>

            <div className='w-full py-s10 flex flex-col justify-start space-y-[6px]'>
              <SummaryInfoItem title={t('Title')} description={cr_form?.title} />
              <SummaryInfoItem title={t('Type')} description={cr_form?.transport_type} />
              <SummaryInfoItem title={t('Pickup Date')} description={formatDate(cr_form?.pickup?.date)} />
              <SummaryInfoItem title={t('Pickup Time')} description={(formatTime(cr_form?.pickup?.start_time) ?? '--') + ' - ' + (formatTime(cr_form?.pickup?.end_time) ?? '--')} />
              <SummaryInfoItem title={t('Delivery Overview')} description={`${cr_form?.deliveries?.length} ${t('Stops')} (${countAllStopsProducts(cr_form?.deliveries)} ${t('Packages')})`} />
              <hr />

              {user_role === role.company ? <>
                <SummaryInfoItem title={t('Driver Name')} description={selectedShift?.driver_name ?? ""} />
                <SummaryInfoItem title={t('Vehicle Number')} description={selectedShift?.car_name ?? ""} />
              </> : <></>}

              {user_role === role.customer ? <>
                <SummaryInfoItem title={t('Direct Invite')} description={cr_form?.company_user_ids?.length?.toString() ?? 0} />
                <SummaryInfoItem title={t('Global Invite')} description={cr_form?.is_global ? t('Submitted') : t('Not Submitted')} />
              </> : <></>}

            </div>

            <div className='mt-s6'>

              <SliderToSubmit width={630} onSubmission={async (data) => {
                if (data) {
                  const res = await saveRequest(k_cr_actions.submit);

                  if (res) {
                    setShowRequestConfirmModal(false);
                    createRequestClear();
                    setSelectedCompanies([]);

                    if (user_role === role.company) navigateTo('/requests/awarded');
                    if (user_role === role.customer) navigateTo('/requests/in-bidding');

                    // setTimeout(() => {
                    //   window.scrollTo({
                    //     top: document.documentElement.scrollHeight - window.innerHeight,
                    //     behavior: "smooth"
                    //   });
                    // }, 1000);
                  }
                }

              }} />
            </div>
          </div>
        </div>
      }

    />
  )
}
