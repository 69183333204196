
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useFavoriteCompaniesStore, { getFavoriteCompany, handleFavAddressOrder, searchFavCompany } from '../../../app/stores/customer/favoriteCompaniesStore';
import { useDebounce } from 'use-debounce';
import { changePageTitle } from '../../../app/utility/utilityFunctions';
import { useTranslation } from 'react-i18next';
import useSettingsStore from '../../../app/stores/others/settingsStore';
import { common_take, k_fav_company_order_by } from '../../../app/utility/const';
import CommonTable from '../../../components/table/CommonTable';
import CommonButton from '../../../components/button/CommonButton';
import RatingFiveStar from '../../../components/rating/RatingFiveStar';
import { useNavigate } from 'react-router-dom';
import CommonCompanyDetailsModal from '../../../components/modal/CommonCompanyDetailsModal';
import { k_fav_company_paths } from '../../../app/utility/AllRoute';

const FavoriteCompanies = () => {

    const {
        favoriteCompanyList,
        setCompany_take,
        setShowCompanyDetailsModal,
        favCompany_order_by,
        is_asc,
        company_take, searchValueFavoriteCompany,
        setSearchValueFavoriteCompany,
        setFavCompanyPageUrl,
        setFavCompany_order_by,
        showCompanyDetailsModal,
    } = useFavoriteCompaniesStore();

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    useEffect(() => {
        changePageTitle(t('Limadi | Favorite Companies'));
        setSearchValueFavoriteCompany("");
        window.scrollTo(0, 0);
    }, [])

    const [searchValue] = useDebounce(searchValueFavoriteCompany, 500);

    useEffect(() => {
        searchFavCompany(searchValue)
    }, [searchValue])

    const { selectedCompanyId, setSelectedCompanyId } = useSettingsStore();

    useEffect(() => {
        fetchData()
    }, [searchValue])


    const fetchData = async () => {
        if (searchValue === "") {
            await setFavCompany_order_by("updated_at")
            setSearchValueFavoriteCompany("")
            const success = await getFavoriteCompany();
            if (success) window.scrollTo(0, 0);
        } else {
            const success = await getFavoriteCompany("", searchValue, favCompany_order_by === "updated_at" ? false : true);
            if (success) window.scrollTo(0, 0);
        }

    }

    const resetTable = async () => {
        await setCompany_take(common_take);
        await setFavCompany_order_by("updated_at")
        await setSearchValueFavoriteCompany("")
        getFavoriteCompany();
    }

    const headers = [
        { name: "#", placement: "justify-center items-center" },
        { isActive: favCompany_order_by === k_fav_company_order_by.name, sort: is_asc, name: t("Company Name"), onClickAction: () => { handleFavAddressOrder(k_fav_company_order_by.name) }, showSortImage: true },
        { isActive: favCompany_order_by === k_fav_company_order_by.city, sort: is_asc, name: t("City"), onClickAction: () => { handleFavAddressOrder(k_fav_company_order_by.city) }, showSortImage: true },
        { isActive: favCompany_order_by === k_fav_company_order_by.email, sort: is_asc, name: t("Email"), onClickAction: () => { handleFavAddressOrder(k_fav_company_order_by.email) }, showSortImage: true },
        { isActive: favCompany_order_by === k_fav_company_order_by.phone, sort: is_asc, name: t("Phone"), onClickAction: () => { handleFavAddressOrder(k_fav_company_order_by.phone) }, showSortImage: true },
        { isActive: favCompany_order_by === k_fav_company_order_by.rate, sort: is_asc, name: t("Rating"), onClickAction: () => { handleFavAddressOrder(k_fav_company_order_by.rate) }, showSortImage: true },
    ];

    const contents = favoriteCompanyList?.data?.map((item, index) => {
        return {
            contents: [
                {value: (favoriteCompanyList?.current_page * common_take - common_take) + index + 1},
                {value: item?.name},
                {value: item?.city},
                {value: item?.email},
                {value: item?.phone},
                {value: <RatingFiveStar rating={parseFloat(item?.rate?.toFixed(1)) ?? 0} />},
            ],
            clickAction: () => {
                setSelectedCompanyId(item?.id)
                setShowCompanyDetailsModal(true)
            }
        }
    })

    return (
        <div>
            <CommonTable
                tableTitle={t('Favorite Companies') + ` (${favoriteCompanyList?.total ?? 0})`}
                tableTitleClassName={'title'}
                showSearchBox={true}
                showTopRightFilter={false}

                topRightMainComponent={<>
                    <CommonButton
                        onClick={() => {
                            navigateTo(k_fav_company_paths.add)
                        }}
                        btnLabel={t('Add Company')}
                        width={"w-[160px]"} />
                </>}
                showTakeOption={true}
                showPagination={true}
                showPageCountText={true}
                headers={headers}
                outerPadding='p-s0'
                paginationObject={favoriteCompanyList}

                withClearSearch={true}
                onSearchClear={() => { setSearchValueFavoriteCompany("") }}
                searchValue={searchValueFavoriteCompany}
                searchOnChange={(e) => { setFavCompanyPageUrl(""); setSearchValueFavoriteCompany(e.target.value); }}

                tableHeaders={headers}
                tableContents={contents}
                hasClickFunction={true}
                widths="50px auto 15% 15% 10% 10%"

                currentTakeAmount={company_take}
                withReloader={true}
                onReload={resetTable}
                takeOptionOnChange={async (e) => {
                    setFavCompanyPageUrl("")
                    await setCompany_take(e.target.value);
                    await getFavoriteCompany("")
                }}
                paginationOnClick={async (url) => {
                    setFavCompanyPageUrl(url)
                    getFavoriteCompany(url);
                }}
                emptyDataMessage={t("No company found")}
            />

            <CommonCompanyDetailsModal showModal={showCompanyDetailsModal} setShowModal={setShowCompanyDetailsModal} company_id={selectedCompanyId} bidding_details={false} />
        </div>
    );
};

export default FavoriteCompanies;