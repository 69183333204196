
import useGlobalReqStore from "../../../app/stores/company/globlaReqStore";
import useShiftStore, { } from "../../../app/stores/company/shiftStore";
import useChatStore from "../../../app/stores/others/chatStore";
import { createRequestClear } from "../../../app/stores/others/createRequestStore";
import useRequestStore from "../../../app/stores/others/requestStore";
import { resetTrackAndTrace } from "../../../app/stores/others/trackTraceStore";
import useUtilityStore from "../../../app/stores/others/utilityStore";
import { common_take, create_request_path, k_requests_status } from "../../../app/utility/const";
import {
    iAdminAccessNormal,
    iAdminAccessSelected,
    iAwardedNormal,
    iAwardedSelected,
    iBiddingIconNormal,
    iBiddingIconSelected,
    iBiddingNormal,
    iBiddingSelected,
    iCarManagerNormal,
    iCarManagerSelected,
    iChangePasswordNormal,
    iChangePasswordSelected,
    iChatNormal,
    iChatSelected,
    iCompletedNormal,
    iCompletedSelected,
    iCreateReqNormal,
    iCreateReqSelected, iDriverManagerNormal, iDriverManagerSelected, iFaqNormal, iFaqSelected, iFavAddressNormal,
    iFavAddressSelected,
    iGlobalRequestNormal,
    iGlobalRequestSelected,
    iHistoryNormal,
    iHistorySelected,
    iHomeNormal,
    iHomeSelected,
    iInvitationNormal,
    iInvitationSelected,
    iLanguageNormal,
    iLanguageSelected,
    iLogout,
    iManagementNormal,
    iManagementSelected,
    iNotPlannedNormal,
    iNotPlannedSelected,
    iNotificationsNormal,
    iNotificationsSelected,
    iOnGoingNormal,
    iOnGoingSelected,
    iProfileNormal,
    iProfileSelected,
    iReqNormal,
    iReqSelected,
    iSavedReqNormal,
    iSavedReqSelected, iSettingsNormal,
    iSettingsSelected, iShiftManagerNormal, iShiftManagerSelected,
    iTermsNormal,
    iTermsSelected,
    iTrackTraceNormal,
    iTrackTraceSelected
} from "../../../app/utility/imageImports";

const { resetFilterShiftList, setShiftSearchFilterChip, resetFilterForm } = useShiftStore.getState();
const { setGlobalIndexForm } = useGlobalReqStore.getState();
const { setSelectChat, setChatSearch } = useChatStore.getState();
const { setShowLogoutModal } = useUtilityStore.getState();
const { setRequestTake } = useRequestStore.getState();

export const CompanySideBarList = [
    //home
    {
        onClick: () => { },
        title: "Dashboard",
        linkTo: "/",
        isActiveLink: "home",
        normalIcon: iHomeNormal,
        selectedIcon: iHomeSelected,
    },

    // create request
    {
        onClick: () => {
            createRequestClear();
        },
        title: "Create Request",
        linkTo: create_request_path,
        isActiveLink: "create",
        normalIcon: iCreateReqNormal,
        selectedIcon: iCreateReqSelected,
    },

    // Track & Trace
    {
        onClick: () => {
            resetTrackAndTrace(true);
        },
        title: "Track & Trace",
        linkTo: "/track-trace",
        isActiveLink: "track-trace",
        normalIcon: iTrackTraceNormal,
        selectedIcon: iTrackTraceSelected,
    },

    //chat
    {
        onClick: () => {
            setSelectChat({});
            setChatSearch("");
        },
        title: "Chat",
        linkTo: "/chat",
        isActiveLink: "chat",
        normalIcon: iChatNormal,
        selectedIcon: iChatSelected,
    },

    // global request
    {
        onClick: () => {
            setGlobalIndexForm({});
        },
        title: "global request",
        linkTo: "/global-request",
        isActiveLink: "global-request",
        normalIcon: iGlobalRequestNormal,
        selectedIcon: iGlobalRequestSelected,
    },


    // request part (expandable)
    {
        onClick: () => { },
        title: "requests",

        isActiveLink: "expanded_bar",
        normalIcon: iReqNormal,
        selectedIcon: iReqSelected,
        expandedItems: [
            {
                title: "saved",
                link: "/requests/saved",
                normalIcon: iSavedReqNormal,
                selectedIcon: iSavedReqSelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestTake(common_take) },
                name: k_requests_status?.saved
            },
            {
                title: "invitation",
                link: "/requests/invitation",
                normalIcon: iInvitationNormal,
                selectedIcon: iInvitationSelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestTake(common_take) },
                name: k_requests_status.invitation
            },
            {
                title: "in bidding",
                link: "/requests/in-bidding",
                normalIcon: iBiddingIconNormal,
                selectedIcon: iBiddingIconSelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestTake(common_take) },
                name: k_requests_status.in_bidding
            },
            {
                title: "not planned",
                link: "/requests/not-planned",
                normalIcon: iNotPlannedNormal,
                selectedIcon: iNotPlannedSelected,
                isActiveItem: true,
                expandedOnClick: () => { console.log(''); },
                name: k_requests_status.not_planned
            },
            {
                title: "upcoming",
                link: "/requests/awarded",
                normalIcon: iAwardedNormal,
                selectedIcon: iAwardedSelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestTake(common_take) },
                name: k_requests_status.awarded
            },
            {
                title: "ongoing",
                link: "/requests/on-going",
                normalIcon: iOnGoingNormal,
                selectedIcon: iOnGoingSelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestTake(common_take) },
                name: k_requests_status.ongoing
            },
            {
                title: "completed",
                link: "/requests/completed",
                normalIcon: iCompletedNormal,
                selectedIcon: iCompletedSelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestTake(common_take) },
                name: k_requests_status.completed
            },
            {
                title: "history",
                link: "/requests/history",
                normalIcon: iHistoryNormal,
                selectedIcon: iHistorySelected,
                isActiveItem: true,
                expandedOnClick: () => { setRequestTake(common_take) },
                name: k_requests_status.history
            },
        ],
    },

    // management part (expandable)
    {
        onClick: () => { },
        title: "Management",
        isActiveLink: "management",
        normalIcon: iManagementNormal,
        selectedIcon: iManagementSelected,
        expandedItems: [
            {
                title: "Shift",
                link: "/shift-manager",
                normalIcon: iShiftManagerNormal,
                selectedIcon: iShiftManagerSelected,
                isActiveItem: true,
                expandedOnClick: () => {
                    if (!window.location.pathname.includes('/shift-manager'))
                        localStorage.setItem('last_shift_item_id', 0);
                    console.log('');
                    resetFilterShiftList();
                    resetFilterForm();
                    setShiftSearchFilterChip([]);
                }
            },
            {
                title: "Driver",
                link: "/driver-manager",
                isActiveLink: "driver-manager",
                normalIcon: iDriverManagerNormal,
                selectedIcon: iDriverManagerSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },

            {
                title: "Car",
                link: "/car-manager",
                isActiveLink: "car-manager",
                normalIcon: iCarManagerNormal,
                selectedIcon: iCarManagerSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
        ],
    },


    // Settings part (expandable)
    {
        onClick: () => { },
        title: "settings",
        isActiveLink: "settings",
        normalIcon: iSettingsNormal,
        selectedIcon: iSettingsSelected,
        expandedItems: [
            {
                title: "Profile",
                link: "/settings/profile",
                normalIcon: iProfileNormal,
                selectedIcon: iProfileSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "FAQ’s",
                link: "/settings/faq",
                normalIcon: iFaqNormal,
                selectedIcon: iFaqSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Language",
                link: "/settings/language",
                normalIcon: iLanguageNormal,
                selectedIcon: iLanguageSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Terms & Conditions",
                link: "/settings/terms-conditions",
                normalIcon: iTermsNormal,
                selectedIcon: iTermsSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Password",
                link: "/settings/change-password",
                normalIcon: iChangePasswordNormal,
                selectedIcon: iChangePasswordSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Notification",
                link: "/settings/push-notification",
                normalIcon: iNotificationsNormal,
                selectedIcon: iNotificationsSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Admin Access",
                link: "/settings/admin-access",
                normalIcon: iAdminAccessNormal,
                selectedIcon: iAdminAccessSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
            {
                title: "Favorite Addresses",
                link: "/favorite-address",
                normalIcon: iFavAddressNormal,
                selectedIcon: iFavAddressSelected,
                isActiveItem: true,
                expandedOnClick: () => { }
            },
        ],
    },

    // Logout
    {
        onClick: () => { setShowLogoutModal(true) },
        title: "Logout",
        linkTo: null,
        isActiveLink: "setts",
        normalIcon: iLogout,
        selectedIcon: iLogout,
    },

];
export default CompanySideBarList;