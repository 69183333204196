/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useSettingsStore, { updateTerms } from '../../../../../app/stores/others/settingsStore';
import CommonButton from '../../../../../components/button/CommonButton';
import CommonModal from '../../../../../components/modal/CommonModal';
import RichTextEditor from '../../../../../components/textEditor/RichTextEditor';
import { useTranslation } from 'react-i18next';

function EditCompanyPolicyModal() {

    const { showEditCompanyPolicyModal, setShowEditCompanyPolicyModal, termsData, setEditTermsData, editTermsData } = useSettingsStore();

    const { t } = useTranslation();
    const [value, setValue] = useState("")
    const [length, setLength] = useState(0)

    useEffect(() => {
        setValue(editTermsData ? editTermsData : termsData)
    }, [editTermsData, termsData])

    const handleCloseModal = () => {
        setShowEditCompanyPolicyModal(false);
        setTimeout(()=> {
            setValue(editTermsData? editTermsData : termsData);
            setLength(0)
        }, 500)
    }

    return (
        <div>
            <CommonModal
                showModal={showEditCompanyPolicyModal}
                setShowModal={handleCloseModal}
                modalTitle={t("Edit Company Terms And Conditions")}
                mainContent={
                    <>
                        <div className='mt-s20 h-[330px]'>
                            <RichTextEditor
                                onChange={(e) => {
                                    setValue(e)
                                }}
                                value={value}
                                height='200px'
                                maxLength={255}
                                setLength={setLength}
                                length={length}
                            />
                            {<div className='text-cGray500 font-medium text-fs14 mt-s45 flex justify-end'>{length} /255</div>}

                            <div className='flex justify-end mt-5'>
                                <CommonButton
                                    isDisabled={length === 0 || length > 255 || value === "<p><br></p>"}
                                    onClick={() => {
                                        setEditTermsData(value)
                                        setShowEditCompanyPolicyModal(false)
                                    }} btnLabel={t('Submit')} />
                            </div>
                        </div>
                    </>
                }

            />
        </div>
    )
}

export default EditCompanyPolicyModal;
