import React from 'react'
import AvailableShifts from './AvailableShifts'
import useCreateRequestStore from '../../../../../../../app/stores/others/createRequestStore'
import TitleAndActions from '../../../titleAndActions/TitleAndActions';

const AllShift = () => {

    const { cr_form, changeCrForm } = useCreateRequestStore();

    return (
        <div className='w-full'>
            <TitleAndActions />
            <AvailableShifts
                selectedId={cr_form?.shift_id}
                onChange={async (item) => {
                    if (item?.id === cr_form?.shift_id) {
                        await changeCrForm("shift_plan", null);
                        await changeCrForm("shift_id", null);
                    } else {
                        await changeCrForm("shift_plan", item);
                        await changeCrForm("shift_id", item?.id);
                    }
                }}
            />
        </div>
    )
}

export default AllShift