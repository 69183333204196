import ProtectedRoute from "../app/utility/ProtectedRoute";
import Layout from "../components/layout/Layout";
import ForgotPassword from "../views/common/auth/ForgotPassword";
import Login from "../views/common/auth/Login";
import OtpVerification from "../views/common/auth/OtpVerification";
import Register from "../views/common/auth/Register";
import Dashboard from "../views/common/dashboard/Dashboard";
import Error404 from "../components/errors/Error404";
import FavoriteAddress from "../views/common/favoriteAddress/FavoriteAddress";
import Notification from "../views/common/notification/Notification";
import ChangePassword from "../views/common/settings/ChangePassword";
import Contact from "../views/common/settings/Contact";
import Faq from "../views/common/settings/Faq";
import Language from "../views/common/settings/Language";
import EditCompanyProfile from "../views/common/settings/profile/company/EditCompanyProfile";

import Settings from "../views/common/settings/Settings";
import TermsConditions from "../views/common/settings/TermsConditions";
import CreateRequest from "../views/common/createRequest/CreateRequest";
import RequestList from "../views/common/requests/requestList/RequestList";
import RequestDetailsSection from "../views/common/requests/requestDetails/RequestDetailsSection";
import DeleteAccount from "../views/common/settings/DeleteAccount";
import Profile from "../views/common/settings/profile/Profile";
import ForgetPasswordUpdate from "../views/common/auth/ForgetPasswordUpdate";
import ExpressLogin from "../views/common/auth/ExpressLogin";
import Chat from "../views/common/chat/Chat";
import SettingsNotification from "../views/common/settings/Notification";
import AdminAccess from "../views/common/settings/AdminAccess";
import AddFavoriteAddress from "../views/common/favoriteAddress/components/AddFavoriteAddress";
import FavoriteAddressDetails from "../views/common/favoriteAddress/components/FavoriteAddressDetails";
import EditCustomerProfile from "../views/common/settings/profile/customer/EditCustomerProfile";
import EditFavoriteAddress from "../views/common/favoriteAddress/components/EditFavoriteAddress";
import ShiftDetails from "../views/company/shiftManager/components/ShiftDetails";
import AllShift from "../views/common/createRequest/components/content/selectShift/components/AllShift";

export const commonRouteMapper = [

    { path: "/login", element: <Login />, },
    { path: "/express/login/:user_id/:express_token", element: <ExpressLogin />, },
    { path: "/admin-login", element: <Login />, },
    { path: "/admin/login", element: <Login />, },
    { path: "/register", element: <Register />, },
    { path: "/otp-verification", element: <OtpVerification />, },
    { path: "/forgot-password", element: <ForgotPassword />, },
    { path: "/set-new-password", element: <ForgetPasswordUpdate />, },

    {
        path: "/",
        element: <ProtectedRoute />,
        children: [
            { path: "", element: <Layout><Dashboard /></Layout> },

        ],
    },

    {
        path: "/request",
        element: <ProtectedRoute />,
        children: [
            { path: "create", element: <Layout><CreateRequest /></Layout> },
            { path: "create/all-shift", element: <Layout><AllShift /></Layout>},
            { path: "create/shift/details/:shift_id", element: <Layout><ShiftDetails /></Layout> },
            { path: "shift/details/:shift_id", element: <Layout><ShiftDetails /></Layout> },
            { path: "edit/:request_id", element: <Layout><CreateRequest /></Layout> },

        ],
    },

    {
        path: "/requests",
        element: <ProtectedRoute />,
        children: [
            // request all type of lists
            { path: "saved", element: <Layout><RequestList /></Layout> },
            { path: "invitation", element: <Layout><RequestList /></Layout> },
            { path: "in-bidding", element: <Layout><RequestList /></Layout> },
            { path: "not-planned", element: <Layout><RequestList /></Layout> },
            { path: "awarded", element: <Layout><RequestList /></Layout> },
            { path: "on-going", element: <Layout><RequestList /></Layout> },
            { path: "completed", element: <Layout><RequestList /></Layout> },
            { path: "history", element: <Layout><RequestList /></Layout> },

            // details
            { path: ":type/details/:request_id", element: <Layout><RequestDetailsSection /></Layout> },
            { path: ":type/details/:request_id/:action", element: <Layout><RequestDetailsSection /></Layout> },
            { path: ":type/details/:request_id/edit", element: <Layout><RequestDetailsSection /></Layout> },
            { path: ":type/details/:request_id/shift/:shift_id", element: <Layout><ShiftDetails /></Layout> },

        ],
    },

    {
        path: "/chat",
        element: <ProtectedRoute />,
        children: [
            { path: "", element: <Layout><Chat /></Layout> },
        ],
    },


    {
        path: "/notification",
        element: <ProtectedRoute />,
        children: [
            { path: "", element: <Layout><Notification /></Layout> },

        ],
    },

    {
        path: "/favorite-address",
        element: <ProtectedRoute />,
        children: [
            { path: "", element: <Layout><FavoriteAddress /></Layout> },
            { path: "add", element: <Layout><AddFavoriteAddress /></Layout> },
            { path: "edit/:fav_address_id", element: <Layout><EditFavoriteAddress /></Layout> },
            { path: "details/:fav_address_id", element: <Layout><FavoriteAddressDetails /></Layout> },
        ],
    },


    {
        path: "/settings",
        element: <ProtectedRoute />,
        children: [
            {
                path: "",
                element: <Layout><Settings /></Layout>,
                children: [
                    { path: "", element: <Settings /> },
                    { path: "profile", element: <Profile /> },
                    { path: "profile/company/edit", element: <EditCompanyProfile /> },
                    { path: "profile/customer/edit", element: <EditCustomerProfile /> },
                    { path: "profile/delete-account", element: <DeleteAccount /> },
                    { path: "change-password", element: <ChangePassword /> },
                    { path: "push-notification", element: <SettingsNotification /> },
                    { path: "admin-access", element: <AdminAccess /> },
                    { path: "contact", element: <Contact /> },
                    { path: "faq", element: <Faq /> },
                    { path: "language", element: <Language /> },
                    { path: "terms-conditions", element: <TermsConditions /> },

                ]
            },
        ],
    },










    {
        path: "/",
        element: <ProtectedRoute />,
        children: [
            { path: "*", element: <Layout><Error404 /></Layout> },

        ],
    },
]