import React from 'react'
import { iGLobe } from '../../../../../../../app/utility/imageImports'
import { useTranslation } from 'react-i18next';
import CommonCheckbox from '../../../../../../../components/input/CommonCheckbox';

export default function SubmittedForGlobal({ disabled = false, isReadOnly = false, checked, onClick }) {
  const { t } = useTranslation();
  return (
    <div className='w-full flex  justify-between p-3 border-[1px] border-cGray200 rounded-lg  '>
      <div className='flex items-center space-x-3'>
        <img src={iGLobe} alt="" srcSet="" />
        <div className='flex flex-col items-start justify-center space-y-1'>
          <div className='font-medium text-cGray800 text-fs16'>{t('Submitted For Global')}</div>
          <div className='font-normal text-cGray800 text-fs12'>{t('This request will be available to all active companies within Limadi')}</div>
        </div>
      </div>
      {isReadOnly ? <></> :
        <div className='flex items-center justify-center'>
          <CommonCheckbox disabled={disabled} checked={checked} onChange={onClick} />
        </div>}


    </div>
  )
}
