/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { chatCluster, chatKey, chat_role, user_role as role } from '../../../../app/utility/const'
import { iAdmin, iChatCamera, iChatDelete, iSendMessage, iUserAvatarCircle } from '../../../../app/utility/imageImports'
import useChatStore, { getChatList, getMessageList, sendMessage } from '../../../../app/stores/others/chatStore'
import Image from '../../../../components/image/Image'
import { useState } from 'react'
import Textarea from '@mui/joy/Textarea';
import { useEffect } from 'react'
import ChatMessageItem from './ChatMessageItem'
import { Toastr, countEmptySpaces, formatChatTimestampMessage, isObjectEmpty } from '../../../../app/utility/utilityFunctions'
import { useRef } from 'react'
import NoMessage from './NoMessage'
import NoSelectChat from './NoSelectChat'
import { useTranslation } from 'react-i18next'
import SendImageView from './SendImageView'
import Pusher from 'pusher-js';
import useGeneralStore from '../../../../app/stores/others/generalStore'

const ChatRightBar = () => {

    const { setChatSearch, message_scroll_bottom, setMessage_scroll_bottom, setShowDeleChatModal, textFieldCount, setTextFieldCount, selectChat, message_array, current_role, setAddMessageArray, chat_search } = useChatStore();

    const { setLoading } = useGeneralStore.getState();

    const { t } = useTranslation();
    const chatContainerRef = useRef(null);
    const inputFile = useRef(null);

    const onButtonClick = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    };

    const [newMessage, setNewMessage] = useState('');

    const [paginationLoading, setPaginationLoading] = useState(true);

    const [image, setImage] = useState({
        attachment: null,
        id: null,
    });


    // image upload onChange function
    const onChangeFile = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        var reader = new FileReader();

        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

        if (!allowedTypes.includes(file.type)) {
            // Do not display any error message or take any action
            return;
        }

        reader.onloadend = async function () {
            await setImage({ id: selectChat.id, attachment: reader.result });
        };
        reader.onload = async (event) => {
            await setImage({ id: selectChat.id, attachment: reader.result });
            image.src = event.target.result;
        };
        if (!/^image\/(png|jpe?g|jpg|jpeg|gif)$/.test(file.type)) {
            Toastr({ message: t('Invalid file type. Only jpeg and png and jpg files are allowed.'), type: 'error' });
            return;
        }
        reader.readAsDataURL(file);
    };

    // input box onKeyPress
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const noEmptySpace = (e) => {
        if (e.key === 'Enter' && e.target.value.trim() === "") {
            e.preventDefault();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newMessage & !image?.attachment) { return; }

        if (countEmptySpaces(newMessage) > 0) {
            setNewMessage('');
            return;
        }

        const messageValue = newMessage;
        const imageValue = image?.attachment;
        const messageData = {
            take: message_array?.total ? message_array?.total : 30,
            to_id: selectChat?.user_id,
            message: messageValue,
            attachment: imageValue,
            channel: selectChat?.channel_name,
            search: "",
        };
        if (message_array?.data?.length > 0) {
            await setAddMessageArray({
                current_page: message_array?.current_page,
                last_page: message_array?.last_page,
                data: [
                    ...message_array?.data,
                    {
                        message: messageValue,
                        attachment: imageValue,
                        user_type: current_role,
                        to_is_read: false,
                        created_at: null,
                        fail: false,
                        new: true,
                    },
                ],
                total: message_array?.total ?? 30,
            });
        } else {
            await setAddMessageArray({
                current_page: message_array?.current_page,
                last_page: message_array?.last_page,
                data: [
                    {
                        message: messageValue,
                        attachment: imageValue,
                        user_type: current_role,
                        to_is_read: false,
                        created_at: null,
                        fail: false,
                        new: true,
                    },
                ],
                total: message_array?.total,
            });
        }
        scrollBottom();
        setNewMessage('');
        await setImage({ id: null, attachment: null });
        setTextFieldCount(40);
        console.log("messageData", messageData);

        const success = await sendMessage(messageData);

        if (success?.success) {
            setPaginationLoading(false);
            await setAddMessageArray({
                current_page: success?.data?.current_page,
                last_page: success?.data?.last_page,
                data: success?.data?.data?.slice().reverse(),
                total: success?.data?.total + 1
            });
            if (chat_search) setChatSearch("");
            await getChatList(chat_search, chat_search ? false : true, false);
        } else {
            const errorMessage = message_array?.data.filter((i) => !i?.new);
            await setAddMessageArray({
                current_page: message_array?.current_page,
                last_page: message_array?.last_page,
                data: [...errorMessage,
                {
                    message: messageValue,
                    attachment: imageValue,
                    user_type: current_role,
                    to_is_read: false,
                    created_at: new Date(),
                    fail: true,
                },
                ],
                total: message_array?.total,
            });
        }
    }

    useEffect(() => {
        setNewMessage("");
        if (selectChat?.user_type && selectChat?.id) {
            fetchData();
        } else { setAddMessageArray([]); }
        setTextFieldCount(40);
    }, [selectChat?.id, selectChat?.new])


    // fetch message list
    const fetchData = async (isLoading = true) => {
        if (isLoading && paginationLoading) setLoading(true)
        if (message_array?.total) {
            await getMessageList({ "chat_id": parseInt(selectChat?.id), take: message_array?.total + 1 }, false)
            scrollBottom();
        } else {
            const res = await getMessageList({ "chat_id": parseInt(selectChat?.id), take: 10 }, false);
            await getMessageList({ "chat_id": parseInt(selectChat?.id), take: res?.data?.total + 1 }, false);
        }
        if (isLoading) setLoading(false)
    }

    // pusher set
    const READ_STATUS = 1;

    useEffect(() => {
        let total = message_array?.total + READ_STATUS;
        const pusher = new Pusher(chatKey, { cluster: chatCluster });

        const channel = pusher.subscribe(`${selectChat?.channel_name}-${selectChat?.id}`);
        channel.bind('my-event', async (data) => {
            if (data?.message?.chat_id && message_array?.total) {
                const success = await getMessageList({ "chat_id": data?.message?.chat_id, take: total }, false);
                await setAddMessageArray({
                    current_page: success?.data?.current_page,
                    last_page: success?.data?.last_page,
                    data: success?.data?.data?.slice().reverse(),
                    total: success?.data?.total
                });
                await getChatList(chat_search, false, false);
                total++;
            } else {
                message_array?.forEach((item, index) => {
                    message_array.data[index].to_is_read = READ_STATUS;
                })
            }
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, [selectChat?.id, selectChat?.new]);

    useEffect(() => {
        scrollBottom();
    }, [message_scroll_bottom])

    const scrollBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
        setMessage_scroll_bottom(false)
    }

    // input box increment row function
    const calculateRowCount = async (e) => {
        const lineBreaks = await (e.match(/\n/g) || [])?.length;

        console.log("lineBreaks", lineBreaks);

        if (lineBreaks < 136) {
            await setTextFieldCount(
                (lineBreaks === 0 && 40) ||
                (lineBreaks === 1 && 64) ||
                (lineBreaks === 2 && 88) ||
                (lineBreaks === 3 && 112) ||
                (lineBreaks === 4 && 136) ||
                (lineBreaks >= 5 && 136)
            );
        }
    };

    const textAreaRef = useRef(null);

    const calculateDynamicHeight = (clientHeight) => {
        if (clientHeight === 40) {
            return 'calc(100vh - 305px)';
        } else if (clientHeight === 64) {
            return 'calc(100vh - 329px)';
        } else if (clientHeight === 88) {
            return 'calc(100vh - 353px)';
        } else if (clientHeight === 112) {
            return 'calc(100vh - 377px)';
        } else if (clientHeight === 136) {
            return 'calc(100vh - 401px)';
        }
        else {
            // Default height if none of the specified conditions match
            return 'calc(100vh - 305px)';
        }
    };

    useEffect(() => {
        if (textAreaRef?.current) {
            const observer = new MutationObserver(() => {
                setTextFieldCount(textAreaRef.current.clientHeight);
            });

            observer.observe(textAreaRef.current, { attributes: true, childList: true, subtree: true });

            return () => {
                observer.disconnect();
            };
        }
    }, [textAreaRef?.current]);


    return (
        <div>
            {!isObjectEmpty(selectChat) ?
                <div className='flex flex-col justify-between h-full'>
                    <div className='px-5 pt-3 pb-[9px] border-b-[1px] border-cGray300 flex justify-between items-center'>
                        <div className="flex space-x-2">
                            {
                                (selectChat?.user_type === role?.admin || selectChat?.role === role?.admin) ? (
                                    <div className="bg-cGreen50 rounded-full max-w-[44px] min-w-[44px] h-[44px] flex items-center justify-center">
                                        <img alt="" src={iAdmin} className="max-w-[28px] min-w-[28px] h-[28px]" />
                                    </div>
                                ) : (
                                    <Image
                                        alt=""
                                        src={selectChat?.image}
                                        roundedFull={false} dummyImage={iUserAvatarCircle}
                                        className="max-w-[44px] min-w-[44px] h-[44px] rounded"
                                    />
                                )
                            }
                            <div>
                                <div className="capitalize text-cGray800 font-fw900 text-fs16">{selectChat?.name}</div>
                                <div className="capitalize text-text-cGray800 font-fw400 text-fs12">
                                    {selectChat?.user_type === role?.admin && 'Admin'}
                                    {((selectChat?.user_type === role?.customer) || (selectChat?.user_type === chat_role?.customer)) && chat_role.customer}
                                    {(selectChat?.user_type === role?.company) && chat_role.company}
                                    {(selectChat?.user_type === role?.driver) && chat_role.driver}
                                </div>
                            </div>
                        </div>
                        {(selectChat?.id && message_array?.data?.length > 0) &&
                            <img onClick={() => { setShowDeleChatModal(true) }}
                                src={iChatDelete}
                                alt=''
                                className='w-5 h-5 cursor-pointer'
                            />}
                    </div>


                    {
                        image?.attachment && image?.id === selectChat?.id ? (
                            <SendImageView
                                textFieldCount={calculateDynamicHeight(textFieldCount)}
                                image={image?.attachment}
                                clearImage={async () => await setImage({ id: null, attachment: null })}
                            />)
                            :
                            <div>
                                {
                                    message_array?.data?.length > 0 ?
                                        <div
                                            style={{ height: calculateDynamicHeight(textFieldCount ?? 40) }}
                                            ref={chatContainerRef}
                                            className={`flex flex-col-reverse overflow-y-auto py-5`}>
                                            <div className='px-5 space-y-5'>
                                                {
                                                    message_array?.data?.map((item, index) => (
                                                        <ChatMessageItem
                                                            fail={item?.fail ?? false}
                                                            key={index}
                                                            newMsg={item?.new ?? false}
                                                            attachment={item?.attachment}
                                                            messageText={item?.message}
                                                            isReplyType={item?.user_type === current_role}
                                                            isReed={item?.to_is_read ? true : false}
                                                            time={item?.updated_at ? formatChatTimestampMessage(item?.updated_at) : null}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div> :
                                        <NoMessage textFieldCount={calculateDynamicHeight(textFieldCount ?? 40)} />
                                }
                            </div>
                    }

                    {
                        selectChat?.is_active ?
                            <div className='px-5 pt-[18px] border-t-[1px] border-cGray300cGray300'>
                                <form onSubmit={handleSubmit}>
                                    <div className={`flex  ${textFieldCount === 40 ? "items-center" : "justify-end items-end"} space-x-4`}>
                                        <div className="relative w-full">
                                            <Textarea
                                                ref={textAreaRef}
                                                minRows={1}
                                                maxRows={5}
                                                maxLength={2}
                                                minLength={0}
                                                placeholder={t('Message')}
                                                value={newMessage}
                                                onChange={async (e) => {
                                                    if (e.target.value.length <= 1024) {
                                                        if (countEmptySpaces(e.target.value) > 0) {
                                                            setNewMessage('');
                                                        } else {
                                                            setNewMessage(e.target.value);
                                                            if ((e.target.value.match(/\n/g) || [])?.length > 0) {
                                                                calculateRowCount(e.target.value)
                                                            }
                                                            if (e.target.value.length < 30) {
                                                                setTextFieldCount(40);
                                                            }
                                                        }
                                                    }
                                                }}

                                                onKeyDown={async (e) => {
                                                    if (e.key === 'Enter' && e.shiftKey) {
                                                        await handleKeyPress();
                                                    } else if (e.key === 'Enter') {
                                                        await handleSubmit(e);
                                                    }
                                                }}

                                                onKeyPress={noEmptySpace}

                                                style={{
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px',
                                                    paddingLeft: '8px',
                                                    paddingRight: '25px',
                                                    borderWidth: '1.5px',
                                                    borderStyle: 'solid',
                                                    borderColor: '#285D43',
                                                    marginRight: '',
                                                }}

                                                sx={{
                                                    '&::before': {
                                                        display: 'none',
                                                    },
                                                    '&:focus-within': {
                                                        outline: '0px',
                                                        outlineOffset: '0px',
                                                    },
                                                }}
                                            />
                                            {
                                                !image?.attachment &&
                                                <div>
                                                    <input
                                                        accept=".png, .jpeg, .jpg"
                                                        onChange={onChangeFile.bind(this)}
                                                        onClick={() => (inputFile.current.value = null)}
                                                        type="file"
                                                        id="file"
                                                        ref={inputFile}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <img onClick={onButtonClick} src={iChatCamera} alt=""
                                                        className={`absolute ml-3 cursor-pointer ${textFieldCount === 40 ? "right-3 top-1/4" : `bottom-3 ${textFieldCount === 136 ? "right-[5px]" : "right-3"}`}`}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {
                                            !newMessage & !image?.attachment ? (
                                                <button
                                                    className="outline-none flex items-center justify-center rounded-full cursor-not-allowed bg-cDarkGray min-h-[44px] max-h-[44px] max-w[44px] min-w-[44px]">
                                                    <img src={iSendMessage} alt="" className="" />
                                                </button>
                                            ) : (
                                                <button type="submit" className="outline-none flex items-center justify-center rounded-full cursor-pointer bg-cBrand min-h-[44px] max-h-[44px] max-w[44px] min-w-[44px]">
                                                    <img src={iSendMessage} alt="" className="" />
                                                </button>
                                            )
                                        }
                                    </div>
                                </form>
                            </div>
                            :
                            <div className="border-t-[1px] border-cGray300 text-center flex items-center justify-center">
                                <div className="flex items-center justify-center text-center mt-7 text-fs16 text-cSecondaryTextColor font-fw400">
                                    {t("The chat is no longer active")}
                                </div>
                            </div>
                    }
                </div>
                : <NoSelectChat />
            }
        </div>
    )
}

export default ChatRightBar