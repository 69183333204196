/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useRequestStore from '../../../../../../../app/stores/others/requestStore';
import CommonInput from '../../../../../../../components/input/CommonInput';
import { useTranslation } from 'react-i18next';
import { request_details_types } from '../../../../../../../app/utility/const';

export default function PlaceBid() {
  const { place_bid_form, setPlaceBidForm, setPlaceBidChange, request_details, resetPlaceBidForm, globalReqMode, setGlobalReqMode } = useRequestStore();
  const { t } = useTranslation();
  const params = useParams();
  const { request_id, type } = params;

  useEffect(() => {
    resetPlaceBidForm()
    if (window.location.pathname === '/global-request/details/' + request_id + "/placeBid") {
      setPlaceBidChange({ ...place_bid_form, customer_name: "Global" })
    } else {
      setPlaceBidChange({ ...place_bid_form, customer_name: request_details?.user?.name, })
    }
  }, [request_details, window.location.pathname]);

  useEffect(() => {
    if (window.location.pathname === '/global-request/details/' + request_id)
      setGlobalReqMode(true);
    else
      setGlobalReqMode(false);
  }, [window.location.pathname]);

  return (
    <div>
      <div className='mb-3 sub-title text-cGray800'>{t("Proposal Info")}</div>
      <form>

        <div className='grid grid-cols-2 gap-x-6'>
          <CommonInput
            required={true}
            name={'budget'}
            value={place_bid_form?.budget}
            onChange={setPlaceBidForm}
            labelText={t('Budget')}
            className={'mb-2 mt-0'}
            type={'number'}
            max_input={8}
            show_asterisk={globalReqMode}
          />

          <CommonInput
            disabled={type === request_details_types.invitation}
            show_asterisk={false}
            required={true}
            className={'mb-2 mt-0'}
            labelText={t('Customer')}
            type='text'
            name={'customer_name'}
            value={place_bid_form?.customer_name}
            onChange={setPlaceBidForm}
          />
        </div>

        <CommonInput
          required={true}
          className={'mt-2'}
          name={'description'}
          value={place_bid_form?.description}
          onChange={(e) => {
            if (e.target.value !== " ") {
              setPlaceBidChange({ ...place_bid_form, description: e.target.value })
            }
          }}
          labelText={t('Bidding Description')}
          textarea={true} type={'text'} max_input={255}
          show_asterisk={globalReqMode} />
        <div className={`flex ${globalReqMode ? "flex-row-reverse" : " flex-row"} justify-between items-center space-x-2 mt-6`}>

        </div>

      </form>

      {/* <DeclineInvitationConfirmModal showModal={showDeclineInvitationConfirmModal} setShowModal={setShowDeclineInvitationConfirmModal} request_id={request_id} /> */}

    </div>
  )
}
