/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getShiftDetails } from '../../../../app/stores/company/shiftStore'
import useGeneralStore from '../../../../app/stores/others/generalStore'
import ShiftDetailsHeader from './ShiftDetailsHeader'
import ShiftDetailsMainContents from './ShiftDetailsMainContents'
import useCreateRequestStore from '../../../../app/stores/others/createRequestStore'
import { k_shift_section } from '../../../../app/utility/const'
import { k_create_request_paths } from '../../../../app/utility/AllRoute'
import { getRequestDetails } from '../../../../app/stores/others/requestStore'

const ShiftDetails = ({ withTopBar = true, isFullWidth = false, outlinedBox = false, shiftID = null }) => {
    const { setPathRecord } = useGeneralStore();

    const { shiftExpandedAccordion, setShiftExpandedAccordion, savedCrFormRes, request_details } = useCreateRequestStore();

    const location = useLocation();

    const { shift_id } = useParams();

    const refreshCarAndDrivers = async () => {
        if (shift_id) {
            await getShiftDetails(shift_id);
        } else if (shiftID) {
            await getShiftDetails(shiftID);
        } else console.log("no shift id");

    }

    useEffect(() => {
        if (shiftID) refreshCarAndDrivers();
    }, [shiftID]);

    useEffect(() => {
        window.scrollTo(0, 0);
        refreshCarAndDrivers();
        setShiftExpandedAccordion(k_shift_section.shift_overview);
    }, [shift_id]);

    useEffect(() => {
        if (location.pathname.includes(k_create_request_paths.shift_details)) {
            console.log("savedCrFormRes::: ", savedCrFormRes);
            if (location?.state?.is_fetch_shift_details === false) return;
            if (savedCrFormRes?.id && savedCrFormRes?.id !== undefined) {
                getRequestDetails("saved", savedCrFormRes?.id);
                console.log(" getRequestDetails from shift details::: request_details::: ", request_details);

            }
        }
        setPathRecord(location.pathname);
    }, [location.pathname]);

    return (
        <div>

            {withTopBar && <ShiftDetailsHeader />}

            <div className='flex items-start h-full space-x-10' >

                <ShiftDetailsMainContents outlinedBox={outlinedBox} />

                {/*e  right sections.. */}
                {/* {
                    type ? <RequestDetailsSummary /> : <div className='min-w-[380px] max-w-[380px]'></div>
                } */}

                {!isFullWidth && <div className='min-w-[380px] max-w-[380px]'></div>}
            </div>
        </div>

    )
}

export default ShiftDetails