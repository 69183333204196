import React from 'react';
import { iDrag, iWhiteOnHold } from '../../../../../app/utility/imageImports';
import { calculateDistance } from '../../../../../app/utility/utilityFunctions';

export default function CalculationStopCard({
    index = " ",
    title = " ",
    address = " ",
    date = " ",
    startTime = " ",
    endTime = " ",
    totalPackages = "0",
    duration = " ",
    indexKm = "0",
    indexTime = "00:00",
    indexType = " ",
    isIndexRed = false,
    isTimeConflict = false,
    isDraggingMode = false,
    isCompleted = false,
    completeMessage = "",
    withDragIcon = true,
    isOnHold = false,
}) {
    const { distance, unit } = calculateDistance(parseInt(indexKm));
    return (
        <div className='w-full flex items-center'>
            <div className='flex flex-col items-center justify-center w-max min-w-[110px] bg-white p-3 rounded-2xl'>
                <div className="relative">
                    <div className={`${isDraggingMode ? "border border-cGray300 text-cPrimary600 bg-white" : isCompleted ? "text-white bg-cGray300" : "bg-cPrimary600 text-white"}  flex items-center justify-center !w-[40px] !h-10 rounded-full text-base leading-6 limadi-font-medium`}>
                        {isOnHold ? <img
                            src={iWhiteOnHold}
                            alt='on hold'
                            className=''
                        /> : index}
                    </div>
                    {isIndexRed ? <div className="absolute rounded-full top-0 right-0 w-[14px] h-[14px] bg-cRed text-[10px] leading-[12px] text-white text-center">!</div> : <></>}
                </div>
                {!isCompleted ?
                    isOnHold ?
                        <div className="text-[8px] leading-[12px] w-max">0 KM, 00:00</div>
                        : <div className="text-[8px] leading-[12px] w-max">{distance} {unit}, {indexTime}</div>
                    : <></>
                }
                <div className={`text-[8px] leading-[12px] capitalize ${isCompleted ? "text-cGray300" : ""}`}>{isCompleted ? completeMessage : indexType}</div>
            </div>
            <div className={`relative w-full border ${isDraggingMode || isCompleted ? "border-cGray300 bg-white" : "border-transparent bg-cSecondary50"} ${isCompleted ? "text-cGray300" : ""} rounded-[4px] p-3`}>

                {
                    withDragIcon && isDraggingMode && <div className="absolute top-1/2 -translate-y-1/2 right-3"><img src={iDrag} alt="drag" className='w-5 h-5' /></div>
                }

                <div className="flex items-center justify-between">
                    <div className="text-base limadi-font-medium leading-6">{title}</div>
                    {isTimeConflict ? <div className="text-[10px] limadi-font-medium leading-[12px]">Time Conflict</div> : <></>}
                </div>
                <div className="text-xs leading-5">{address}</div>
                {date && <div className="text-xs leading-5">{date}, {startTime} - {endTime}</div>}
                <div className="text-xs leading-5">{totalPackages} {totalPackages > 1 ? "packages" : "package"}, Duration: {duration}</div>
            </div>
        </div>
    )
}
